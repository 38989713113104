import React, { createElement } from "react";
import withConsumers from "@/repository/withConsumers";
var Repository = function (_a) {
    var _b = _a.containers, containers = _b === void 0 ? [] : _b, pageTemplate = _a.pageTemplate, _c = _a.scheme, scheme = _c === void 0 ? {} : _c, _d = _a.options, options = _d === void 0 ? {} : _d;
    var data = containers.reduce(function (acc, key) {
        acc.providers.push(scheme[key].provider);
        acc.consumers.push(scheme[key].context.Consumer);
        return acc;
    }, { providers: [], consumers: [] });
    return data.providers.reduceRight(function (Prev, Elem) {
        return createElement(Elem, { options: options }, Prev);
    }, withConsumers(React.memo(pageTemplate), data.consumers));
};
export default Repository;
