import Button from "../../components/ui/button";
import React from "react";
import { getTemplate } from "@/helpers/getTemplate";
export var getModalFooter = function (props, isLoading, isDisabled) {
    if (props === void 0) { props = {}; }
    if (isLoading === void 0) { isLoading = {}; }
    if (isDisabled === void 0) { isDisabled = {}; }
    return function (data, key) {
        var _a;
        var _b;
        var template = (_a = {},
            _a["ACCEPT"] = React.createElement(Button, { className: "button button_huge button_fill", label: data.label, loading: isLoading === null || isLoading === void 0 ? void 0 : isLoading.accept, disabled: (isLoading === null || isLoading === void 0 ? void 0 : isLoading.accept) || ((_b = props === null || props === void 0 ? void 0 : props.isDisabled) === null || _b === void 0 ? void 0 : _b[data === null || data === void 0 ? void 0 : data.type]), onClick: props === null || props === void 0 ? void 0 : props.onAccept, style: { width: "100%" } }),
            _a["DECLINE"] = React.createElement(Button, { label: data.label, onClick: props === null || props === void 0 ? void 0 : props.onDecline, loading: isLoading === null || isLoading === void 0 ? void 0 : isLoading.decline, disabled: isLoading === null || isLoading === void 0 ? void 0 : isLoading.decline, className: "button button_huge button_outline", style: { width: "100%", border: "none" } }),
            _a);
        return getTemplate(template, data.type, { key: key });
    };
};
