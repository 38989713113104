var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getThemeClassName } from "@/helpers/getThemeClassName";
import React from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
var FilterTab = function (_a) {
    var tab = _a.tab, isActive = _a.isActive, theme = _a.theme, setTab = _a.setTab, onClick = _a.onClick, props = __rest(_a, ["tab", "isActive", "theme", "setTab", "onClick"]);
    var t = useTranslation().t;
    return React.createElement("div", __assign({}, props, { onClick: function () {
            onClick && onClick(tab);
            setTab && setTab(tab);
        }, className: "mortgage-filter-tabs mortgage-filter-tabs_".concat(getThemeClassName(theme), " ").concat(isActive ? "active" : "") }), t(tab.title));
};
export default FilterTab;
