import TextInput from "@/components/ui/text-input";
import React, { useEffect, useRef } from "react";
import BaseForm from "../base-form";
import { findDOMNode } from "react-dom";
import { useTranslation } from "react-i18next";
var ResetPassForm = function (_a) {
    var params = _a.params, changeParam = _a.changeParam, onSubmit = _a.onSubmit, isLoading = _a.isLoading, isDisabled = _a.isDisabled, onPassStep = _a.onPassStep, selectedTab = _a.selectedTab, title = _a.title, list = _a.list, onListChange = _a.onListChange;
    var phoneRef = useRef(null);
    var t = useTranslation().t;
    useEffect(function () {
        phoneRef.current.focus();
    }, []);
    return (React.createElement(BaseForm, { title: t(title || "flow.profile.enter"), btnLabel: t("flow.profile.send_sms_pass"), onSubmit: onSubmit, isLoading: isLoading, isDisabled: isDisabled, onListChange: onListChange, selectedTab: selectedTab, list: list },
        React.createElement(React.Fragment, null,
            React.createElement("h5", { style: { marginBottom: "10px", width: "335px" } }, t('flow.profile.otp_message')),
            React.createElement(TextInput, { label: t('flow.input.phone'), mask: "+7 (999) 999 99 99", inputRef: function (ref) { return phoneRef.current = findDOMNode(ref); }, onChange: changeParam('phone'), value: params.phone, placeholder: '+7 (___) ___ __ __' }))));
};
export default ResetPassForm;
