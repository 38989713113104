import { getThemeClassName } from "@/helpers/getThemeClassName";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import { i18n } from "@/translate/i18n";
import dayjs from "dayjs";
var data = [
    {
        title: "Рус",
        code: "ru",
    },
    {
        title: "Каз",
        code: "kz",
    },
    /*{
      title: "Eng",
      code: "en",
    },*/
];
var SelectLang = function (_a) {
    var theme = _a.theme;
    var _b = useState(localStorage.getItem('lang') || 'ru'), lang = _b[0], setLang = _b[1];
    var changeLanguage = function (lang) {
        localStorage.setItem('lang', lang);
        i18n.changeLanguage(lang);
        dayjs.locale(lang == 'kz' ? 'kk' : 'ru');
        setLang(lang);
    };
    useEffect(function () {
        lang && dayjs.locale(lang == 'kz' ? 'kk' : 'ru');
    }, []);
    return (React.createElement("div", { className: "select-lang select-lang_".concat(getThemeClassName(theme)) }, data.map(function (lng) { return (React.createElement("div", { key: lng.code, className: "select-lang__item ".concat(lng.code === lang && "active"), onClick: function () { return changeLanguage(lng.code); } }, lng.title)); })));
};
export default SelectLang;
