import React from "react";
import "./styles.scss";
import { getThemeClassName } from "@/helpers/getThemeClassName";
import SidebarFooter from "../sidebar-footer";
import { StepsSidebar, SubTitle, Text } from "@/components";
import { useTranslation } from "react-i18next";
var Sidebar = function (_a) {
    var theme = _a.theme;
    var t = useTranslation().t;
    return React.createElement("aside", { className: "sidebar-home indent-l sidebar-home_".concat(getThemeClassName(theme)) },
        React.createElement("div", { className: "sidebar-home__block" },
            React.createElement(SubTitle, { text: t('home.sidebar.title'), size: "xl" }),
            React.createElement(Text, { text: t('calc.calc.desc'), size: "xl" })),
        React.createElement(StepsSidebar, { step: 0, theme: theme, order: {}, showFooter: false }),
        React.createElement("div", { className: "links__block" },
            React.createElement("div", { className: "line m-t-30 m-b-30" }),
            React.createElement(SidebarFooter, null)));
};
export default Sidebar;
