import TextInput from "@/components/ui/text-input";
import React, { useEffect, useRef } from "react";
import { findDOMNode } from "react-dom";
import BaseForm from "../base-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
var LogInForm = function (_a) {
    var params = _a.params, changeParam = _a.changeParam, onSubmit = _a.onSubmit, isLoading = _a.isLoading, onResetPass = _a.onResetPass, isDisabled = _a.isDisabled, list = _a.list, onListChange = _a.onListChange, title = _a.title, selectedTab = _a.selectedTab;
    var phoneRef = useRef(null);
    var navigate = useNavigate();
    var t = useTranslation().t;
    useEffect(function () {
        phoneRef.current.focus();
    }, []);
    return (React.createElement(BaseForm, { title: t(title || "flow.profile.enter"), btnLabel: t("flow.profile.login"), onSubmit: onSubmit, onListChange: onListChange, selectedTab: selectedTab, list: list, isLoading: isLoading, isDisabled: isDisabled },
        React.createElement(React.Fragment, null,
            React.createElement(TextInput, { mask: "+7 (999) 999 99 99", inputRef: function (ref) { return phoneRef.current = findDOMNode(ref); }, onChange: changeParam('phone'), value: params === null || params === void 0 ? void 0 : params.phone, placeholder: '+7 (___) ___ __ __', label: t("flow.input.phone") }),
            React.createElement(TextInput, { placeholder: t("flow.profile.enter_pass"), onChange: changeParam('password'), label: t("flow.profile.pass"), value: params === null || params === void 0 ? void 0 : params.password, type: "password" }),
            React.createElement("div", { className: "d-f j-c-s-b" },
                React.createElement("a", { className: 'pointer', onClick: onResetPass }, t("flow.profile.put_pass")),
                React.createElement("a", { className: 'pointer', onClick: onResetPass }, t("flow.profile.miss_pass"))))));
};
export default LogInForm;
