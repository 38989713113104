import React, { useState } from 'react';
import './style.scss';
import { ShowIf } from "@/components";
import SVG from "react-inlinesvg";
import { getThemeClassName } from "@/helpers/getThemeClassName";
import { fillSvg } from '@/helpers/fillSvg';
var ChevronOpen = require('public/ui/accordion/chevron-open.svg');
var ChevronClose = require('public/ui/accordion/chevron-close.svg');
var Accordion = function (_a) {
    var _b = _a.defaultOpen, defaultOpen = _b === void 0 ? false : _b, icon = _a.icon, _c = _a.title, title = _c === void 0 ? '' : _c, children = _a.children, theme = _a.theme;
    var _d = useState(defaultOpen), open = _d[0], setOpen = _d[1];
    var toggleAccordion = function () { return setOpen(function (prev) { return !prev; }); };
    return (React.createElement("div", null,
        React.createElement("div", { className: "accordion accordion_".concat(getThemeClassName(theme)) },
            React.createElement("div", { className: "accordion__head", onClick: toggleAccordion },
                React.createElement("div", { className: "accordion__title" },
                    React.createElement(ShowIf, { condition: !!icon },
                        React.createElement("div", { className: "accordion__icon m-r-10" },
                            React.createElement(SVG, { src: icon.src, height: icon.size, preProcessor: fillSvg(theme) }))),
                    title),
                React.createElement("div", { className: "accordion__arrow" },
                    React.createElement(SVG, { src: open ? ChevronOpen : ChevronClose, preProcessor: fillSvg(theme) })))),
        React.createElement("div", { className: "accordion__body ".concat(open ? 'a-open' : '') }, children)));
};
export default Accordion;
