var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import './styles.scss';
import SVG from "react-inlinesvg";
// @ts-ignore
import Up from 'public/assets/up.svg';
// @ts-ignore
import Down from 'public/assets/down.svg';
var Loading = require('public/loading.svg');
var SelectSearch = /** @class */ (function (_super) {
    __extends(SelectSearch, _super);
    function SelectSearch(props) {
        var _this = _super.call(this, props) || this;
        _this.dropdownContainer = React.createRef();
        _this.dropdownLink = React.createRef();
        _this.scrollItem = React.createRef();
        _this.observer = null;
        _this.observerHandler = function (_a) {
            var _b, _c;
            var entry = _a[0];
            if (_this.props.isInfiniteLoading || _this.state.isUpdated)
                return;
            if (entry.isIntersecting && !_this.state.isUpdated) {
                console.log('Visible');
                (_c = (_b = _this.props).onInfiniteHandler) === null || _c === void 0 ? void 0 : _c.call(_b);
                _this.setState({ isUpdated: true });
            }
        };
        _this.scrollHandler = function () {
            var _a, _b, _c, _d;
            if (_this.observer) {
                (_b = (_a = _this.observer) === null || _a === void 0 ? void 0 : _a.disconnect) === null || _b === void 0 ? void 0 : _b.call(_a);
                // this.setState({isUpdated: false})
            }
            _this.observer = new IntersectionObserver(_this.observerHandler);
            if (_this.scrollItem.current)
                (_d = (_c = _this.observer) === null || _c === void 0 ? void 0 : _c.observe) === null || _d === void 0 ? void 0 : _d.call(_c, _this.scrollItem.current);
        };
        // Close dropdowns (start)
        _this.handleClickOutside = function (event) {
            if (_this.dropdownContainer.current &&
                !_this.dropdownContainer.current.contains(event.target) &&
                _this.dropdownLink.current &&
                !_this.dropdownLink.current.contains(event.target)) {
                _this.setState({ dropdown: false });
            }
        };
        _this.componentDidUpdate = function (prevProps, prevState) {
            document.addEventListener('mousedown', _this.handleClickOutside);
            if (prevProps.value !== _this.props.value && !_this.props.value) {
                _this.setState({ label: null });
            }
            if (prevProps.value !== _this.state.label && _this.props.value) {
                _this.setState({ label: _this.props.value });
            }
            if (prevProps.default == '' &&
                !_this.props.value &&
                prevProps.default !== _this.props.default) {
                _this.setState({ placeholder: _this.props.default });
            }
            if (!prevState.isUpdated && _this.state.isUpdated) {
                setTimeout(function () { return _this.setState({ isUpdated: false }); }, 3000);
            }
        };
        _this.state = {
            dropdown: false,
            title: '',
            label: '',
            placeholder: '',
            isUpdated: false,
        };
        return _this;
    }
    // Close dropdowns (end)
    SelectSearch.prototype.componentDidMount = function () {
        var _this = this;
        var _a, _b, _c, _d;
        ((_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.list) === null || _b === void 0 ? void 0 : _b.length) > 0 &&
            this.props.list.map(function (item) {
                return item.name == _this.props.default &&
                    _this.setState({ placeholder: item.name });
            });
        this.props.title && this.setState({ title: this.props.title });
        if (this.props.value) {
            ((_d = (_c = this.props) === null || _c === void 0 ? void 0 : _c.list) === null || _d === void 0 ? void 0 : _d.length) > 0 &&
                this.props.list.map(function (item) {
                    return (item === null || item === void 0 ? void 0 : item.id) == _this.props.value &&
                        _this.setState({ placeholder: item.name });
                });
        }
        if (this.props.isInfinite) {
            this.observer = new IntersectionObserver(this.observerHandler);
            if (this.scrollItem.current)
                this.observer.observe(this.scrollItem.current);
        }
    };
    SelectSearch.prototype.selectItem = function (item, index) {
        this.props.changeSelect(item === null || item === void 0 ? void 0 : item.id, index);
        this.setState({ dropdown: !this.state.dropdown });
        this.setState({ label: item.name });
    };
    SelectSearch.prototype.onSearch = function (value) {
        this.props.searchSelect(value);
        this.setState({ dropdown: true });
        this.setState({ label: value });
        this.setState({ placeholder: '' });
    };
    SelectSearch.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var _k = this.state, dropdown = _k.dropdown, label = _k.label, title = _k.title, placeholder = _k.placeholder;
        var _l = this.props, _m = _l.disabled, disabled = _m === void 0 ? false : _m, replace = _l.replace;
        return (React.createElement("div", { className: "input ".concat(this.props.inputClass || ''), ref: this.dropdownLink, onClick: this.props.onClick },
            title && React.createElement("label", { className: "select-search__title" }, title),
            React.createElement("div", { className: "select-search ".concat(disabled ? 'disabled' : ''), style: this.props.style, onClick: function () { return _this.setState({ dropdown: !dropdown }); } },
                React.createElement("div", { className: "select-search__label" },
                    React.createElement("input", { type: "text", value: label ? this.props.value
                            ? (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.list.find(function (el) { return (el === null || el === void 0 ? void 0 : el.id) == _this.props.value; })) === null || _b === void 0 ? void 0 : _b.name
                            : label : placeholder, onChange: function (e) {
                            return _this.onSearch(e.target.value.replace(replace || '', ''));
                        }, placeholder: this.props.placeholder, disabled: disabled, name: (_c = this.props) === null || _c === void 0 ? void 0 : _c.name }),
                    React.createElement("div", { className: "select-search__interface" }, dropdown ? React.createElement(SVG, { src: Up }) : React.createElement(SVG, { src: Down }))),
                React.createElement("div", { className: "dropdown", onScroll: this.props.isInfinite ? this.scrollHandler : function () {
                    }, style: {
                        display: dropdown ? 'block' : 'none',
                        maxHeight: "".concat(window.innerHeight * 0.5, "px"),
                    }, ref: this.dropdownContainer },
                    ((_e = (_d = this.props) === null || _d === void 0 ? void 0 : _d.list) === null || _e === void 0 ? void 0 : _e.length) > 0 &&
                        ((_g = (_f = this.props) === null || _f === void 0 ? void 0 : _f.list) === null || _g === void 0 ? void 0 : _g.map(function (item, index) { return (React.createElement("div", { key: index, className: "dropdown__item ".concat(label === item.name ? 'active' : ''), onClick: function () { return _this.selectItem(item, index); } }, item.name || '')); })),
                    (this.props.isInfinite && (this.props.isInfiniteLoading || this.state.isUpdated)) &&
                        React.createElement("div", { className: "dropdown__loading" },
                            React.createElement(SVG, { src: Loading })),
                    (this.props.isInfinite && ((_j = (_h = this.props) === null || _h === void 0 ? void 0 : _h.list) === null || _j === void 0 ? void 0 : _j.length) >= 10) &&
                        React.createElement("div", { ref: this.scrollItem, style: { height: '1px', backgroundColor: "transparent" } })))));
    };
    return SelectSearch;
}(Component));
export default SelectSearch;
