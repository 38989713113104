import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
var PrivateRoute = function (_a) {
    var authenticated = _a.authenticated, redirectTo = _a.redirectTo, element = _a.element, forceRedirect = _a.forceRedirect;
    var navigate = useNavigate();
    useEffect(function () {
        if (!authenticated)
            setTimeout(function () { return navigate(redirectTo || '/login'); }, 300);
    }, [authenticated]);
    if (forceRedirect)
        return React.createElement(Navigate, { to: typeof forceRedirect === 'string' || forceRedirect === true ? String(forceRedirect) : '/login' });
    // if (!authenticated) return <Navigate to={redirectTo || '/login'}/>;
    return element;
};
export default PrivateRoute;
