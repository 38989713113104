var statuses = {
    warning: {
        ESTIMATOR_DOCS_WAITING_FOR_APPROVAL: 'registrar.statuses.ESTIMATOR_DOCS_WAITING_FOR_APPROVAL',
        SALE_CONTRACT_CREATION: 'registrar.statuses.SALE_CONTRACT_CREATION',
        NOTARY_WAITING: 'registrar.statuses.NOTARY_WAITING',
        WAITING_CONFIRMATION: 'registrar.statuses.WAITING_CONFIRMATION',
        OBJECT_REGISTRATION_WAITING: 'registrar.statuses.OBJECT_REGISTRATION_WAITING',
        WAITING_ECP_SIGN: 'registrar.statuses.WAITING_ECP_SIGN',
        WAITING_ECP_BANK_APPROVE: 'registrar.statuses.WAITING_ECP_BANK_APPROVE',
        WAITING_SCHEDULE_ECP_SIGN: 'registrar.statuses.WAITING_SCHEDULE_ECP_SIGN',
        WAITING_SCHEDULE_ECP_BANK_APPROVE: 'registrar.statuses.WAITING_SCHEDULE_ECP_BANK_APPROVE',
        REJECTED_BY_SYSTEM: 'registrar.statuses.REJECTED_BY_SYSTEM',
        BI_PROCESS_FLOW: "registrar.statuses.BI_PROCESS_FLOW",
        WAITING: 'registrar.statuses.WAITING',
        ARCHIVED: 'registrar.statuses.ARCHIVED',
        UNDER_REVISION: 'registrar.statuses.UNDER_REVISION',
        INCOMPLETE_DOSSIER: 'registrar.statuses.INCOMPLETE_DOSSIER',
        "PRE-APPROVED": "registrar.statuses.PRE-APPROVED",
        REGISTRAR_CONFIRMATION: "registrar.statuses.REGISTRAR_CONFIRMATION",
        WAITING_SALE_CONTRACT_SIGN: "registrar.statuses.WAITING_SALE_CONTRACT_SIGN",
        WAITING_REGISTRAR_SALE_CONTRACT_SIGN: "registrar.statuses.WAITING_REGISTRAR_SALE_CONTRACT_SIGN",
        SELLER_CHECKING: "registrar.statuses.SELLER_CHECKING",
        PAYMENT_BY_THE_BANK: "registrar.statuses.PAYMENT_BY_THE_BANK",
        SELLER_DOCS_UPLOADING: "registrar.statuses.SELLER_DOCS_UPLOADING",
        REAL_ESTATE_GBDRN_NOT_VERIFIED: "registrar.statuses.REAL_ESTATE_GBDRN_NOT_VERIFIED",
        NOTARY_CONFIRMATION: "registrar.statuses.NOTARY_CONFIRMATION",
        WAITING_NOTARY_SALE_CONTRACT_SIGN: "registrar.statuses.WAITING_NOTARY_SALE_CONTRACT_SIGN",
        WAITING_TO_RECEIVE_ENIS_CODE: "registrar.statuses.WAITING_TO_RECEIVE_ENIS_CODE",
        WAITING_GBDRN_REGISTRATION: "registrar.statuses.WAITING_GBDRN_REGISTRATION",
        ESCROW_REPLENISHMENT: "registrar.statuses.ESCROW_REPLENISHMENT",
        REGISTRY_REGISTRATION: "registrar.statuses.REGISTRY_REGISTRATION",
        WAITING_SHIPMENT_TO_ENIS: "registrar.statuses.WAITING_SHIPMENT_TO_ENIS",
        PAYMENT_OF_STATE_DUTY: "registrar.statuses.PAYMENT_OF_STATE_DUTY",
    },
    success: {
        IN_PROGRESS: 'registrar.statuses.IN_PROGRESS',
        APPROVED: 'registrar.statuses.APPROVED',
        FINAL_AGREEMENT: 'registrar.statuses.FINAL_AGREEMENT',
        ESTIMATOR_DOCS_APPROVED: 'registrar.statuses.ESTIMATOR_DOCS_APPROVED',
        SALE_CONTRACT_CREATED: 'registrar.statuses.SALE_CONTRACT_CREATED',
        NOTARY_APPROVED: 'registrar.statuses.NOTARY_APPROVED',
        OBJECT_REGISTRATION_VERIFIED: 'registrar.statuses.OBJECT_REGISTRATION_VERIFIED',
        ECP_SIGNED: 'registrar.statuses.ECP_SIGNED',
        ECP_BANK_APPROVED: 'registrar.statuses.ECP_BANK_APPROVED',
        SCHEDULE_ECP_SIGNED: 'registrar.statuses.SCHEDULE_ECP_SIGNED',
        SCHEDULE_ECP_BANK_APPROVED: 'registrar.statuses.SCHEDULE_ECP_BANK_APPROVED',
        CONFIRMED: 'registrar.statuses.CONFIRMED',
        NEW: "registrar.statuses.NEW",
        CLIENT_OTP_VERIFICATION: "registrar.statuses.CLIENT_OTP_VERIFICATION",
        CLIENT_OTP_VERIFIED: "registrar.statuses.CLIENT_OTP_VERIFIED",
        CLIENT_WAITING_BIOMETRY_VERIFICATION: "registrar.statuses.CLIENT_WAITING_BIOMETRY_VERIFICATION",
        CLIENT_BIOMETRY_VERIFICATION: "registrar.statuses.CLIENT_BIOMETRY_VERIFICATION",
        CLIENT_BIOMETRY_VERIFIED: "registrar.statuses.CLIENT_BIOMETRY_VERIFIED",
        CLIENT_ID_CARD_VERIFICATION: "registrar.statuses.CLIENT_ID_CARD_VERIFICATION",
        CLIENT_ID_CARD_VERIFIED: "registrar.statuses.CLIENT_ID_CARD_VERIFIED",
        CLIENT_SPOUSE_CONFIRMING_REAL_ESTATE: "registrar.statuses.CLIENT_SPOUSE_CONFIRMING_REAL_ESTATE",
        CLIENT_SPOUSE_OTP_VERIFICATION: "registrar.statuses.CLIENT_SPOUSE_OTP_VERIFICATION",
        CLIENT_SPOUSE_OTP_VERIFIED: "registrar.statuses.CLIENT_SPOUSE_OTP_VERIFIED",
        CLIENT_SPOUSE_BIOMETRY_VERIFICATION: "registrar.statuses.CLIENT_SPOUSE_BIOMETRY_VERIFICATION",
        CLIENT_SPOUSE_BIOMETRY_VERIFIED: "registrar.statuses.CLIENT_SPOUSE_BIOMETRY_VERIFIED",
        CLIENT_SPOUSE_ID_CARD_VERIFICATION: "registrar.statuses.CLIENT_SPOUSE_ID_CARD_VERIFICATION",
        CLIENT_SPOUSE_ID_CARD_VERIFIED: "registrar.statuses.CLIENT_SPOUSE_ID_CARD_VERIFIED",
        WAITING_CLIENT_FILL_REAL_ESTATE: "registrar.statuses.WAITING_CLIENT_FILL_REAL_ESTATE",
        CLIENT_FILLING_REAL_ESTATE_DATA: "registrar.statuses.CLIENT_FILLING_REAL_ESTATE_DATA",
        CLIENT_CONFIRMED_REAL_ESTATE_DATA: "registrar.statuses.CLIENT_CONFIRMED_REAL_ESTATE_DATA",
        SELLER_OTP_VERIFICATION: "registrar.statuses.SELLER_OTP_VERIFICATION",
        SELLER_OTP_VERIFIED: "registrar.statuses.SELLER_OTP_VERIFIED",
        SELLER_WAITING_BIOMETRY_VERIFICATION: "registrar.statuses.SELLER_WAITING_BIOMETRY_VERIFICATION",
        SELLER_BIOMETRY_VERIFICATION: "registrar.statuses.SELLER_BIOMETRY_VERIFICATION",
        SELLER_BIOMETRY_VERIFIED: "registrar.statuses.SELLER_BIOMETRY_VERIFIED",
        REAL_ESTATE_GBDRN_VERIFICATION: "registrar.statuses.REAL_ESTATE_GBDRN_VERIFICATION",
        REAL_ESTATE_GBDRN_VERIFIED: "registrar.statuses.REAL_ESTATE_GBDRN_VERIFIED",
        SELLER_ID_CARD_VERIFICATION: "registrar.statuses.SELLER_ID_CARD_VERIFICATION",
        SELLER_ID_CARD_VERIFIED: "registrar.statuses.SELLER_ID_CARD_VERIFIED",
        GBDRN_NOT_VERIFIED: "registrar.statuses.GBDRN_NOT_VERIFIED",
        SELLER_SPOUSE_CONFIRMING_REAL_ESTATE: "registrar.statuses.SELLER_SPOUSE_CONFIRMING_REAL_ESTATE",
        SELLER_SPOUSE_OTP_VERIFICATION: "registrar.statuses.SELLER_SPOUSE_OTP_VERIFICATION",
        SELLER_SPOUSE_OTP_VERIFIED: "registrar.statuses.SELLER_SPOUSE_OTP_VERIFIED",
        SELLER_SPOUSE_BIOMETRY_VERIFICATION: "registrar.statuses.SELLER_SPOUSE_BIOMETRY_VERIFICATION",
        SELLER_SPOUSE_BIOMETRY_VERIFIED: "registrar.statuses.SELLER_SPOUSE_BIOMETRY_VERIFIED",
        SELLER_SPOUSE_ID_CARD_VERIFICATION: "registrar.statuses.SELLER_SPOUSE_ID_CARD_VERIFICATION",
        SELLER_SPOUSE_ID_CARD_VERIFIED: "registrar.statuses.SELLER_SPOUSE_ID_CARD_VERIFIED",
        SELLER_CONFIRMED_REAL_ESTATE_DATA: "registrar.statuses.SELLER_CONFIRMED_REAL_ESTATE_DATA",
        CLIENT_APPROVING_TERMS_OF_DEAL: "registrar.statuses.CLIENT_APPROVING_TERMS_OF_DEAL",
        CLIENT_TERMS_OF_DEAL_APPROVED: "registrar.statuses.CLIENT_TERMS_OF_DEAL_APPROVED",
        SELLER_TERMS_OF_DEAL_APPROVED: "registrar.statuses.SELLER_TERMS_OF_DEAL_APPROVED",
        CLIENT_SPOUSE_TERMS_OF_DEAL_APPROVED: "registrar.statuses.CLIENT_SPOUSE_TERMS_OF_DEAL_APPROVED",
        SELLER_SPOUSE_TERMS_OF_DEAL_APPROVED: "registrar.statuses.SELLER_SPOUSE_TERMS_OF_DEAL_APPROVED",
        REGISTRAR_CONFIRMED: "registrar.statuses.REGISTRAR_CONFIRMED",
        REGISTRAR_CREATED_MEET: "registrar.statuses.REGISTRAR_CREATED_MEET",
        CLIENT_PAYMENT: "registrar.statuses.CLIENT_PAYMENT",
        CLIENT_PAID: "registrar.statuses.CLIENT_PAID",
        BLOCK_CHAIN_PROCESS: "registrar.statuses.BLOCK_CHAIN_PROCESS",
        COMPLETED: "registrar.statuses.COMPLETED",
        NOTARY_CONFIRMED: "registrar.statuses.NOTARY_CONFIRMED",
        CLIENT_CHECKING: "registrar.statuses.CLIENT_CHECKING",
        CLIENT_SIGNED_DOCS: "registrar.statuses.CLIENT_SIGNED_DOCS",
        SELLER_CHECKED: "registrar.statuses.SELLER_CHECKED",
        SELLER_DOCS_UPLOADED: "registrar.statuses.SELLER_DOCS_UPLOADED",
        REGISTRY_REGISTERED: "registrar.statuses.REGISTRY_REGISTERED",
        GBDRN_REGISTERED: "registrar.statuses.GBDRN_REGISTERED",
    },
    danger: {
        REJECTED_BLOCKCHAIN: 'registrar.statuses.REJECTED_BLOCKCHAIN',
        REJECTED: "registrar.statuses.REJECTED",
        SERVICE_INACTIVE_REJECT: 'registrar.statuses.SERVICE_INACTIVE_REJECT',
    },
    other: {}
};
var className = {
    danger: 'status-danger',
    success: 'status-success',
    warning: 'status-warning',
    other: '',
};
export function getStatusInfo(status, checkConditions) {
    var _a;
    var type = Object
        .keys(statuses)
        .reduce(function (res, tp) { var _a; return (!!((_a = statuses[tp]) === null || _a === void 0 ? void 0 : _a[status]) ? tp : res); }, null);
    return (checkConditions && checkConditions(className)) || {
        text: ((_a = statuses[type]) === null || _a === void 0 ? void 0 : _a[status]) || status,
        className: className[type] || '',
    };
}
