import { useSelector } from "react-redux";
import { userSelector } from "@/saga-query/ducks/auth";
import { accessAtoms, rules } from "@/options/permissions";
export var usePermissions = function () {
    var user = useSelector(function (state) { return userSelector(state); });
    var groups = ((user === null || user === void 0 ? void 0 : user.groups) || []);
    var isHasForbid = function (page) { return groups.some(function (role) { var _a; return Array.isArray(rules === null || rules === void 0 ? void 0 : rules[page]) && ((_a = rules === null || rules === void 0 ? void 0 : rules[role]) === null || _a === void 0 ? void 0 : _a.includes(page)); }); };
    var hasAccess = function (type) { return groups.some(function (role) { return Array.isArray(accessAtoms === null || accessAtoms === void 0 ? void 0 : accessAtoms[role]) && accessAtoms[role].includes(type); }); };
    return { isHasForbid: isHasForbid, hasAccess: hasAccess };
};
