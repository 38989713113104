import React from "react";
import "./index.scss";
import Button from "@/components/ui/button";
import { getThemeClassName } from "@/helpers/getThemeClassName";
import { ShowList } from "@/components";
import { flatObject } from "@/helpers/flatObject";
var PLUS = require("public/plus.svg");
var DocumentsAmount = function (_a) {
    var data = _a.data, head = _a.head, theme = _a.theme, onAdd = _a.onAdd, isDisabled = _a.isDisabled;
    var transformedData = flatObject(data || {});
    return (React.createElement("div", { className: "documents-amount documents-amount__".concat(getThemeClassName(theme)) },
        React.createElement(ShowList, { list: head }, function (_a) {
            var title = _a.title, field = _a.field;
            return React.createElement("div", { className: "documents-amount__badge", key: title },
                React.createElement("h5", { className: "documents-amount__amount" }, transformedData[field]),
                React.createElement("span", { className: "documents-amount__title" }, title));
        }),
        React.createElement(Button, { className: "button button_huge button_outline button_".concat(getThemeClassName(theme), " m-r-15"), svg_left: PLUS, label: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C", style: { width: "235px" }, onClick: onAdd, disabled: isDisabled === null || isDisabled === void 0 ? void 0 : isDisabled.DOCUMENT_ADD })));
};
export default DocumentsAmount;
