import React from "react";
import ShowIf from "@/components/other/show-if";
import Button from "@/components/ui/button";
import "./index.scss";
import { useTranslation } from "react-i18next";
var BaseForm = function (_a) {
    var children = _a.children, title = _a.title, btnLabel = _a.btnLabel, onSubmit = _a.onSubmit, isLoading = _a.isLoading, isDisabled = _a.isDisabled, selectedTab = _a.selectedTab, list = _a.list, onListChange = _a.onListChange;
    var t = useTranslation().t;
    var handleClick = function (e) {
        e.preventDefault();
        onSubmit();
    };
    return (React.createElement("div", null,
        React.createElement(ShowIf, { condition: !!title },
            React.createElement("h3", { className: "base-form__title m-b-10" }, title)),
        React.createElement(ShowIf, { condition: !!(list === null || list === void 0 ? void 0 : list.length) },
            React.createElement("div", { className: "base-form__selector" }, list === null || list === void 0 ? void 0 : list.map(function (_a) {
                var name = _a.name, id = _a.id;
                return React.createElement("div", { key: id, className: "base-form__item ".concat(selectedTab === id ? 'active' : ''), onClick: function () { return onListChange(id); } }, t(name));
            }))),
        React.createElement("form", { className: "base-form" },
            React.createElement("div", { className: "base-form__container" }, children),
            React.createElement(Button, { isSimple: true, onClick: handleClick, className: "button button_huge button_fill m-t-15", label: btnLabel, loading: isLoading, disabled: isLoading || isDisabled }))));
};
export default BaseForm;
