import React, { useState } from "react";
import { ShowIf, ShowList } from "@/components";
import './styles.scss';
import { useTranslation } from "react-i18next";
import { getProfileMenuTemplate, profileMenuList } from "@/options/profile";
import { useAuthorization } from "@/saga-query/query-hooks/useAuthorization";
var ProfileMenu = function (_a) {
    var onLogout = _a.onLogout, imageLink = _a.imageLink;
    var user = useAuthorization().user;
    var t = useTranslation().t;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var menuList = profileMenuList.filter(function (m) { return m.filterType ? m.filterType.includes(user.user_role) : true; });
    return React.createElement("div", { className: 'profile-menu' },
        React.createElement("div", { onClick: function () { return setIsOpen(function (prev) { return !prev; }); }, className: "profile-menu__img" },
            React.createElement("img", { src: imageLink || "/assets/img/default-user-avatar.png", alt: "avatar" })),
        React.createElement(ShowIf, { condition: isOpen, wrapElem: { elem: React.createElement("ul", null), className: 'profile-menu__list' } },
            React.createElement(ShowList, { list: menuList }, getProfileMenuTemplate({ onLogout: onLogout }))));
};
export default ProfileMenu;
