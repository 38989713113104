var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import './index.scss';
import ProgresBar from "@/components/chunks/progres-bar";
import { ShowIf, ShowList } from "@/components";
import SVG from "react-inlinesvg";
import Text from "@/components/ui/text/Text";
import SubTitle from "@/components/ui/sub-title";
import { getConditionIcon } from "@/options/chunks/getConditionIcon";
import { valueFormat } from "@/helpers/valueFormat";
var MortgageCondition = function (_a) {
    var title = _a.title, value = _a.value, icon = _a.icon, type = _a.type;
    var transformedValue = valueFormat(value, type);
    return (React.createElement("div", { className: 'mortgage-header__card' },
        React.createElement(SVG, { src: getConditionIcon(icon), width: 20, height: 20 }),
        React.createElement("div", null,
            React.createElement(Text, { text: title, style: { marginBottom: '2px' } }),
            React.createElement(SubTitle, { text: transformedValue, style: { whiteSpace: 'nowrap', marginBottom: '0', } }))));
};
var MortgageHeader = function (_a) {
    var head = _a.head, data = _a.data, percent = _a.percent, theme = _a.theme, step = _a.step, _b = _a.showData, showData = _b === void 0 ? false : _b;
    return React.createElement("div", { className: 'mortgage-header__wrapper' },
        React.createElement(ProgresBar, { currentProgress: percent }),
        React.createElement(ShowIf, { condition: showData },
            React.createElement("div", { className: 'mortgage-header__list' },
                React.createElement(ShowList, { list: head }, function (headerData) { return React.createElement(MortgageCondition, __assign({}, headerData, { value: data[headerData.field] })); }))));
};
export default MortgageHeader;
