import { ThemeContext } from "@/containers/theme-container";
import { getThemeClassName } from "@/helpers/getThemeClassName";
import React, { useContext } from "react";
import "./index.scss";
var TextArea = function (_a) {
    var _b = _a.placeholder, placeholder = _b === void 0 ? '' : _b, value = _a.value, onChange = _a.onChange;
    var theme = useContext(ThemeContext).theme;
    var onChangeText = function (event) { return onChange((event.target.value)); };
    return React.createElement("textarea", { className: "textarea textarea_".concat(getThemeClassName(theme)), placeholder: placeholder, value: value, onChange: onChangeText });
};
export default TextArea;
