import React, { lazy, Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Repository from "@/repository/Repository";
import { Loading } from "@/components";
import StepsLayout from "@/layout/steps-layout";
import { generalScheme } from "@/options/repository-scheme/general";
var HomeLayout = lazy(function () { return import("@/layout/home-layout"); });
var BaseLayout = lazy(function () { return import("@/layout/base-layout"); });
var LoginPage = lazy(function () { return import("@/pages/login-page"); });
var Home = lazy(function () { return import("@/pages/home"); });
var StepsPage = lazy(function () { return import("@/pages/steps-page"); });
var ProfilePage = lazy(function () { return import("@/pages/profile-page"); });
var ListPage = lazy(function () { return import("@/pages/registrator/list-page"); });
var SinglePage = lazy(function () { return import("@/pages/registrator/single-page"); });
var ListNotaryPage = lazy(function () { return import("@/pages/notary/list-page"); });
var SingleNotaryPage = lazy(function () { return import("@/pages/notary/single-page"); });
export var routes = [
    {
        path: "/",
        private: false,
        element: React.createElement(Suspense, { fallback: React.createElement(Loading, null) },
            React.createElement(HomeLayout, { showSidebar: false })),
        children: [
            {
                path: "",
                private: false,
                element: React.createElement(Repository, { containers: [], scheme: {}, pageTemplate: Home }),
            },
        ],
    },
    {
        path: "/steps/",
        element: React.createElement(Suspense, { fallback: React.createElement(Loading, null) },
            React.createElement(StepsLayout, null)),
        children: [
            {
                path: "",
                forceRedirect: "/profile",
            },
            {
                path: ":uuid",
                private: true,
                element: React.createElement(Repository, { containers: ['init', 'order', 'step_2'], scheme: generalScheme, pageTemplate: StepsPage })
            },
        ],
    },
    {
        path: "/init/",
        element: React.createElement(Suspense, { fallback: React.createElement(Loading, null) },
            React.createElement(StepsLayout, null)),
        children: [
            {
                path: "",
                forceRedirect: "/login",
            },
            {
                path: "client",
                private: false,
                element: React.createElement(Repository, { options: { type: 'CLIENT' }, containers: ['init'], scheme: generalScheme, pageTemplate: StepsPage })
            },
            {
                path: "seller",
                private: false,
                element: React.createElement(Repository, { options: { type: 'SELLER' }, containers: ['init'], scheme: generalScheme, pageTemplate: StepsPage })
            },
            {
                path: "spouse",
                private: false,
                element: React.createElement(Repository, { options: {}, containers: ['init_spouse'], scheme: generalScheme, pageTemplate: StepsPage })
            },
        ],
    },
    {
        path: "/profile",
        private: true,
        element: React.createElement(Suspense, { fallback: React.createElement(Loading, null) },
            React.createElement(StepsLayout, null)),
        children: [
            {
                path: "",
                element: React.createElement(Repository, { containers: ['profile'], scheme: generalScheme, pageTemplate: ProfilePage }),
            },
        ],
    },
    {
        path: "/change-pass",
        private: true,
        element: React.createElement(Suspense, { fallback: React.createElement(Loading, null) },
            React.createElement(Outlet, null)),
        children: [
            {
                path: "",
                element: React.createElement(Repository, { containers: ['change_pass'], scheme: generalScheme, pageTemplate: LoginPage })
            },
        ],
    },
    {
        path: "/cabinet/",
        private: true,
        element: React.createElement(Suspense, { fallback: React.createElement(Loading, null) },
            React.createElement(BaseLayout, { showSidebar: false })),
        children: [
            {
                path: "",
                forceRedirect: "/login",
            },
            {
                path: "deals",
                element: React.createElement(Repository, { containers: ['list'], scheme: generalScheme, pageTemplate: ListPage })
            },
            {
                path: "detail/:uuid",
                element: React.createElement(Repository, { containers: ['detail'], scheme: generalScheme, pageTemplate: SinglePage })
            },
            {
                path: "notary-deals",
                element: React.createElement(Repository, { containers: ['list_notary'], scheme: generalScheme, pageTemplate: ListNotaryPage })
            },
            {
                path: "notary-detail/:uuid",
                element: React.createElement(Repository, { containers: ['detail_notary'], scheme: generalScheme, pageTemplate: SingleNotaryPage })
            },
        ],
    },
    {
        path: "/login",
        private: false,
        element: React.createElement(Suspense, { fallback: React.createElement(Loading, null) },
            React.createElement(Outlet, null)),
        children: [
            {
                path: "",
                element: React.createElement(Repository, { containers: ['login'], scheme: generalScheme, pageTemplate: LoginPage })
            },
        ],
    },
    {
        path: "*",
        element: React.createElement(Navigate, { to: "/" }),
    },
];
