import React from 'react';
import './index.scss';
import { ModalLink } from "@/components";
import { Trans, useTranslation } from "react-i18next";
var Checkbox = function (_a) {
    var label = _a.label, modalLink = _a.modalLink, modalLink2 = _a.modalLink2, captions = _a.captions, checked = _a.checked, onSelect = _a.onSelect, disabled = _a.disabled, onModal = _a.onModal, onModal2 = _a.onModal2;
    var t = useTranslation().t;
    return (React.createElement("label", { className: 'checkbox__wrapper' },
        React.createElement("input", { onChange: function (e) {
                e.stopPropagation();
                onSelect && onSelect(e);
            }, id: "checkbox-".concat(label), disabled: disabled, className: 'checkbox__input', type: 'checkbox', checked: checked }),
        React.createElement("span", { className: 'checkbox__checkmark' }),
        React.createElement("span", { className: 'checkbox__label' },
            React.createElement(Trans, { i18nKey: label },
                t(label),
                React.createElement(ModalLink, { onClick: onModal ? onModal : modalLink === null || modalLink === void 0 ? void 0 : modalLink.onClick }, t(label)),
                React.createElement(ModalLink, { onClick: modalLink2 === null || modalLink2 === void 0 ? void 0 : modalLink2.onClick }, t(label))))));
};
export default Checkbox;
