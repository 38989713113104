var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SubTitle from "@/components/ui/sub-title";
import Text from "@/components/ui/text/Text";
import { ShowGroup } from "@/components";
var LoanMessage = function (_a) {
    var title = _a.title, message = _a.message, message2 = _a.message2, subtitle = _a.subtitle, _b = _a.extraClass, extraClass = _b === void 0 ? '' : _b, style = _a.style;
    return (React.createElement("div", { className: "".concat(extraClass), style: __assign({ padding: '0 20px' }, style) },
        React.createElement(ShowGroup, { conditions: [!!title, !!message, !!message2, !!subtitle] },
            React.createElement(SubTitle, { text: title, style: { marginBottom: '8px' } }),
            React.createElement(Text, { text: message, style: { maxWidth: '453px', marginBottom: '8px' } }),
            React.createElement(Text, { text: message2, style: { maxWidth: '453px', marginBottom: '8px' } }),
            React.createElement("b", { className: 'message' }, subtitle))));
};
export default LoanMessage;
