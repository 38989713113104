export var downloadFileHelper = function (file, fileName, options) {
    if (fileName === void 0) { fileName = ''; }
    var link = document.createElement('a');
    if (options === null || options === void 0 ? void 0 : options.onNewWindow) {
        link.setAttribute('target', '_blank');
    }
    else {
        link.download = fileName;
    }
    link.href = typeof file === 'string' ? file : window.URL.createObjectURL(file);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
