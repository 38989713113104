var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import CryptoJS from "crypto-js";
import crypto from "crypto";
var NCALayerService = /** @class */ (function () {
    function NCALayerService(_a) {
        var onSignXmlGroupBack = _a.onSignXmlGroupBack, onSignXmlBack = _a.onSignXmlBack, onCreateCMSSignatureFromBase64Back = _a.onCreateCMSSignatureFromBase64Back, onCreateCAdESFromBase64HashBack = _a.onCreateCAdESFromBase64HashBack, onSignBack = _a.onSignBack, onError = _a.onError, onSuccess = _a.onSuccess, onMessage = _a.onMessage;
        var _this = this;
        this.url = "wss://127.0.0.1:13579/";
        this.webSocket = null;
        this.step = "NONE";
        this.keys = {};
        this.info = {};
        this.documentHash = null;
        this.iin = null;
        this.xml = null;
        this.blob = null;
        this.storageAlias = 'PKCS12';
        this.keyType = 'SIGNATURE';
        this.transformer = null;
        this.messages = {
            SIGN_SUCCESS_MESSAGE: { message: "\u041F\u043E\u0434\u043F\u0438\u0441\u0430\u043D\u0438\u0435 \u043F\u0440\u043E\u0448\u043B\u043E \u0443\u0441\u043F\u0435\u0448\u043D\u043E" },
            CONNECTION_ERROR_MESSAGE: { message: 'Ошибка соединения с NCALayer' },
        };
        this.onError = null;
        this.onSuccess = null;
        this.onSignBack = null;
        this.onMessage = null;
        this.onSignXmlGroupBack = null;
        this.onSignXmlBack = null;
        this.onCreateCMSSignatureFromBase64Back = null;
        this.onCreateCAdESFromBase64HashBack = null;
        this.init = function (callBack) {
            _this.webSocket = new WebSocket(_this.url);
            _this.webSocket.onopen = function () {
                callBack === null || callBack === void 0 ? void 0 : callBack();
            };
            _this.webSocket.onclose = function (event) {
                // console.log('onclose', event)
                _this.step = "NONE";
            };
            _this.webSocket.onmessage = function (event) {
                var data = JSON.parse(event.data);
                var result = new NcaResultData(data);
                if (result.getCode() === "200")
                    _this.onMessageHandler(result);
                else
                    _this.errorValidation(result);
            };
            _this.webSocket.onerror = function (event) {
                _this.onError(_this.messages.CONNECTION_ERROR_MESSAGE);
                console.log('onerror', _this.step);
                _this.webSocket.close();
            };
        };
        this.closeConnection = function () {
            _this.webSocket.close();
        };
        this.errorValidation = function (result) {
            // console.log('errorValidation', result);
            if (result.getCode() === "500") {
                if (result.getMessage() === "action.canceled")
                    return _this.onError({ message: "Отмена подписи" });
                _this.onError({ message: result.getMessage() });
            }
        };
        this.getKeyInfoBack = function (result) {
            var info = result.getResponseObject();
            _this.iin = (info === null || info === void 0 ? void 0 : info.serialNumber) || "";
            _this.info = info;
        };
        this.getInfo = function () { return _this.info; };
        this.getKeyInfo = function () {
            var args = [_this.storageAlias];
            _this.step = "KEY_INFO";
            _this.onSend('getKeyInfo', args);
        };
        this.signXml = function (blob) { return __awaiter(_this, void 0, void 0, function () {
            var data, _a, args;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.transformer.prepareDocToSign(blob, {
                            transformToBase64: true,
                            hashFromBuffer: true,
                            createXml: true,
                        })];
                    case 1:
                        data = _b.sent();
                        _a = this;
                        return [4 /*yield*/, this.transformer.prepareDocToSign(blob, {
                                transformToBase64: true,
                                hashFromBuffer: true
                            })];
                    case 2:
                        _a.documentHash = _b.sent();
                        this.blob = blob;
                        args = [this.storageAlias, this.keyType, data, "", ""];
                        this.step = "SIGN_XML";
                        this.onSend("signXml", args);
                        return [2 /*return*/];
                }
            });
        }); };
        this.signXmlGroup = function (xml) { return __awaiter(_this, void 0, void 0, function () {
            var _a, args;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.transformer.prepareDocToSign(xml, {
                                hashFromBase64: true
                            })];
                    case 1:
                        _a.documentHash = _b.sent();
                        args = [this.storageAlias, this.keyType, xml, "", ""];
                        this.step = "SIGN_XML_GROUPED";
                        this.onSend("signXml", args);
                        return [2 /*return*/];
                }
            });
        }); };
        this.onMessageHandler = function (result) {
            // console.log('onMessageHandler', {step: this.step, result})
            var _a, _b;
            if ((_a = _this.keys) === null || _a === void 0 ? void 0 : _a[_this.step]) {
                (_b = _this.keys) === null || _b === void 0 ? void 0 : _b[_this.step](result);
            }
            _this.onMessage(result);
        };
        this.onSend = function (method, args) {
            var data = {
                module: "kz.gov.pki.knca.commonUtils",
                method: method,
                args: args
            };
            _this.webSocket.send(JSON.stringify(data));
        };
        this.signXmlBack = function (result) {
            var signedData = result.getResponseObject();
            var res = {
                type: "SUCCESS", hash: _this.documentHash, blob: _this.blob, xml: signedData
            };
            _this.onSignXmlBack(res);
            _this.onSignBack(res);
            _this.closeConnection();
            return _this.onSuccess(_this.messages.SIGN_SUCCESS_MESSAGE);
        };
        this.signXmlGroupedBack = function (result) {
            var signedData = result.getResponseObject();
            var res = {
                type: "SUCCESS", hash: _this.documentHash, iin: _this.iin, xml: signedData
            };
            _this.onSignXmlGroupBack(res);
            _this.onSignBack(res);
            _this.closeConnection();
            return _this.onSuccess(_this.messages.SIGN_SUCCESS_MESSAGE);
        };
        this.createCMSSignatureFromBase64Back = function (result) {
            var signedData = result.getResponseObject();
            var res = {
                type: "SUCCESS", iin: _this.iin,
                signedData: signedData
            };
            _this.onCreateCMSSignatureFromBase64Back(res);
            _this.onSignBack(res);
            _this.closeConnection();
            return _this.onSuccess(_this.messages.SIGN_SUCCESS_MESSAGE);
        };
        this.createCMSSignatureFromBase64 = function (base64ToSign) {
            var args = [_this.storageAlias, _this.keyType, base64ToSign, true];
            _this.step = 'CMS_SIGNATURE_FROM_BASE64';
            _this.onSend('createCMSSignatureFromBase64', args);
        };
        this.createCAdESFromBase64Hash = function (base64) { return __awaiter(_this, void 0, void 0, function () {
            var data, args;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.transformer.prepareDocToSign(base64, {
                            hashFromBuffer: true
                        })];
                    case 1:
                        data = _a.sent();
                        args = [this.storageAlias, this.keyType, data];
                        this.step = "CMS_SIGNATURE_FROM_BASE64_HASH";
                        this.onSend("createCAdESFromBase64Hash", args);
                        return [2 /*return*/];
                }
            });
        }); };
        this.createCAdESFromBase64HashBack = function (result) {
            var signedData = result.getResponseObject();
            var res = {
                type: "SUCCESS", iin: _this.iin,
                signedData: signedData
            };
            _this.onCreateCAdESFromBase64HashBack(res);
            _this.onSignBack(res);
            _this.webSocket.close();
            return _this.onSuccess(_this.messages.SIGN_SUCCESS_MESSAGE);
        };
        this.onSignXmlBack = onSignXmlBack || console.log;
        this.onSignXmlGroupBack = onSignXmlGroupBack || console.log;
        this.onCreateCMSSignatureFromBase64Back = onCreateCMSSignatureFromBase64Back || console.log;
        this.onCreateCAdESFromBase64HashBack = onCreateCAdESFromBase64HashBack || console.log;
        this.onSuccess = onSuccess || console.log;
        this.onSignBack = onSignBack || console.log;
        this.onError = onError || console.log;
        this.onMessage = onMessage || console.log;
        this.transformer = new DocumentTransformer();
        this.keys = {
            "KEY_INFO": function (result) { return _this.getKeyInfoBack(result); },
            "SIGN_XML": function (result) { return _this.signXmlBack(result); },
            "SIGN_XML_GROUPED": function (result) { return _this.signXmlGroupedBack(result); },
            "CMS_SIGNATURE_FROM_BASE64": function (result) { return _this.createCMSSignatureFromBase64Back(result); },
            "CMS_SIGNATURE_FROM_BASE64_HASH": function (result) { return _this.createCAdESFromBase64HashBack(result); }
        };
    }
    return NCALayerService;
}());
export { NCALayerService };
var DocumentTransformer = /** @class */ (function () {
    function DocumentTransformer() {
        var _this = this;
        this.prepareDocToSign = function (document, _a) {
            var hashFromBuffer = _a.hashFromBuffer, hashFromBase64 = _a.hashFromBase64, hashFromBase64Sha1 = _a.hashFromBase64Sha1, createXml = _a.createXml, encodeToBase64 = _a.encodeToBase64, transformToBase64 = _a.transformToBase64;
            return __awaiter(_this, void 0, void 0, function () {
                var result;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            result = document;
                            if (!transformToBase64) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.transformFileToBase64(result)];
                        case 1:
                            result = _b.sent();
                            _b.label = 2;
                        case 2:
                            console.log('prepareDocToSign', {
                                hashFromBuffer: hashFromBuffer,
                                hashFromBase64: hashFromBase64,
                                hashFromBase64Sha1: hashFromBase64Sha1,
                                createXml: createXml,
                                encodeToBase64: encodeToBase64,
                                document: document,
                                result: result,
                                transformToBase64: transformToBase64
                            });
                            if (hashFromBuffer)
                                result = this.getDocumentHashFromBuffer(result);
                            else if (hashFromBase64)
                                result = this.getDocumentHashFromBase64(result);
                            else if (hashFromBase64Sha1)
                                result = this.getDocumentHashSha1(result);
                            if (createXml) {
                                result = "<PDFFileHash>" + result + "</PDFFileHash>";
                            }
                            if (encodeToBase64) {
                                result = this.getBase64EncodeUnicode(result);
                            }
                            return [2 /*return*/, result];
                    }
                });
            });
        };
        this.getDocumentHashSha1 = function (binaryStr) {
            var documentHash = CryptoJS.SHA1(binaryStr);
            return documentHash.toString();
        };
        this.getDocumentHashFromBase64 = function (binaryStr) {
            var file_wordArr = CryptoJS.lib.WordArray.create(binaryStr);
            var documentHash = CryptoJS.SHA1(file_wordArr);
            return documentHash.toString();
        };
        this.getBase64EncodeUnicode = function (str) {
            return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
                return String.fromCharCode(Number("0x" + p1));
            }));
        };
        this.getDocumentHashFromBuffer = function (binaryStr) {
            var decoded = Buffer.from(binaryStr, "base64");
            return crypto.createHash("sha1").update(decoded).digest("hex");
        };
        this.transformFileToBase64 = function (document) {
            return new Promise(function (resolve, reject) {
                var reader = new FileReader();
                reader.readAsDataURL(document);
                reader.onload = function () {
                    var _a, _b;
                    var result = this.result;
                    var from = ((_a = result === null || result === void 0 ? void 0 : result.search) === null || _a === void 0 ? void 0 : _a.call(result, ",")) + 1;
                    var to = result === null || result === void 0 ? void 0 : result.length;
                    var file = (_b = result === null || result === void 0 ? void 0 : result.substring) === null || _b === void 0 ? void 0 : _b.call(result, from, to);
                    resolve(file);
                };
                reader.onerror = function (error) {
                    reject(error);
                };
            });
        };
    }
    return DocumentTransformer;
}());
export { DocumentTransformer };
var NcaResultData = /** @class */ (function () {
    function NcaResultData(result) {
        var _this = this;
        this.code = null;
        this.message = null;
        this.responseObject = null;
        this.getMessage = function () {
            return _this.message;
        };
        this.getResponseObject = function () {
            return _this.responseObject;
        };
        this.getCode = function () {
            return _this.code;
        };
        this.code = result['code'];
        this.message = result['message'];
        this.responseObject = result['responseObject'];
    }
    return NcaResultData;
}());
