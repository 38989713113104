var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { createContext, useState } from 'react';
import { tableHead } from "./data";
import { useQuery } from "@/saga-query/query-hooks/useQuery";
import { useQueryCache } from "@/saga-query/query-hooks/useQueryCache";
import { debounce } from '@/helpers/debounce';
import { useNotification } from "@/saga-query/query-hooks/useNotification";
import { RegistrarApi } from "@/types/api";
import { useAuthorization } from "@/saga-query/query-hooks/useAuthorization";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
var defaultValue = {
    params: { limit: 10, page: 1, offset: 0 }
};
export var ListPageNotaryContext = createContext(defaultValue);
export var ListPageNotaryContextProvider = function (_a) {
    var children = _a.children, options = _a.options;
    var profile = useAuthorization().profile;
    var t = useTranslation().t;
    var _b = useState(defaultValue.params), params = _b[0], setParams = _b[1];
    var _c = useNotification(), onSuccess = _c.onSuccess, onWarn = _c.onWarn;
    var get = useQueryCache().get;
    var _d = useState([null, null]), rangeValue = _d[0], setRangeValue = _d[1];
    var _e = useState({
        type: null,
        isOpen: false,
        title: '',
        description: '',
    }), modalData = _e[0], setModalData = _e[1];
    var cacheParams = get(options.type).params;
    var _f = useQuery({
        api: '/notary/applications/',
        type: RegistrarApi.LOAD_APPLICATIONS2,
        data: params,
        refetchInterval: 60000,
        showErrorMessage: true,
    }), data = _f.data, isLoadingTable = _f.isLoading, refetch = _f.refetch;
    var statusesList = useQuery({
        api: '/notary/applications/statuses/',
        type: RegistrarApi.LOAD_STATUSES,
        showErrorMessage: true,
    }).data;
    var changeParam = function (key) { return function (value) {
        if (['page'].includes(key))
            setParams(function (prev) {
                var _a;
                return (__assign(__assign({}, prev), (_a = {}, _a[key] = value, _a.offset = (+value - 1) * prev.limit, _a)));
            });
        else if (['range'].includes(key)) {
            var _a = value, from_1 = _a[0], to_1 = _a[1];
            setParams(function (prev) { return (__assign(__assign({}, prev), { created_at__gte: from_1 && dayjs(from_1).format('YYYY-MM-DDTHH:mm:ss'), created_at__lte: to_1 && dayjs(to_1).format('YYYY-MM-DDTHH:mm:ss') })); });
        }
        else
            setParams(function (prev) {
                var _a;
                return (__assign(__assign({}, prev), (_a = {}, _a[key] = value, _a)));
            });
        if (['search'].includes(key))
            debounce(refetch, 500)();
        else
            refetch();
    }; };
    var tableParams = {
        data: ((data === null || data === void 0 ? void 0 : data.results) || []),
        head: tableHead.map(function (h) { return (__assign(__assign({}, h), { name: t(h.name) })); })
    };
    var onTableClear = function () {
        setParams(function (prev) { return (__assign(__assign({}, prev), { search: '', status: '' })); });
        setRangeValue([null, null]);
        refetch();
    };
    var fullName = [profile === null || profile === void 0 ? void 0 : profile.last_name, profile === null || profile === void 0 ? void 0 : profile.first_name, profile === null || profile === void 0 ? void 0 : profile.middle_name].filter(Boolean).join(' ');
    return React.createElement(ListPageNotaryContext.Provider, { value: {
            isLoading: { table: isLoadingTable },
            onTableClear: !!((params === null || params === void 0 ? void 0 : params.search) || (params === null || params === void 0 ? void 0 : params.status) || (params === null || params === void 0 ? void 0 : params.created_at__gte) || (params === null || params === void 0 ? void 0 : params.created_at__lte)) && onTableClear,
            params: params,
            changeParam: changeParam,
            rangeValue: rangeValue,
            setRangeValue: setRangeValue,
            statusesList: (statusesList === null || statusesList === void 0 ? void 0 : statusesList.map(function (_a) {
                var key = _a.key, value = _a.value;
                return ({ id: key, name: t("registrar.statuses.".concat(value)) });
            })) || [],
            tableHead: tableParams.head,
            tableData: tableParams.data || [],
            options: {
                pageTitle: t('registrar.lc_notary'),
                subtitle: "".concat(t('registrar.notary'), " ").concat(fullName),
                count: data === null || data === void 0 ? void 0 : data.count,
            },
        } }, children);
};
