import React from "react";
import Text from "@/components/ui/text/Text";
import SVG from "react-inlinesvg";
import SubTitle from "@/components/ui/sub-title";
import './index.scss';
import { useTranslation } from "react-i18next";
var Phone = require('public/chunks/sidebar/telephone-fill.svg');
var Instagram = require('public/chunks/sidebar/instagram.svg');
var Whatsapp = require('public/chunks/sidebar/whatsapp.svg');
var Tiktok = require("public/assets/icons/tiktok.svg");
var Facebook = require('public/chunks/sidebar/faceboook-circle.svg');
var icons = {
    facebook: Facebook,
    instagram: Instagram,
    whatsapp: Whatsapp,
};
export var socialLinks = [
    { icon: Instagram, link: 'https://www.instagram.com/bankffin.kz' },
    { icon: Facebook, link: 'https://www.facebook.com/bankffin.kz' },
    { icon: Tiktok, link: 'https://vt.tiktok.com/ZSdGVVBCU' },
];
var SocialLink = function (_a) {
    var social = _a.social;
    return React.createElement(React.Fragment, null, socialLinks.map(function (soc, key) {
        return React.createElement("a", { key: key, href: soc.link, target: "_blank", className: 'sidebar-footer__icon-wrapper' },
            React.createElement(SVG, { src: soc.icon, width: 16, height: 16 }));
    }));
};
var SidebarFooter = function () {
    var t = useTranslation().t;
    return (React.createElement("div", { className: 'sidebar-footer__wrapper' },
        React.createElement("div", { className: 'sidebar-footer__left' },
            React.createElement("div", { className: 'sidebar-footer__phone-container' },
                React.createElement(SVG, { src: Phone, width: 32, height: 32 }),
                React.createElement(SubTitle, { text: '595', style: { fontSize: '24px', margin: '0' } })),
            React.createElement(Text, { text: t('flow.layout.call'), style: { margin: '0' } })),
        React.createElement("div", { className: 'sidebar-footer__right' },
            React.createElement(SocialLink, null))));
};
export default SidebarFooter;
