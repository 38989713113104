import React from 'react';
import SVG from "react-inlinesvg";
import './styles.scss';
var Sun = require('public/theme/sun.svg');
var Moon = require('public/theme/moon.svg');
var DarkModeToggleButton = function (_a) {
    var theme = _a.theme, changeTheme = _a.changeTheme;
    return React.createElement("div", { className: "darkmode-toggle" },
        React.createElement("input", { type: "checkbox", onChange: changeTheme, id: "darkmode-toggle", checked: theme === 'dark-theme' }),
        React.createElement("label", { htmlFor: "darkmode-toggle" },
            React.createElement(SVG, { src: Sun }),
            React.createElement(SVG, { src: Moon })));
};
export default DarkModeToggleButton;
