import React, { useMemo } from 'react';
import { ShowIf, ShowList } from '@/components';
import './styles.scss';
import SVG from "react-inlinesvg";
var Arrow = require('public/ui/pagination/arrow.svg');
var Pagination = function (_a) {
    var value = _a.value, pages = _a.pages, onClick = _a.onClick, _b = _a.maxSize, maxSize = _b === void 0 ? 7 : _b;
    var pagesList = Array.from({ length: Math.ceil(pages) }, function (_, i) { return i + 1; });
    var cutResult = useMemo(function () {
        var mid = Math.floor(maxSize / 2);
        var start = value > (mid + 1) ? value + (mid + 1) >= pages ? pages - (maxSize - 1) : value - (mid - 1) : 1;
        var end = value > mid ? value + mid >= pages ? pages + 1 : mid + value : maxSize;
        return { start: start, end: end, mid: mid };
    }, [value, maxSize, pages,]);
    return (React.createElement("div", { className: "ui-pagination" },
        React.createElement(ShowIf, { condition: value > 1 },
            React.createElement("div", { onClick: function () { return onClick(value - 1); }, className: "ui-pagination__arrow left" },
                React.createElement(SVG, { src: Arrow }))),
        React.createElement(ShowIf, { condition: cutResult.start > 1 },
            React.createElement("div", { onClick: function () { return onClick(1); }, className: "ui-pagination__item" }, "1"),
            React.createElement("div", { className: "ui-pagination__dots" }, "...")),
        React.createElement(ShowList, { list: pagesList.slice(cutResult.start - 1, cutResult.end - 1) }, function (val) { return React.createElement("div", { key: val, onClick: function () { return value !== val && onClick(val); }, className: "ui-pagination__item".concat(value === val ? ' active' : '') }, val); }),
        React.createElement(ShowIf, { condition: cutResult.end + cutResult.mid < pages || (maxSize + cutResult.end > pages && pages > maxSize - 1) && cutResult.end !== pages + 1 },
            React.createElement("div", { className: "ui-pagination__dots" }, "..."),
            React.createElement("div", { onClick: function () { return onClick(pages); }, className: "ui-pagination__item" }, pages)),
        React.createElement(ShowIf, { condition: pages > value },
            React.createElement("div", { onClick: function () { return onClick(value + 1); }, className: "ui-pagination__arrow right" },
                React.createElement(SVG, { src: Arrow })))));
};
export default Pagination;
