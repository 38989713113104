import React from 'react';
import './index.scss';
import SVG from "react-inlinesvg";
var House = require('public/chunks/steps/house.svg');
var ProgressBar = function (_a, contrast) {
    var currentProgress = _a.currentProgress, _b = _a.extraClass, extraClass = _b === void 0 ? '' : _b, _c = _a.style, style = _c === void 0 ? {} : _c;
    if (contrast === void 0) { contrast = true; }
    return (React.createElement("div", { className: "progress-bar__wrapper ".concat(extraClass), style: style },
        React.createElement("div", { className: 'progress-bar__background-line' },
            React.createElement("div", { className: 'progress-bar__progress-line', style: { width: "".concat(currentProgress, "%") } },
                React.createElement(SVG, { src: House, width: 25, height: 26, style: contrast ? { filter: "contrast(".concat(currentProgress, "%)") } : {} })))));
};
export default ProgressBar;
