import React, { useEffect, useState } from "react";
import './index.scss';
import { useTranslation } from "react-i18next";
var Tabs = function (_a) {
    var navList = _a.navList, children = _a.children, navElem = _a.navElem, active = _a.active, className = _a.className, getActiveTab = _a.getActiveTab, isDisabled = _a.isDisabled, _b = _a.defaultActiveIndex, defaultActiveIndex = _b === void 0 ? 0 : _b;
    var t = useTranslation().t;
    var _c = useState(navList.findIndex(function (nav) { return nav.tabsPanel === active.tabsPanel; }) || defaultActiveIndex), activeTab = _c[0], setActiveTab = _c[1];
    var getNavElement = function (tab, key) {
        var isActive = key === activeTab;
        var onClick = function () { return !isDisabled && setActiveTab(key); };
        var navItem = navElem
            ? typeof navElem === "function"
                ? navElem({ tab: tab, isActive: isActive })
                : navElem
            : null;
        return navItem ? (React.cloneElement(navItem, { key: key, onClick: onClick })) : (React.createElement("div", { key: key, onClick: onClick, className: "ui-tabs__link".concat(isActive ? " active" : "") }, t(tab === null || tab === void 0 ? void 0 : tab.title)));
    };
    useEffect(function () {
        getActiveTab && getActiveTab(navList[activeTab]);
    }, [activeTab]);
    return (React.createElement("div", { className: "ui-tabs ".concat(className || "") },
        React.createElement("div", { className: "ui-tabs__navigation" }, navList.map(getNavElement)),
        React.createElement("div", { className: "ui-tabs__outlet" }, children(navList[activeTab], activeTab))));
};
export default Tabs;
