var _a;
// Запрет к страницам по ролям
export var rules = (_a = {},
    _a['ADMIN'] = [],
    _a['CREDIT_ADMIN'] = [],
    _a['SUPERVISOR'] = [],
    _a['MANAGER'] = [],
    _a['TELEMARKETING_ADMIN'] = [],
    _a['SERVICEDESC_ADMIN'] = [],
    _a);
// Доступ к компонентам по ролям
// REASSIGN BUTTONS REVEAL APPROVE DOCUMENT_ARCHIVE DOCUMENT_RESTORE DOCUMENT_ADD DOCUMENT_UPDATE DOCUMENTS_DELETE
export var accessAtoms = {
    ADMIN: [
        'REASSIGN',
        'BUTTONS',
        'REASSIGN',
        'REVEAL',
        'APPROVE',
        'DOCUMENT_ARCHIVE',
        'DOCUMENT_RESTORE',
        'DOCUMENT_ADD',
        'DOCUMENT_UPDATE',
        'DOCUMENTS_DELETE'
    ],
    CREDIT_ADMIN: [
        'REASSIGN',
        'BUTTONS',
        'REASSIGN',
        'REVEAL',
        'APPROVE',
        'DOCUMENT_ARCHIVE',
        'DOCUMENT_RESTORE',
    ],
    SUPERVISOR: [
        'REASSIGN',
        'BUTTONS',
        'REASSIGN',
        'REVEAL',
        'APPROVE',
        'DOCUMENT_ARCHIVE',
        'DOCUMENT_RESTORE',
        'DOCUMENT_ADD',
        'DOCUMENT_UPDATE',
        'DOCUMENTS_DELETE'
    ],
    MANAGER: [],
    TELEMARKETING_ADMIN: [],
    SERVICEDESC_ADMIN: [
        'DOCUMENT_ADD',
        'DOCUMENT_UPDATE',
        'DOCUMENT_DELETE'
    ],
};
