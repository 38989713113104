var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { createContext, useState, } from "react";
import { useQueryCache } from "@/saga-query/query-hooks/useQueryCache";
import { Api } from "@/types/api";
import { useMutation } from "@/saga-query/query-hooks/useMutation";
import { useNotification } from "@/saga-query/query-hooks/useNotification";
import { useProgress } from "@/hooks/useProgress";
import { useParams } from "react-router-dom";
export var Context = createContext({});
export var ContextProvider = function (_a) {
    var children = _a.children;
    var get = useQueryCache().get;
    var uuid = useParams().uuid;
    var order = get([Api.LOAD_APPLICATION_BY_UUID, uuid]).data;
    var _b = useNotification(), onSuccess = _b.onSuccess, onWarn = _b.onWarn;
    var _c = useState({}), params = _c[0], setParams = _c[1];
    var _d = useState('FORENCIS'), service = _d[0], setService = _d[1];
    var changeParam = function (key) { return function (value) {
        if (['iin', 'phone'].includes(key)) {
            return setParams(function (prev) {
                var _a;
                return (__assign(__assign({}, prev), (_a = {}, _a[key] = value.replace(/[^+\d]/g, ''), _a)));
            });
        }
        setParams(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[key] = value, _a)));
        });
    }; };
    var progressProps = useProgress('BIOMETRY', console.log);
    var _e = useMutation({
        api: "/applications/".concat(uuid, "/biometry-verify/"),
        method: 'POST',
        type: Api.POST_BIOMETRY_RESULT,
        typeToUpdate: [Api.LOAD_APPLICATION_BY_UUID, uuid],
        showErrorMessage: true,
        onSuccess: progressProps.setDone
    }), onForencis = _e[0], isLoadingOnForencis = _e[1].isLoading;
    var _f = useState({
        type: null,
        isOpen: false,
        title: '',
        description: '',
    }), modalData = _f[0], setModalData = _f[1];
    var getAcceptFunctionByType = function (type) {
        var fnData = {
            'CONFIRMATION': function () {
            },
        };
        return fnData[type] || console.log;
    };
    var isDisabledModalFooterByType = function (type) {
        var ACCEPT = false;
        switch (type) {
            case 'CONFIRMATION':
                ACCEPT = false;
                break;
        }
        return { ACCEPT: ACCEPT };
    };
    var onModalClose = function () {
        setModalData(__assign(__assign({}, modalData), { isOpen: false }));
    };
    return (React.createElement(Context.Provider, { value: {
            isLoading: {
                step2: isLoadingOnForencis
            },
            isDisabled: {
                step2: isLoadingOnForencis || [
                    'CLIENT_BIOMETRY_VERIFICATION',
                    'SELLER_BIOMETRY_VERIFICATION',
                    'CLIENT_SPOUSE_BIOMETRY_VERIFICATION',
                    'SELLER_SPOUSE_BIOMETRY_VERIFICATION',
                ].includes(order === null || order === void 0 ? void 0 : order.status)
            },
            progressProps: progressProps,
            showElem: {},
            step2Props: {
                params: params,
                changeParam: changeParam,
                service: service,
                onForencis: function () {
                    progressProps.setStart();
                    onForencis();
                },
                onDid: function () { return console.log('onDid'); },
                modalParams: {
                    modalData: modalData,
                    isDisabled: isDisabledModalFooterByType(modalData === null || modalData === void 0 ? void 0 : modalData.type),
                    onAccept: getAcceptFunctionByType(modalData === null || modalData === void 0 ? void 0 : modalData.type),
                    modalFooterData: [],
                    onModalClose: onModalClose,
                }
            }
        } }, children));
};
export default { context: Context, provider: ContextProvider };
