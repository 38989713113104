import React from 'react';
import './styles.scss';
import { ShowIf } from "@/components";
import SVG from "react-inlinesvg";
var Loading = require('public/loading.svg');
var ToggleButton = function (_a) {
    var value = _a.value, onChange = _a.onChange, loading = _a.loading, disabled = _a.disabled;
    return React.createElement("div", { className: "toggle-btn" },
        React.createElement(ShowIf, { condition: loading },
            React.createElement("div", { className: "toggle-btn__loading".concat(value ? ' checked' : '') },
                React.createElement(SVG, { src: Loading }))),
        React.createElement("input", { type: "checkbox", onChange: !disabled ? onChange : function () {
            }, id: "toggle-btn", checked: value }),
        React.createElement("label", { htmlFor: "toggle-btn" },
            React.createElement("div", { className: 'sun' }),
            React.createElement("div", { className: 'moon' })));
};
export default ToggleButton;
