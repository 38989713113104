var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useDispatch, useSelector } from "react-redux";
import { clearAllNotifications, notificationsListSelector, removeNotification, setNotification } from "@/saga-query/ducks/notification";
var id = 0;
export var useNotification = function () {
    var dispatch = useDispatch();
    var list = useSelector(notificationsListSelector);
    var onError = function (params) { return dispatch(setNotification(__assign(__assign({}, params), { id: ++id, type: 'ERROR' }))); };
    var onSuccess = function (params) { return dispatch(setNotification(__assign(__assign({}, params), { id: ++id, type: 'SUCCESS' }))); };
    var onWarn = function (params) { return dispatch(setNotification(__assign(__assign({}, params), { id: ++id, type: 'WARN' }))); };
    var add = function (params) { return dispatch(setNotification(__assign(__assign({}, params), { id: ++id }))); };
    var onRemove = function (index) { return dispatch(removeNotification(index)); };
    var onClearAll = function () { return dispatch(clearAllNotifications()); };
    return { list: list, onError: onError, onSuccess: onSuccess, onWarn: onWarn, onClearAll: onClearAll, onRemove: onRemove, add: add };
};
