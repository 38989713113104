import { ThemeContext } from "@/containers/theme-container/";
import { getThemeClassName } from "@/helpers/getThemeClassName";
import "./index.scss";
import React, { useContext } from "react";
var ListCount = function (_a) {
    var title = _a.title, count = _a.count, _b = _a.className, className = _b === void 0 ? '' : _b;
    var theme = useContext(ThemeContext).theme;
    return (React.createElement("div", { className: "list-count list-count_".concat(getThemeClassName(theme), " d-f a-i-c ").concat(className) },
        React.createElement("span", { className: "m-r-5" }, title),
        " ",
        React.createElement("b", null, count.toLocaleString("ru-RU"))));
};
export default ListCount;
