import { getSexFromIIN } from "@/helpers/getSexFromIin";
import { useTranslation } from "react-i18next";
export var texts = function (iin) {
    var t = useTranslation().t;
    return ({
        title: t('flow.dear'),
        body: [
            getSexFromIIN(iin) === "FEMALE"
                ? t('flow.step1.indicated2')
                : t('flow.step1.indicated') + t("flow.step1.seller_title2"),
            t("flow.step1.if_agree"),
        ],
        subtitle: t('flow.step1.proc')
    });
};
export var conditionsHeader = {
    title: 'flow.fill_real_estate.deal_conditions',
    type: 'default'
};
export var conditionsHead = [
    {
        title: 'flow.input.building_price',
        field: 'price',
        type: 'MONEY',
        icon: 'HOME'
    }
];
export var infoData = [
    { title: 'flow.input.address', value: 'address' }
];
export var infoCadastralData = [
    { title: 'flow.input.cadastral', value: 'cadastral_number' }
];
