import { useCallback, useEffect, useRef } from 'react';
import { useAuthorization } from "@/saga-query/query-hooks/useAuthorization";
export var useActivity = function (time) {
    if (time === void 0) { time = 900000; }
    var _a = useAuthorization(), isAuth = _a.isAuth, onLogout = _a.onLogout;
    var doc = useRef(window.document);
    var timeout = useRef(null);
    var onMouseMove = useCallback(function () {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(function () {
            isAuth && onLogout();
        }, time);
    }, [isAuth, timeout.current]);
    useEffect(function () {
        var _a, _b;
        (_b = (_a = doc.current) === null || _a === void 0 ? void 0 : _a.addEventListener) === null || _b === void 0 ? void 0 : _b.call(_a, 'mousemove', onMouseMove);
        return function () {
            var _a;
            clearTimeout(timeout.current);
            (_a = doc.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('mousemove', onMouseMove);
        };
    }, [timeout.current, doc.current, onMouseMove]);
};
