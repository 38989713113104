import { appQueryClear, queryDataStateByIdSelector, } from "@/saga-query/ducks/app";
import { useDispatch, useSelector } from "react-redux";
export var useQueryCache = function () {
    // HOOKS
    var dispatch = useDispatch();
    // FUNCTIONS
    var get = function (type) { return useSelector(function (state) {
        return queryDataStateByIdSelector(state, Array.isArray(type) ? type.join('_') : String(type))(state);
    }); };
    var clear = function (type) { return dispatch(appQueryClear(Array.isArray(type) ? type.join('_') : String(type))); };
    return { get: get, clear: clear };
};
