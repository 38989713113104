import ShowIf from "@/components/other/show-if";
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import "./index.scss";
var Eye = require("public/eye.svg");
var PassInput = function (_a) {
    var onChange = _a.onChange, value = _a.value, placeholder = _a.placeholder, ref = _a.ref;
    var _b = useState(false), isVisible = _b[0], setIsVisible = _b[1];
    var handleClick = function () { return function (e) {
        e.preventDefault();
        setIsVisible(function (prevState) { return !prevState; });
    }; };
    return (React.createElement("div", { className: "pass-input__container" },
        React.createElement("input", { style: { width: "100%" }, className: "text-input__input", type: isVisible ? "text" : "password", placeholder: placeholder || "Введите пароль", value: value, onChange: onChange }),
        React.createElement(ShowIf, { condition: (value === null || value === void 0 ? void 0 : value.length) > 0 },
            React.createElement("button", { className: "pass-input__btn", onClick: handleClick() },
                React.createElement(SVG, { src: Eye, width: 20, height: 20 })))));
};
export default PassInput;
