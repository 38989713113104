var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import './styles.scss';
import { ShowList } from "@/components";
var Up = require('public/up.svg');
var Down = require('public/down.svg');
var Select = function (_a) {
    var _b, _c;
    var disabled = _a.disabled, shadow = _a.shadow, border = _a.border, className = _a.className, onFocus = _a.onFocus, style = _a.style, props = __rest(_a, ["disabled", "shadow", "border", "className", "onFocus", "style"]);
    var _d = useState(false), dropdown = _d[0], setDropdown = _d[1];
    var _e = useState(''), title = _e[0], setTitle = _e[1];
    var _f = useState(''), placeholder = _f[0], setPlaceholder = _f[1];
    var _g = useState(''), value = _g[0], setValue = _g[1];
    console.log('Select', { value: value, placeholder: placeholder, title: title, props: props });
    var dropdownContainer = React.useRef(null);
    var dropdownLink = React.useRef(null);
    useEffect(function () {
        var _a, _b;
        props.placeholder &&
            !value &&
            !props.value &&
            setPlaceholder(props.placeholder);
        if (((_a = props === null || props === void 0 ? void 0 : props.list) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            (_b = props === null || props === void 0 ? void 0 : props.list) === null || _b === void 0 ? void 0 : _b.map(function (item) {
                return (item === null || item === void 0 ? void 0 : item.id) == value &&
                    setPlaceholder((item === null || item === void 0 ? void 0 : item.name) || (item === null || item === void 0 ? void 0 : item.verbose_name));
            });
        }
    }, [props.placeholder, props.value, value, (_b = props === null || props === void 0 ? void 0 : props.list) === null || _b === void 0 ? void 0 : _b.length]);
    // Close dropdowns (start)
    var handleClickOutside = function (event) {
        if (dropdownContainer.current &&
            !dropdownContainer.current.contains(event.target) &&
            dropdownLink.current &&
            !dropdownLink.current.contains(event.target)) {
            setDropdown(false);
        }
    };
    useEffect(function () {
        var _a;
        document.addEventListener('mousedown', handleClickOutside);
        if (props.value && ((_a = props.list) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            setValue(props.value);
            props === null || props === void 0 ? void 0 : props.list.map(function (i) {
                return (i === null || i === void 0 ? void 0 : i.id) === props.value &&
                    setPlaceholder((i === null || i === void 0 ? void 0 : i.name) || (i === null || i === void 0 ? void 0 : i.verbose_name));
            });
        }
        if (props.placeholder && !props.value && props.value === '') {
            setPlaceholder(props.placeholder);
        }
        if (props.placeholder && props.placeholder !== placeholder) {
            setPlaceholder(props.placeholder);
        }
        if (props.value !== value) {
            setValue(props.value);
        }
        if (props.value === '' &&
            props.placeholder === '---' &&
            props.placeholder !== '---') {
            setPlaceholder(props.placeholder);
        }
    }, [value, props.value, (_c = props.list) === null || _c === void 0 ? void 0 : _c.length, props.placeholder]);
    var selectItem = function (item, index) {
        var _a;
        props.onChange(item === null || item === void 0 ? void 0 : item.id, index);
        setDropdown(false);
        if ((props === null || props === void 0 ? void 0 : props.list) && ((_a = props === null || props === void 0 ? void 0 : props.list) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            props === null || props === void 0 ? void 0 : props.list.map(function (i) {
                return (i === null || i === void 0 ? void 0 : i.id) === (item === null || item === void 0 ? void 0 : item.id) &&
                    setPlaceholder((i === null || i === void 0 ? void 0 : i.name) || (i === null || i === void 0 ? void 0 : i.verbose_name));
            });
        }
    };
    return React.createElement("div", { onClick: onFocus, className: "select__wrapper ".concat(className || '') },
        props.label && React.createElement("label", { className: "select__label" }, props.label),
        React.createElement("div", { className: "input", ref: dropdownLink },
            title && React.createElement("label", { className: "select__title" }, title),
            React.createElement("div", { className: "select ".concat(shadow ? 'shadow' : '', " ").concat(border ? 'border' : '', " ").concat(disabled ? 'disabled' : ''), style: style, onClick: function () { return !disabled && setDropdown(function (prev) { return !prev; }); } },
                React.createElement("div", { className: "select__label" },
                    placeholder,
                    React.createElement("div", { className: "select__interface" }, dropdown ? React.createElement(SVG, { src: Up }) : React.createElement(SVG, { src: Down }))),
                React.createElement("div", { className: "dropdown", style: {
                        display: dropdown ? 'block' : 'none',
                        maxHeight: "".concat(window.innerHeight * 0.5, "px"),
                    }, ref: dropdownContainer },
                    React.createElement(ShowList, { list: props.list }, function (item, index) { return (React.createElement("div", { key: index, className: "dropdown__item ".concat(value === (item === null || item === void 0 ? void 0 : item.id) ? 'active' : ''), onClick: function (e) {
                            e.stopPropagation();
                            selectItem(item, index);
                        } }, (item === null || item === void 0 ? void 0 : item.name) || (item === null || item === void 0 ? void 0 : item.verbose_name) || '')); })))));
};
export default Select;
