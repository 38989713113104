import React from "react";
import './index.scss';
import { ShowIf, ShowList, ShowSwitch } from "@/components";
import { getStatusInfo } from "@/helpers/getStatusesInfo";
import SubTitle from "../../ui/sub-title";
import SVG from "react-inlinesvg";
import { downloadFileHelper } from "@/helpers/downloadFileHelper";
import { useTranslation } from "react-i18next";
import configs from "@/saga-query/constants";
var Loading = require('public/loading.svg');
var PDF = require('../../../assets/assets/icons/pdf.svg');
var AppList = function (_a) {
    var list = _a.list, onClick = _a.onClick, pageTitle = _a.pageTitle, isLoading = _a.isLoading;
    var t = useTranslation().t;
    return (React.createElement("div", { className: "app-list pointer" },
        pageTitle && React.createElement(SubTitle, { text: pageTitle, style: { marginBottom: '10px', marginLeft: '10px' } }),
        React.createElement(ShowSwitch, { conditions: [isLoading] },
            React.createElement("div", { className: "app-list__loading" },
                React.createElement("div", { className: "table-loader" })),
            React.createElement(ShowList, { list: list }, function (app, key) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
                return React.createElement("div", { onClick: function () { return onClick(app); }, key: key, className: "app-list__item" },
                    React.createElement("div", null,
                        React.createElement("div", { className: "gray" }, "\u2116"),
                        React.createElement("span", null, app.id)),
                    React.createElement("div", null,
                        React.createElement("div", { className: "gray" }, t('flow.profile.buyer_fio')),
                        React.createElement("span", null, [
                            (_b = (_a = app === null || app === void 0 ? void 0 : app.client) === null || _a === void 0 ? void 0 : _a.personal_record) === null || _b === void 0 ? void 0 : _b.last_name,
                            (_d = (_c = app === null || app === void 0 ? void 0 : app.client) === null || _c === void 0 ? void 0 : _c.personal_record) === null || _d === void 0 ? void 0 : _d.first_name,
                            (_f = (_e = app === null || app === void 0 ? void 0 : app.client) === null || _e === void 0 ? void 0 : _e.personal_record) === null || _f === void 0 ? void 0 : _f.middle_name,
                        ].filter(Boolean).join(' ') || '---')),
                    React.createElement("div", null,
                        React.createElement("div", { className: "gray" }, t('flow.profile.buyer_phone')),
                        React.createElement("span", null, (_h = (_g = app.client) === null || _g === void 0 ? void 0 : _g.user) === null || _h === void 0 ? void 0 : _h.phone)),
                    React.createElement("div", null,
                        React.createElement("div", { className: "gray" }, t('flow.profile.seller_fio')),
                        React.createElement("span", null, [
                            (_k = (_j = app === null || app === void 0 ? void 0 : app.seller) === null || _j === void 0 ? void 0 : _j.personal_record) === null || _k === void 0 ? void 0 : _k.last_name,
                            (_m = (_l = app === null || app === void 0 ? void 0 : app.seller) === null || _l === void 0 ? void 0 : _l.personal_record) === null || _m === void 0 ? void 0 : _m.first_name,
                            (_p = (_o = app === null || app === void 0 ? void 0 : app.seller) === null || _o === void 0 ? void 0 : _o.personal_record) === null || _p === void 0 ? void 0 : _p.middle_name,
                        ].filter(Boolean).join(' ') || '---')),
                    React.createElement("div", null,
                        React.createElement("div", { className: "gray" }, t('flow.profile.seller_phone')),
                        React.createElement("span", null, (_r = (_q = app.seller) === null || _q === void 0 ? void 0 : _q.user) === null || _r === void 0 ? void 0 : _r.phone)),
                    React.createElement("div", null,
                        React.createElement("div", { className: "gray" }, t('flow.profile.status')),
                        React.createElement("span", { className: getStatusInfo(app.status).className }, t(getStatusInfo(app.status).text))),
                    React.createElement(ShowIf, { condition: !!((_s = app === null || app === void 0 ? void 0 : app.docs) === null || _s === void 0 ? void 0 : _s.file) },
                        React.createElement("div", null,
                            React.createElement("div", { className: "gray" }, t('flow.profile.dkp')),
                            React.createElement("div", { className: "d-f a-i-c pointer", onClick: function (e) {
                                    var _a;
                                    e.stopPropagation();
                                    downloadFileHelper("".concat(configs.SERVER.substr(0, configs.SERVER.length - 4)).concat((_a = app === null || app === void 0 ? void 0 : app.docs) === null || _a === void 0 ? void 0 : _a.file), 'Договор купли-продажи', { onNewWindow: true });
                                } },
                                React.createElement(SVG, { src: PDF, width: 20, className: "pointer" }),
                                React.createElement("span", { className: "m-l-5" }, t('flow.profile.download'))))));
            }))));
};
export default AppList;
