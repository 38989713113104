import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import kz_calc from './kz_calc.json';
import kz_home from './kz_home.json';
import kz_steps2 from './kz_steps2.json';
import kz_registrar from './kz_registrar.json';
import kz_home2 from './kz_home-page.json';
import en_home from './en_home.json';
import en_calc from './en_calc.json';
import en_steps from './en_steps.json';
import en_seller from './en_seller.json';
import ru_home from './ru_home.json';
import ru_home2 from './ru_home-page.json';
import ru_calc from './ru_calc.json';
import ru_steps2 from './ru_steps2.json';
import ru_steps from './ru_steps.json';
import ru_seller from './ru_seller.json';
import ru_registrar from './ru_registrar.json';
import ru_flow from './ru_flow.json';
import kz_flow from './kz_flow.json';
import dayjs from "dayjs";

let lang = 'ru';

if (localStorage.getItem('lang')) {
	lang = localStorage.getItem('lang');
  dayjs.locale(lang == 'kz' ? 'kk': 'ru')
} else {
	localStorage.setItem('lang', 'ru');
  dayjs.locale('ru')
}

i18n.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		react: {
			useSuspense: true,
			wait: true,
		},
		lng: lang,
		fallbackLng: lang,
		debug: false,
		resources: {
			ru: {
				home: ru_home2,
				home2: ru_home,
				calc: ru_calc,
				steps: ru_steps,
				steps2: ru_steps2,
				seller: ru_seller,
        registrar: ru_registrar,
        flow: ru_flow,
			},
			kz: {
				home: kz_home2,
				home2: kz_home,
				calc: kz_calc,
				steps: ru_steps,
				steps2: kz_steps2,
				seller: ru_seller,
        registrar: kz_registrar,
        flow: kz_flow,
			},
			en: {
				home: en_home,
				home2: en_home,
				calc: en_calc,
				steps: en_steps,
				seller: en_seller,
			},
		},
		detection: {
			order: ['queryString', 'cookie'],
			cache: ['cookie'],
		},
		interpolation: {
			escapeValue: false,
		},
		ns: ['steps', 'home', 'calc', 'seller', 'steps2', 'home2', 'registrar', 'flow'],
		defaultNS: 'home',
		nsSeparator: '.',
		nonExplicitWhitelist: true,
	});

export {i18n}
