var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import './index.scss';
import SubTitle from "@/components/ui/sub-title";
import ConditionBlock from "@/components/chunks/borrowing-conditions/condition-block";
import SVG from "react-inlinesvg";
import { getConditionIcon } from "@/options/chunks/getConditionIcon";
import { BaseContainer, Button, ShowIf, ShowList } from "@/components";
import { flatObject } from "@/helpers/flatObject";
import { useTranslation } from "react-i18next";
var BorrowingConditions2 = function (_a) {
    var head = _a.head, theme = _a.theme, header = _a.header, data = _a.data, _b = _a.changeable, changeable = _b === void 0 ? true : _b, _c = _a.extraClass, extraClass = _c === void 0 ? '' : _c;
    var t = useTranslation().t;
    var transformedData = flatObject(data);
    return (React.createElement("section", { className: "borrowing-conditions__container ".concat(extraClass) },
        React.createElement(ShowIf, { condition: !!header.title },
            React.createElement("div", { style: { padding: '16px 0 0' }, className: "borrowing-conditions__header borrowing-conditions__header_".concat(header.type) },
                React.createElement(SubTitle, { theme: theme, text: t(header.title), style: { margin: '0' } }))),
        React.createElement(ShowList, { list: head }, function (block, i) {
            if (Array.isArray(block))
                return (React.createElement("div", { key: i, className: 'borrowing-conditions__row' }, block.map(function (condition) {
                    return (React.createElement(ConditionBlock, __assign({ key: condition.field }, condition, { style: { padding: '16px 0' }, value: transformedData[condition.field], icon: React.createElement(SVG, { src: getConditionIcon(condition.icon), width: 20, height: 20 }) })));
                })));
            return (React.createElement(ConditionBlock, __assign({}, block, { style: { padding: '16px 0' }, value: transformedData[block.field], icon: React.createElement(SVG, { src: getConditionIcon(block.icon), width: 20, height: 20 }) })));
        }),
        React.createElement(ShowIf, { condition: changeable },
            React.createElement(BaseContainer, { style: { padding: '12px 0', borderRadius: '0 0 16px 16px' } },
                React.createElement(Button, { onClick: console.log, label: t('steps.change-terms'), className: 'link' })))));
};
export default BorrowingConditions2;
