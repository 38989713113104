export var Api;
(function (Api) {
    Api["LOAD_PROFILE"] = "LOAD_PROFILE";
    Api["LOAD_APPLICATIONS"] = "LOAD_APPLICATIONS";
    Api["LOAD_DOCUMENT"] = "LOAD_DOCUMENT";
    Api["LOAD_TOTP_VERIFY"] = "LOAD_TOTP_VERIFY";
    Api["POST_APPLICATIONS_NEW"] = "POST_APPLICATIONS_NEW";
    Api["POST_APPLICATIONS_VERIFY"] = "POST_APPLICATIONS_VERIFY";
    Api["POST_BIOMETRY_RESULT"] = "POST_BIOMETRY_RESULT";
    Api["POST_DDOCS_SEND_OTP"] = "POST_DDOCS_SEND_OTP";
    Api["POST_DDOCS_CHECK_OTP"] = "POST_DDOCS_CHECK_OTP";
    Api["POST_FILL_SPOUSE_DATA"] = "POST_FILL_SPOUSE_DATA";
    Api["POST_CHECK_MARRIAGE"] = "POST_CHECK_MARRIAGE";
    Api["POST_ENOTARY_CREATE"] = "POST_ENOTARY_CREATE";
    Api["POST_CONFIRM_TERMS_OF_DEAL"] = "POST_CONFIRM_TERMS_OF_DEAL";
    Api["POST_CONFIRM_REAL_ESTATE"] = "POST_CONFIRM_REAL_ESTATE";
    Api["POST_PAY_COMMISSION"] = "POST_PAY_COMMISSION";
    Api["POST_SET_NOTARY"] = "POST_SET_NOTARY";
    Api["POST_UPLOAD"] = "POST_UPLOAD";
    Api["POST_APPLICATION_REJECT"] = "POST_APPLICATION_REJECT";
    Api["POST_SIGN_DKP"] = "POST_SIGN_DKP";
    Api["POST_LIVENESS_FORENSICS"] = "POST_LIVENESS_FORENSICS";
    Api["LOAD_APPLICATION_BY_UUID"] = "LOAD_APPLICATION_BY_UUID";
})(Api || (Api = {}));
export var RegistrarApi;
(function (RegistrarApi) {
    RegistrarApi["LOAD_APPLICATIONS"] = "LOAD_APPLICATIONS";
    RegistrarApi["LOAD_APPLICATIONS2"] = "LOAD_APPLICATIONS2";
    RegistrarApi["LOAD_HOLIDAYS"] = "LOAD_HOLIDAYS";
    RegistrarApi["LOAD_NOTARY_LIST"] = "LOAD_NOTARY_LIST";
    RegistrarApi["LOAD_STATUSES"] = "LOAD_STATUSES";
    RegistrarApi["LOAD_APPLICATION_BY_UUID"] = "LOAD_APPLICATION_BY_UUID";
    RegistrarApi["LOAD_DKP_BODY"] = "LOAD_DKP_BODY";
    RegistrarApi["LOAD_GET_BALANCE"] = "LOAD_GET_BALANCE";
    RegistrarApi["POST_CONFIRM"] = "POST_CONFIRM";
    RegistrarApi["POST_WAITING_ESCROW"] = "POST_WAITING_ESCROW";
    RegistrarApi["PATCH_CHANGE_ACTIVE"] = "PATCH_CHANGE_ACTIVE";
    RegistrarApi["PUT_CONFIRM_SCHEDULES"] = "PUT_CONFIRM_SCHEDULES";
    RegistrarApi["POST_UPLOAD_DOCUMENT"] = "POST_UPLOAD_DOCUMENT";
    RegistrarApi["POST_VIDEO_CALL"] = "POST_VIDEO_CALL";
    RegistrarApi["POST_NEW_MEET_DATE"] = "POST_NEW_MEET_DATE";
    RegistrarApi["PUT_SUBMIT_REQUEST"] = "PUT_SUBMIT_REQUEST";
})(RegistrarApi || (RegistrarApi = {}));
