export var tabsNav = [
    { title: "flow.profile.profile", tabType: "RADIO_TAB", tabsPanel: 'PROFILE' },
    { title: "flow.profile.app_list", tabType: "RADIO_TAB", tabsPanel: 'APP_LIST' },
];
export var modalFooterData = {
    'DECLINE': [
        { label: "Отправить на доработку", type: "ACCEPT" },
        { label: "Отмена", type: "DECLINE" },
    ],
    'CONFIRMATION': [
        { label: "Да. подтвердить", type: "ACCEPT" },
        { label: "Нет, отмена", type: "DECLINE" },
    ],
    'CONFIRMATION_REJECT': [
        { label: "Да. подтвердить", type: "ACCEPT" },
        { label: "Нет, отмена", type: "DECLINE" },
    ],
    'REASSIGNMENT': [
        { label: "Да. переназначить", type: "ACCEPT" },
        { label: "Нет, отмена", type: "DECLINE" },
    ],
};
