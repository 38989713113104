var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import './index.scss';
import SubTitle from "@/components/ui/sub-title";
import { Button, ShowList } from "@/components";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import { downloadFileHelper } from "@/helpers/downloadFileHelper";
import { useQuery } from "@/saga-query/query-hooks/useQuery";
import { Api } from "@/types/api";
import configs from "@/saga-query/constants";
var Download = require('public/chunks/steps/download.svg');
var Document = require('public/chunks/steps/document.svg');
export var DocumentCard = function (_a) {
    var name = _a.name, link = _a.link, api = _a.api, api2 = _a.api2;
    var t = useTranslation().t;
    var _b = useQuery({
        api: '',
        type: Api.LOAD_DOCUMENT,
        immediately: false,
        showErrorMessage: true,
        onSuccess: function (data) {
            console.log('DOCDATA', data);
            // const file = window.URL.createObjectURL(data)
            // window.open(file, '_blank')
            // downloadFileHelper(link, name, {onNewWindow: true})
        }
    }), isLoading = _b.isLoading, refetchWithParams = _b.refetchWithParams;
    var onDownload = function () {
        if (link)
            downloadFileHelper(link, name, { onNewWindow: true });
        if (api)
            refetchWithParams({}, { type: [Api.LOAD_DOCUMENT, api], api: api });
        if (api2)
            downloadFileHelper("".concat(configs.SERVER).concat(api2), name, { onNewWindow: true });
    };
    return (React.createElement("div", { className: 'document-block__document-card' },
        React.createElement(SVG, { src: Document, width: 20, height: 20 }),
        React.createElement(SubTitle, { text: t(name), style: { margin: '0 0 0 8px', fontSize: '14px', flex: '1' } }),
        React.createElement(Button, { onClick: onDownload, label: '', svg_left: Download, className: 'icon', disabled: isLoading, loading: isLoading })));
};
var DocumentBlock = function (_a) {
    var title = _a.title, docs = _a.docs;
    var t = useTranslation().t;
    return (React.createElement("div", { className: 'document-block__wrapper' },
        React.createElement(SubTitle, { extraClass: "sub-title_title", text: t(title), style: { margin: '0' } }),
        React.createElement(ShowList, { list: docs }, function (doc, i) {
            return React.createElement(DocumentCard, __assign({ key: i }, doc));
        })));
};
export default DocumentBlock;
