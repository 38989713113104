var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo, useState } from "react";
import "./styles.scss";
import SVG from "react-inlinesvg";
import { ProfileMenu, SelectLang, ShowIf, ShowSwitch } from "@/components";
import { Link } from "react-router-dom";
import { getThemeClassName } from "@/helpers/getThemeClassName";
import { useAuthorization } from "@/saga-query/query-hooks/useAuthorization";
import DarkModeToggleButton from "@/components/ui/dark-mode-toggle-button";
import { useTranslation } from "react-i18next";
import { BurgerMenu } from "@/components/ui/burger-menu/burger-menu";
import { stepsDataHeader } from "@/options/sidebar";
import { getSideInnerByType } from "@/components/chunks/steps-sidebar";
var Logo = require("public/logo.svg");
var DarkLogo = require("public/logo-dark-theme.svg");
// const BurgerMenu = require("public/menu-burger.svg");
var Header = function (_a) {
    var theme = _a.theme, changeTheme = _a.changeTheme, registrar_choice = _a.registrar_choice, _b = _a.step, step = _b === void 0 ? null : _b;
    var _c = useAuthorization(), isAuth = _c.isAuth, onLogout = _c.onLogout;
    var t = useTranslation().t;
    var _d = useState(''), menu = _d[0], setMenu = _d[1];
    var innerDataMap = useMemo(function () { return stepsDataHeader
        .filter(function (step) { var _a, _b; return ((_a = step.props) === null || _a === void 0 ? void 0 : _a.filterType) ? ((_b = step.props) === null || _b === void 0 ? void 0 : _b.filterType) === (registrar_choice || 'REGISTRAR') : true; })
        .reduce(function (acc, cur) {
        var current = cur;
        var convertedStep = parseInt(step);
        var currentStep = parseInt(cur.props.step);
        if (convertedStep == currentStep) {
            current = Object.assign({}, cur, { props: __assign(__assign({}, cur.props), { status: 'IN_PROGRESS' }) });
            return acc.concat(current);
        }
        return acc;
    }, [])
        .map(getSideInnerByType); }, [theme, step, registrar_choice]);
    return React.createElement(React.Fragment, null,
        React.createElement("header", { className: "header__wrapper indent header_".concat(getThemeClassName(theme)) },
            React.createElement("div", { className: "header" },
                React.createElement("div", { className: "header__left" },
                    React.createElement("div", { className: "header__logo" },
                        React.createElement(Link, { to: "/" },
                            React.createElement(SVG, { src: theme === "dark-theme" ? DarkLogo : Logo, width: 110 }))),
                    React.createElement(BurgerMenu, { extraClass: "header__burger", onClick: setMenu })),
                React.createElement(ShowIf, { condition: !!step },
                    React.createElement("div", { className: "header__step" }, innerDataMap)),
                React.createElement("div", { className: "header__right" },
                    React.createElement(DarkModeToggleButton, { theme: theme, changeTheme: changeTheme }),
                    React.createElement(SelectLang, { theme: theme }),
                    React.createElement(ShowSwitch, { conditions: [isAuth] },
                        React.createElement(ProfileMenu, { onLogout: onLogout, imageLink: null }),
                        React.createElement(Link, { className: "green", to: '/login' },
                            React.createElement("b", null, t("steps.come_in"))))))),
        React.createElement("div", { id: "burger-container", className: "header__burger-wrapper ".concat(menu) },
            React.createElement("div", { className: "header__burger-overlay" },
                React.createElement("div", { className: "header__burger-content" },
                    React.createElement(DarkModeToggleButton, { theme: theme, changeTheme: changeTheme }),
                    React.createElement(SelectLang, { theme: theme })))));
};
export default Header;
