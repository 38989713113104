var BAG = require('public/chunks/steps/bag.svg');
var CALENDAR = require('public/chunks/steps/calendar.svg');
var CLOCK = require('public/chunks/steps/clock.svg');
var MONEY = require('public/chunks/steps/money.svg');
var PAYMENT = require('public/chunks/steps/payment.svg');
var PERCENT = require('public/chunks/steps/percent.svg');
var HOME = require('public/chunks/steps/home.svg');
export var getConditionIcon = function (type) {
    var icons = {
        BAG: BAG,
        HOME: HOME,
        CALENDAR: CALENDAR,
        CLOCK: CLOCK,
        MONEY: MONEY,
        PAYMENT: PAYMENT,
        PERCENT: PERCENT,
    };
    return icons[type];
};
