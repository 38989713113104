import { getSexFromIIN } from "@/helpers/getSexFromIin";
export var texts_client_spouse = function (iin) {
    return ({
        title: ('flow.dear'),
        body: [
            (getSexFromIIN(iin) === "FEMALE"
                ? ('flow.step1.client_spouse_title2')
                : ('flow.step1.client_spouse_title')),
            ('flow.step1.if_agree')
        ],
        subtitle: ('flow.step1.proc')
    });
};
export var texts_seller_spouse = function (iin) {
    return ({
        title: ('flow.dear'),
        body: [
            (getSexFromIIN(iin) === "FEMALE"
                ? ('flow.step1.seller_spouse_title2')
                : ('flow.step1.seller_spouse_title')),
            ('flow.step1.if_agree')
        ],
        subtitle: ('flow.step1.proc')
    });
};
export var conditionsHeader = {
    title: 'flow.fill_real_estate.deal_conditions',
    type: 'default'
};
export var conditionsHead = [
    {
        title: 'flow.input.building_price',
        field: 'price',
        type: 'MONEY',
        icon: 'HOME'
    }
];
export var infoData = [
    { title: 'flow.input.address', value: 'address' }
];
export var infoCadastralData = [
    { title: 'flow.input.cadastral', value: 'cadastral_number' }
];
