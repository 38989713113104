var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Record } from 'immutable';
import { createSelector } from 'reselect';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { sagaQuery } from '@/saga-query/saga/query';
import configs from '@/saga-query/constants';
import axios from 'axios';
import { LOGOUT, SET_CREDENTIALS } from "@/saga-query/ducks/auth";
import { START } from "@/saga-query/prefix";
import jwtDecode from "jwt-decode";
/**
 * Constants
 * */
export var moduleName = 'app';
var prefix = "".concat(configs.APP_NAME, "/").concat(moduleName);
export var FETCH = "".concat(prefix, "/FETCH");
export var FETCH_START = "".concat(prefix, "/FETCH_START");
export var FETCH_FAIL = "".concat(prefix, "/FETCH_FAIL");
export var FETCH_REFRESH = "".concat(prefix, "/FETCH_REFRESH");
export var FETCH_SUCCESS = "".concat(prefix, "/FETCH_SUCCESS");
export var FETCH_END = "".concat(prefix, "/FETCH_END");
export var SET_QUERY_VALUE_DATA_BY_ID = "".concat(prefix, "/SET_QUERY_VALUE_DATA_BY_ID");
export var SET_QUERY_VALUE_LOADER_BY_ID = "".concat(prefix, "/SET_QUERY_VALUE_LOADER_BY_ID");
export var SET_QUERY_VALUE_ERROR_BY_ID = "".concat(prefix, "/SET_QUERY_VALUE_ERROR_BY_ID");
export var SET_NOTIFICATION = "".concat(prefix, "/SET_NOTIFICATION");
export var CREATE_QUERY_VALUE_DATA_BY_ID = "".concat(prefix, "/CREATE_QUERY_VALUE_DATA_BY_ID");
export var UPDATE_QUERY_VALUE_DATA_BY_ID = "".concat(prefix, "/UPDATE_QUERY_VALUE_DATA_BY_ID");
export var DELETE_QUERY_VALUE_DATA_BY_ID = "".concat(prefix, "/DELETE_QUERY_VALUE_DATA_BY_ID");
export var CLEAR_QUERY_VALUE_DATA_BY_ID = "".concat(prefix, "/CLEAR_QUERY_VALUE_DATA_BY_ID");
export var AppState = Record({
    queryState: {},
    notification: {
        text: '',
        type: '',
    },
}, moduleName);
// TODO: скорее всего нужна будет оптимизация
export default function reducer(state, action) {
    if (state === void 0) { state = new AppState(); }
    var type = action.type;
    var payload;
    switch (type) {
        case SET_QUERY_VALUE_LOADER_BY_ID:
            return state.withMutations(function (map) {
                var payload = action.payload;
                payload.isLoading !== undefined &&
                    map.setIn(['queryState', payload.id, 'isLoading'], payload.isLoading);
                payload.isLoaded !== undefined &&
                    map.setIn(['queryState', payload.id, 'isLoaded'], payload.isLoaded);
                payload.error !== undefined &&
                    map.setIn(['queryState', payload.id, 'error'], payload.error);
                payload.isError !== undefined &&
                    map.setIn(['queryState', payload.id, 'isError'], payload.isError);
            });
        case SET_QUERY_VALUE_DATA_BY_ID:
            return state.withMutations(function (map) {
                var payload = action.payload;
                map.setIn(['queryState', payload.id, 'data'], payload === null || payload === void 0 ? void 0 : payload.data);
                map.setIn(['queryState', payload.id, 'params'], payload === null || payload === void 0 ? void 0 : payload.params);
                map.setIn(['queryState', payload.id, 'path'], payload === null || payload === void 0 ? void 0 : payload.path);
                map.setIn(['queryState', payload.id, 'baseUrl'], payload === null || payload === void 0 ? void 0 : payload.baseUrl);
                map.setIn(['queryState', payload.id, 'meta'], payload === null || payload === void 0 ? void 0 : payload.meta);
                map.setIn(['queryState', payload.id, 'isLoaded'], true);
            });
        case CREATE_QUERY_VALUE_DATA_BY_ID:
            return state.withMutations(function (map) {
                var payload = action.payload;
                var items = map.getIn(['queryState', payload.id, 'data']);
                //@ts-ignore
                items.push(payload.data);
                map.setIn(['queryState', payload.id, 'data'], items);
                map.setIn(['queryState', payload.id, 'isLoaded'], true);
            });
        case UPDATE_QUERY_VALUE_DATA_BY_ID:
            return state.withMutations(function (map) {
                var payload = action.payload;
                var index = state
                    .getIn(['queryState', payload.id, 'data'])
                    // @ts-ignore
                    .findIndex(function (ch) { var _a; return ch.id === Number((_a = payload.data) === null || _a === void 0 ? void 0 : _a.id); });
                map.setIn(['queryState', payload.id, 'data', index], payload.data);
                map.setIn(['queryState', payload.id, 'isLoaded'], true);
            });
        case DELETE_QUERY_VALUE_DATA_BY_ID:
            return state.withMutations(function (map) {
                var payload = action.payload;
                //@ts-ignore
                var index = state
                    .getIn(['queryState', payload.id, 'data'])
                    // @ts-ignore
                    .findIndex(function (ch) { return ch.id === Number(payload.data); });
                map.deleteIn(['queryState', payload.id, 'data', index]);
                map.setIn(['queryState', payload.id, 'isLoaded'], true);
            });
        case CLEAR_QUERY_VALUE_DATA_BY_ID:
            return state.deleteIn(['queryState', action.payload.id]);
        case SET_QUERY_VALUE_ERROR_BY_ID:
            return state.withMutations(function (map) {
                var payload = action.payload;
                map
                    .setIn(['queryState', payload.id, 'error'], {
                    errorDesc: payload.error.errorDesc,
                    errorCode: payload.error.errorCode,
                })
                    .setIn(['queryState', payload.id, 'isError'], true)
                    .deleteIn(['queryState', payload.id, 'data']);
            });
        case SET_NOTIFICATION:
            payload = action.payload;
            return state.set('notification', {
                type: payload.type,
                text: payload.message,
            });
        default:
            return state;
    }
}
/**
 * Selectors
 * */
export var appSelector = function (state) { return state[moduleName]; };
export var queryStateSelector = function (_) {
    return createSelector(appSelector, function (state) { return state.queryState; });
};
export var queryDataStateByIdSelector = function (state, id) {
    return createSelector(queryStateSelector(state), function (state) {
        return state[id] !== undefined
            ? state[id]
            : {};
    });
};
export var queryDataSelector = function (id) {
    return function (state) {
        return queryDataStateByIdSelector(state, id);
    };
};
export function appQuery(payload) {
    return {
        type: FETCH,
        payload: {
            api: payload.api,
            baseUrl: payload.baseUrl,
            method: payload.method,
            headers: payload.headers,
            type: payload.type,
            data: payload.data,
            meta: payload.meta ? payload.meta : {},
            message: payload.message,
            refreshAfter: payload.refreshAfter,
            onUploadProgress: payload === null || payload === void 0 ? void 0 : payload.onUploadProgress,
            onSuccess: payload === null || payload === void 0 ? void 0 : payload.onSuccess,
            onError: payload === null || payload === void 0 ? void 0 : payload.onError,
        },
    };
}
export function appMutation(payload) {
    return {
        type: FETCH,
        payload: {
            api: payload.api,
            baseUrl: payload.baseUrl,
            method: payload.method,
            headers: payload.headers,
            refreshAfter: payload.refreshAfter,
            type: payload.type,
            typeToUpdate: payload === null || payload === void 0 ? void 0 : payload.typeToUpdate,
            data: payload.data,
            meta: payload.meta ? payload.meta : {},
            message: payload.message,
            onError: payload === null || payload === void 0 ? void 0 : payload.onError,
            onSuccess: payload === null || payload === void 0 ? void 0 : payload.onSuccess,
            onUploadProgress: payload === null || payload === void 0 ? void 0 : payload.onUploadProgress,
        },
    };
}
export function appQueryClear(type) {
    return {
        type: CLEAR_QUERY_VALUE_DATA_BY_ID,
        payload: {
            id: type,
        },
    };
}
/**
 *Sagas
 **/
export function fetchSaga(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, call(sagaQuery, {
                    type: payload.type,
                    baseUrl: payload.baseUrl,
                    typeToUpdate: payload === null || payload === void 0 ? void 0 : payload.typeToUpdate,
                    method: payload.method,
                    headers: payload.headers,
                    callAPI: payload.api,
                    refreshAfter: payload.refreshAfter,
                    payload: payload.data || {},
                    meta: payload.meta,
                    message: payload === null || payload === void 0 ? void 0 : payload.message,
                    onUploadProgress: payload === null || payload === void 0 ? void 0 : payload.onUploadProgress,
                    onError: payload === null || payload === void 0 ? void 0 : payload.onError,
                    onSuccess: payload === null || payload === void 0 ? void 0 : payload.onSuccess,
                })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function fetchStartSaga(_a) {
    var data;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!payload) return [3 /*break*/, 2];
                data = {
                    id: payload.action,
                    isLoading: true,
                    isLoaded: false,
                    isError: false,
                    error: {},
                    data: {},
                };
                return [4 /*yield*/, put({ type: SET_QUERY_VALUE_LOADER_BY_ID, payload: data })];
            case 1:
                _b.sent();
                _b.label = 2;
            case 2: return [2 /*return*/];
        }
    });
}
export function fetchSuccessSaga(_a) {
    var data, notification, toUpdateData;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!((payload === null || payload === void 0 ? void 0 : payload.response) !== undefined)) return [3 /*break*/, 7];
                data = {
                    id: payload.action,
                    params: payload.data,
                    meta: payload.meta,
                    path: payload.path,
                    baseUrl: payload.baseUrl,
                    data: payload.response,
                };
                notification = {
                    type: 'success',
                    message: ((_b = payload === null || payload === void 0 ? void 0 : payload.success) === null || _b === void 0 ? void 0 : _b.successDesc) || '',
                };
                return [4 /*yield*/, put({ type: SET_NOTIFICATION, payload: notification })];
            case 1:
                _c.sent();
                if (!(payload === null || payload === void 0 ? void 0 : payload.typeToUpdate)) return [3 /*break*/, 5];
                return [4 /*yield*/, select(
                    // @ts-ignore
                    function (state) { return appSelector(state).queryState[payload.typeToUpdate]; })];
            case 2:
                toUpdateData = _c.sent();
                return [4 /*yield*/, put({ type: SET_QUERY_VALUE_DATA_BY_ID, payload: { id: data.id } })];
            case 3:
                _c.sent();
                return [4 /*yield*/, call(sagaQuery, {
                        type: payload.typeToUpdate,
                        method: 'GET',
                        baseUrl: toUpdateData === null || toUpdateData === void 0 ? void 0 : toUpdateData.baseUrl,
                        callAPI: toUpdateData === null || toUpdateData === void 0 ? void 0 : toUpdateData.path,
                        payload: toUpdateData === null || toUpdateData === void 0 ? void 0 : toUpdateData.params,
                        meta: toUpdateData === null || toUpdateData === void 0 ? void 0 : toUpdateData.meta,
                    })];
            case 4:
                _c.sent();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({ type: SET_QUERY_VALUE_DATA_BY_ID, payload: data })];
            case 6:
                _c.sent();
                _c.label = 7;
            case 7: return [2 /*return*/];
        }
    });
}
export function fetchFailSaga(_a) {
    var notification, data;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!((payload === null || payload === void 0 ? void 0 : payload.error) !== undefined)) return [3 /*break*/, 3];
                notification = {
                    type: 'error',
                    message: ((_b = payload === null || payload === void 0 ? void 0 : payload.error) === null || _b === void 0 ? void 0 : _b.errorDesc) || '',
                };
                return [4 /*yield*/, put({ type: SET_NOTIFICATION, payload: notification })];
            case 1:
                _c.sent();
                data = {
                    id: payload.action,
                    error: payload.error,
                };
                return [4 /*yield*/, put({ type: SET_QUERY_VALUE_ERROR_BY_ID, payload: data })];
            case 2:
                _c.sent();
                _c.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}
export function fetchRefreshSaga(_a) {
    var access, refresh, res, e_1;
    var _b, _c, _d, _e, _f, _g;
    var payload = _a.payload;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0: return [4 /*yield*/, select(function (state) { return state.auth.access; })];
            case 1:
                access = _h.sent();
                refresh = window.localStorage.getItem('refresh');
                _h.label = 2;
            case 2:
                _h.trys.push([2, 12, , 16]);
                if (!refresh) return [3 /*break*/, 7];
                return [4 /*yield*/, call(axios.post, configs.SERVER + '/auth/refresh/', { refresh: refresh })];
            case 3:
                res = _h.sent();
                return [4 /*yield*/, put({
                        type: SET_CREDENTIALS,
                        payload: {
                            access: res.data.access,
                            user: jwtDecode(res.data.access),
                            isRegistrar: ((_b = jwtDecode(res.data.access)) === null || _b === void 0 ? void 0 : _b.user_role) === "REGISTRAR",
                            isNotary: ((_c = jwtDecode(res.data.access)) === null || _c === void 0 ? void 0 : _c.user_role) === "NOTARY",
                            has_totp: (_d = jwtDecode(res.data.access)) === null || _d === void 0 ? void 0 : _d.has_totp
                        },
                    })];
            case 4:
                _h.sent();
                if (!!((_e = payload.action) === null || _e === void 0 ? void 0 : _e.refreshAfter)) return [3 /*break*/, 6];
                return [4 /*yield*/, call(sagaQuery, payload.action)];
            case 5:
                _h.sent();
                _h.label = 6;
            case 6: return [3 /*break*/, 11];
            case 7:
                if (!((_f = Object.keys(access)) === null || _f === void 0 ? void 0 : _f.length)) return [3 /*break*/, 9];
                return [4 /*yield*/, put({ type: LOGOUT })];
            case 8:
                _h.sent();
                _h.label = 9;
            case 9: return [4 /*yield*/, put({
                    type: SET_NOTIFICATION + START,
                    payload: {
                        type: 'ERROR',
                        message: 'Токен истек',
                    },
                })];
            case 10:
                _h.sent();
                _h.label = 11;
            case 11: return [3 /*break*/, 16];
            case 12:
                e_1 = _h.sent();
                if (!((_g = Object.keys(access)) === null || _g === void 0 ? void 0 : _g.length)) return [3 /*break*/, 15];
                return [4 /*yield*/, put({ type: LOGOUT })];
            case 13:
                _h.sent();
                return [4 /*yield*/, put({
                        type: SET_NOTIFICATION + START,
                        payload: {
                            type: 'ERROR',
                            message: 'Токен истек',
                        },
                    })];
            case 14:
                _h.sent();
                _h.label = 15;
            case 15: return [3 /*break*/, 16];
            case 16: return [2 /*return*/];
        }
    });
}
export function fetchEndSaga(_a) {
    var data;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                data = {
                    id: payload.action,
                    isLoading: false,
                };
                return [4 /*yield*/, put({ type: SET_QUERY_VALUE_LOADER_BY_ID, payload: data })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all([takeEvery(FETCH, fetchSaga)])];
            case 1:
                _a.sent();
                return [4 /*yield*/, all([takeEvery(FETCH_START, fetchStartSaga)])];
            case 2:
                _a.sent();
                return [4 /*yield*/, all([takeEvery(FETCH_SUCCESS, fetchSuccessSaga)])];
            case 3:
                _a.sent();
                return [4 /*yield*/, all([takeEvery(FETCH_FAIL, fetchFailSaga)])];
            case 4:
                _a.sent();
                return [4 /*yield*/, all([takeLatest(FETCH_REFRESH, fetchRefreshSaga)])];
            case 5:
                _a.sent();
                return [4 /*yield*/, all([takeEvery(FETCH_END, fetchEndSaga)])];
            case 6:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
