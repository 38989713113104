import React, { useContext } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import SVG from "react-inlinesvg";
import { getThemeClassName } from "@/helpers/getThemeClassName";
import { fillSvg } from "@/helpers/fillSvg";
import { ThemeContext } from "@/containers/theme-container/";
var ArrowLeft = require("public/chunks/breadcrumbs/arrow-left.svg");
var Breadcrumbs = function (_a) {
    var title = _a.title;
    var navigate = useNavigate();
    var theme = useContext(ThemeContext).theme;
    return (React.createElement("div", { className: "ui-breadcrumbs ui-breadcrumbs_".concat(getThemeClassName(theme)) },
        React.createElement("div", { className: "d-f", onClick: function () { return navigate(-1); } },
            React.createElement(SVG, { src: ArrowLeft, preProcessor: fillSvg(theme) }),
            React.createElement("span", null, title))));
};
export default Breadcrumbs;
