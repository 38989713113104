var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
var ShowIf = function (_a) {
    var condition = _a.condition, children = _a.children, wrapElem = _a.wrapElem;
    var _b = wrapElem || {}, elem = _b.elem, className = _b.className, _c = _b.props, props = _c === void 0 ? {} : _c;
    var insertChildren = Array.isArray(children) ? children : [children];
    var element = elem ?
        React.cloneElement(elem, __assign({ className: className || '' }, props), insertChildren) :
        React.cloneElement(React.createElement(React.Fragment, null), {}, insertChildren);
    return condition ? element : null;
};
export default ShowIf;
