import React from "react";
import './styles.scss';
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
var Success = require("public/circle-icon.svg");
var SuccessResetPass = function () {
    var t = useTranslation().t;
    return React.createElement("div", { className: 'success-reset-pass' },
        React.createElement(SVG, { src: Success, width: 96, height: 96 }),
        React.createElement("h4", { style: { maxWidth: "335px", textAlign: "center" } }, t('flow.profile.pass_added')));
};
export default SuccessResetPass;
