import React from "react";
import './index.scss';
import SVG from "react-inlinesvg";
import SubTitle from "@/components/ui/sub-title";
import Text from "@/components/ui/text/Text";
import { ShowGroup, ShowSwitch } from "@/components";
var Loading = require('public/chunks/steps/loading-bar.svg');
var Check = require('public/chunks/steps/check.svg');
var SellerStatus = function (_a) {
    var title = _a.title, message = _a.message, _b = _a.type, type = _b === void 0 ? 'CHECK' : _b, subtitle = _a.subtitle;
    return (React.createElement("div", { className: 'seller-status__wrapper m-t-20' },
        React.createElement(ShowSwitch, { conditions: [type === 'LOADING', type === 'CHECK', type === 'ERROR'] },
            React.createElement(SVG, { src: Loading, className: 'notary-checking__spinner', width: 18, height: 18 }),
            React.createElement(SVG, { src: Check, width: 20, height: 20 }),
            React.createElement(SVG, { src: Check, width: 20, height: 20, fill: 'red' })),
        React.createElement("div", { className: 'seller-status__container' },
            React.createElement(ShowGroup, { conditions: [!!title, !!message, !!subtitle] },
                React.createElement(SubTitle, { text: title, style: { margin: '0' } }),
                React.createElement(Text, { text: message }),
                React.createElement(Text, { text: subtitle, style: { margin: '0' } })))));
};
export default SellerStatus;
