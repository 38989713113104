import React from 'react';
import './index.scss';
import { getThemeClassName } from "@/helpers/getThemeClassName";
var Text = function (_a) {
    var text = _a.text, _b = _a.size, size = _b === void 0 ? 'md' : _b, theme = _a.theme, _c = _a.extraClass, extraClass = _c === void 0 ? "" : _c, _d = _a.style, style = _d === void 0 ? {} : _d;
    if (Array.isArray(text)) {
        return React.createElement(React.Fragment, null, text.map(function (t) { return (React.createElement("p", { style: style, className: "text text_size text_".concat(getThemeClassName(theme), " ").concat(extraClass) }, t)); }));
    }
    return React.createElement("p", { style: style, className: "text text_size text_".concat(getThemeClassName(theme), " ").concat(extraClass) }, text);
};
export default Text;
