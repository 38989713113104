var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { AppList, Profile } from "@/components";
import { getTemplate } from "@/helpers/getTemplate";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
export var profileMenuList = [
    { title: 'flow.profile.profile', type: 'NAV_LINK', link: '/profile' },
    { title: 'flow.profile.registrar', type: 'NAV_LINK', link: '/cabinet/deals', filterType: 'REGISTRAR' },
    { title: 'flow.profile.notary', type: 'NAV_LINK', link: '/cabinet/notary-deals', filterType: 'NOTARY' },
    { title: 'flow.profile.change_pass', type: 'NAV_LINK', link: '/change-pass' },
    { title: 'flow.profile.exit', type: 'BUTTON', funcName: 'onLogout', className: 'exit' },
];
export var getProfileMenuTemplate = function (props) { return function (item, key) {
    var _a;
    var t = useTranslation().t;
    var template = (_a = {},
        _a["NAV_LINK"] = React.createElement(NavLink, { to: item.link }, function (_a) {
            var isActive = _a.isActive;
            return React.createElement("div", { className: "profile-menu__item ".concat(item.className || '', " ").concat(isActive && "active") },
                React.createElement("span", null, t(item.title)));
        }),
        _a["BUTTON"] = React.createElement("div", { onClick: props === null || props === void 0 ? void 0 : props[item.funcName], className: "profile-menu__item " + item.className },
            React.createElement("span", null, t(item.title))),
        _a);
    return getTemplate(template, item.type, { key: key });
}; };
export var getProfilePanelsTemplate = function (props) { return function (tab, key) {
    var _a;
    var _b, _c, _d, _e, _f, _g;
    var template = (_a = {},
        _a["PROFILE"] = React.createElement(Profile, __assign({ pageTitle: (_b = props === null || props === void 0 ? void 0 : props.options) === null || _b === void 0 ? void 0 : _b.pageTitle, data: (_c = props === null || props === void 0 ? void 0 : props.options) === null || _c === void 0 ? void 0 : _c.profile }, ((props === null || props === void 0 ? void 0 : props.options) || {}), { isLoading: props.isLoading })),
        _a["APP_LIST"] = React.createElement(AppList, { pageTitle: (_d = props === null || props === void 0 ? void 0 : props.options) === null || _d === void 0 ? void 0 : _d.pageTitle, onClick: (_e = props === null || props === void 0 ? void 0 : props.options) === null || _e === void 0 ? void 0 : _e.onApplicationClickHandler, list: (_f = props === null || props === void 0 ? void 0 : props.options) === null || _f === void 0 ? void 0 : _f.appListData, isLoading: (_g = props === null || props === void 0 ? void 0 : props.isLoading) === null || _g === void 0 ? void 0 : _g.appList }),
        _a);
    return getTemplate(template, tab.tabsPanel, { key: key });
}; };
