var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { deepmerge } from "deepmerge-ts";
function composeConsumers(consumers) {
    return /** @class */ (function (_super) {
        __extends(Composed, _super);
        function Composed() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.composeHelper = function (restConsumers, contexts) {
                if (contexts === void 0) { contexts = []; }
                if (!(restConsumers === null || restConsumers === void 0 ? void 0 : restConsumers.length)) {
                    return _this.props.children(contexts.reduce(function (acc, cur) { return deepmerge(acc, cur); }, {}));
                }
                var Consumer = restConsumers[0], rest = restConsumers.slice(1);
                return (React.createElement(Consumer, null, function (context) { return _this.composeHelper(rest, __spreadArray(__spreadArray([], contexts, true), [context], false)); }));
            };
            _this.render = function () { return _this.composeHelper(consumers); };
            return _this;
        }
        return Composed;
    }(React.PureComponent));
}
export default composeConsumers;
