// Head
export var EHeadTableType;
(function (EHeadTableType) {
    EHeadTableType["SORT"] = "SORT";
})(EHeadTableType || (EHeadTableType = {}));
// Column
export var EColumnTableType;
(function (EColumnTableType) {
    EColumnTableType["INDEX"] = "INDEX";
    EColumnTableType["MODIFY"] = "MODIFY";
    EColumnTableType["BUTTON"] = "BUTTON";
    EColumnTableType["ACTIONS"] = "ACTIONS";
    EColumnTableType["STATUS"] = "STATUS";
    EColumnTableType["PRICE"] = "PRICE";
    EColumnTableType["DATE"] = "DATE";
    EColumnTableType["BOOLEAN"] = "BOOLEAN";
    EColumnTableType["CONCAT"] = "CONCAT";
    EColumnTableType["CONDITIONS"] = "CONDITIONS";
})(EColumnTableType || (EColumnTableType = {}));
