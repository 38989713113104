var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import { call, cancelled, put, select } from 'redux-saga/effects';
import { FAIL, START, SUCCESS } from '@/saga-query/prefix';
import { tokenKeySelector } from '@/saga-query/ducks/auth';
import { FETCH_END, FETCH_FAIL, FETCH_REFRESH, FETCH_START, FETCH_SUCCESS, } from '@/saga-query/ducks/app';
import { SET_NOTIFICATION } from "@/saga-query/ducks/notification";
/**
 *
 * @param action: IAction
 * type - используется в качестве тага
 * callAPI - урл
 * method - http methods
 * meta - дополнительная информация
 */
export var sagaQuery = function (action) {
    var baseUrl, callAPI, method, type, meta, headers, refreshAfter, typeToUpdate, _a, onUploadProgress, _b, onError, _c, onSuccess, cancelTokenSource, headers_1, access, payload, response, error_1;
    var _d, _e, _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                baseUrl = action.baseUrl, callAPI = action.callAPI, method = action.method, type = action.type, meta = action.meta, headers = action.headers, refreshAfter = action.refreshAfter, typeToUpdate = action.typeToUpdate, _a = action.onUploadProgress, onUploadProgress = _a === void 0 ? function () {
                } : _a, _b = action.onError, onError = _b === void 0 ? function (error) {
                } : _b, _c = action.onSuccess, onSuccess = _c === void 0 ? function (data) {
                } : _c;
                if (!callAPI) {
                    return [2 /*return*/];
                }
                return [4 /*yield*/, put({
                        type: FETCH_START,
                        payload: {
                            action: type,
                        },
                    })];
            case 1:
                _g.sent();
                return [4 /*yield*/, put({ type: type + START })];
            case 2:
                _g.sent();
                cancelTokenSource = (meta === null || meta === void 0 ? void 0 : meta.cancelTokenSource)
                    ? meta.cancelTokenSource
                    : axios.CancelToken.source();
                _g.label = 3;
            case 3:
                _g.trys.push([3, 12, 22, 25]);
                headers_1 = prepareHeaders(action);
                if (!(meta === null || meta === void 0 ? void 0 : meta.authorization)) return [3 /*break*/, 5];
                return [4 /*yield*/, select(tokenKeySelector)];
            case 4:
                access = _g.sent();
                if ((meta === null || meta === void 0 ? void 0 : meta.authorization) && !access)
                    return [2 /*return*/];
                headers_1['Authorization'] = 'Bearer ' + access;
                _g.label = 5;
            case 5:
                payload = preparePayload(headers_1, action);
                return [4 /*yield*/, call(asyncApiReq, method, baseUrl + callAPI, headers_1, payload, cancelTokenSource, onUploadProgress)];
            case 6:
                response = _g.sent();
                return [4 /*yield*/, put({ type: type + SUCCESS, payload: response })];
            case 7:
                _g.sent();
                return [4 /*yield*/, put({
                        type: FETCH_SUCCESS,
                        payload: {
                            action: type,
                            data: payload,
                            method: method,
                            success: { successDesc: (_d = action === null || action === void 0 ? void 0 : action.message) === null || _d === void 0 ? void 0 : _d.successDesc },
                            response: response,
                            typeToUpdate: typeToUpdate,
                            path: callAPI,
                            params: payload,
                            meta: meta,
                            baseUrl: baseUrl
                        },
                    })];
            case 8:
                _g.sent();
                return [4 /*yield*/, call(onSuccess, response)];
            case 9:
                _g.sent();
                if (!refreshAfter) return [3 /*break*/, 11];
                return [4 /*yield*/, put({
                        type: FETCH_REFRESH,
                        payload: { action: action },
                    })];
            case 10:
                _g.sent();
                _g.label = 11;
            case 11: return [3 /*break*/, 25];
            case 12:
                error_1 = _g.sent();
                return [4 /*yield*/, put({ type: type + FAIL, payload: error_1 })];
            case 13:
                _g.sent();
                return [4 /*yield*/, onError && call(onError, error_1)];
            case 14:
                _g.sent();
                return [4 /*yield*/, put({
                        type: FETCH_FAIL,
                        payload: {
                            action: type,
                            error: {
                                errorDesc: ((_e = action === null || action === void 0 ? void 0 : action.message) === null || _e === void 0 ? void 0 : _e.errorDesc) || (error_1 === null || error_1 === void 0 ? void 0 : error_1.errorDesc) || (error_1 === null || error_1 === void 0 ? void 0 : error_1.message),
                                errorCode: error_1 === null || error_1 === void 0 ? void 0 : error_1.errorCode,
                            },
                        },
                    })];
            case 15:
                _g.sent();
                if (!["token_not_valid"].includes((_f = error_1 === null || error_1 === void 0 ? void 0 : error_1.data) === null || _f === void 0 ? void 0 : _f.code)) return [3 /*break*/, 17];
                return [4 /*yield*/, put({
                        type: FETCH_REFRESH,
                        payload: { action: action },
                    })];
            case 16:
                _g.sent();
                _g.label = 17;
            case 17:
                if (!["ERR_NETWORK"].includes(error_1 === null || error_1 === void 0 ? void 0 : error_1.code)) return [3 /*break*/, 19];
                return [4 /*yield*/, put({
                        type: SET_NOTIFICATION + START,
                        payload: {
                            id: new Date().getTime(),
                            type: "ERROR",
                            message: 'Oшибка соединения'
                        }
                    })];
            case 18:
                _g.sent();
                _g.label = 19;
            case 19:
                if (![403].includes(error_1 === null || error_1 === void 0 ? void 0 : error_1.status)) return [3 /*break*/, 21];
                return [4 /*yield*/, put({
                        type: SET_NOTIFICATION + START,
                        payload: {
                            id: new Date().getTime(),
                            type: "ERROR",
                            title: 'Ошибка доступа',
                            message: 'У вас нет прав для выполнения данного действия'
                        }
                    })];
            case 20:
                _g.sent();
                _g.label = 21;
            case 21: return [3 /*break*/, 25];
            case 22: return [4 /*yield*/, cancelled()];
            case 23:
                if (_g.sent()) {
                    cancelTokenSource.cancel();
                }
                return [4 /*yield*/, put({
                        type: FETCH_END,
                        payload: {
                            action: type,
                        },
                    })];
            case 24:
                _g.sent();
                return [7 /*endfinally*/];
            case 25: return [2 /*return*/];
        }
    });
};
function prepareHeaders(action) {
    var headers = action.headers;
    headers = headers
        ? headers
        : {
            'Content-Type': 'application/json',
        };
    return headers;
}
function preparePayload(headers, action) {
    var method = action.method, payload = action.payload;
    if (method !== 'GET') {
        if (headers['Content-Type'] === 'multipart/form-data;') {
            delete headers['Content-Type'];
            var params_1 = new FormData();
            Object.keys(payload).forEach(function (key) { return params_1.append(key, payload[key]); });
            return params_1;
        }
        else {
            return payload !== null ? JSON.stringify(payload) : {};
        }
    }
    return payload;
}
function paramsSerializer(params) {
    var search = new URLSearchParams();
    Object.entries(params).forEach(function (_a) {
        var key = _a[0], val = _a[1];
        if (!val)
            return;
        if (typeof val === 'string' && val.includes("&")) {
            val.split("&").forEach(function (v) { return search.append(key, v); });
        }
        else {
            search.append(key, val);
        }
    });
    return search.toString();
}
function asyncApiReq(method, url, headers, data, cancelTokenSource, onUploadProgress) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, axios({
                    method: method,
                    url: url,
                    headers: headers,
                    data: data,
                    onUploadProgress: onUploadProgress,
                    params: method === 'GET' && data,
                    paramsSerializer: method === 'GET' && paramsSerializer,
                    cancelToken: cancelTokenSource.token,
                })
                    .then(function (response) { return response.data; })
                    .catch(function (error) {
                    var _a;
                    if ((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data)
                        throw error.response;
                    else
                        throw error;
                })];
        });
    });
}
