import React from "react";
import './index.scss';
import SubTitle from "@/components/ui/sub-title";
import Text from "@/components/ui/text/Text";
import { ShowIf } from "@/components";
import SVG from "react-inlinesvg";
import { getCircleColorByClassName, getStepCircleClassNameByStatus } from "./helpers";
import { useTranslation } from "react-i18next";
var Check = require('public/chunks/steps/check.svg');
var AsideStepStatus = function (_a) {
    var title = _a.title, step = _a.step, status = _a.status;
    var t = useTranslation().t;
    return (React.createElement("li", { className: 'aside-step-status__el' },
        React.createElement("div", { className: 'aside-step-status__wrapper' },
            React.createElement("span", { className: "aside-step-status__count-step aside-step-status__count-step_".concat(getStepCircleClassNameByStatus(status)) },
                React.createElement(SubTitle, { text: step, style: { margin: '0', color: getCircleColorByClassName(status) } })),
            React.createElement("div", { className: 'aside-step-status__info' },
                React.createElement(Text, { text: "".concat(t('steps.step'), " ").concat(step, "/5"), style: { margin: '0' } }),
                React.createElement(SubTitle, { text: t(title), style: { margin: '0' } }))),
        React.createElement(ShowIf, { condition: status === 'FINISHED' },
            React.createElement(SVG, { src: Check, width: 20, height: 20 }))));
};
export default AsideStepStatus;
