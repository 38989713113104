var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { createContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import configs from '@/saga-query/constants';
import { useQuery } from "@/saga-query/query-hooks/useQuery";
import { useMutation } from "@/saga-query/query-hooks/useMutation";
import { useNotification } from "@/saga-query/query-hooks/useNotification";
import { usePermissions } from "@/saga-query/query-hooks/usePermissions";
import { useAuthorization } from "@/saga-query/query-hooks/useAuthorization";
import { RegistrarApi } from "@/types/api";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { PrintFormsService } from "@/saga-query/api/print-forms";
export var SingleMortgagePageContext = createContext({});
export var SingleMortgagePageContextProvider = function (_a) {
    var _b, _c;
    var children = _a.children, options = _a.options;
    var uuid = useParams().uuid;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var hasAccess = usePermissions().hasAccess;
    var _d = useNotification(), onSuccess = _d.onSuccess, onWarn = _d.onWarn;
    var user = useAuthorization().user;
    var _e = useState(''), text = _e[0], setText = _e[1];
    var _f = useState(''), changeDate = _f[0], setChangeDate = _f[1];
    var _g = useState(''), message = _g[0], setMessage = _g[1];
    var _h = useState(null), selectTime = _h[0], setSelectTime = _h[1];
    var _j = useState({
        type: '',
        isOpen: false,
        title: '',
        description: '',
    }), modalData = _j[0], setModalData = _j[1];
    var _k = useQuery({
        api: "/registrar/applications/".concat(uuid, "/"),
        type: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        forceUpdate: true,
        refetchInterval: 120000,
        showErrorMessage: true
    }), data = _k.data, isLoading = _k.isLoading, isLoaded = _k.isLoaded;
    var _l = useMutation({
        api: "/registrar/applications/".concat(uuid, "/confirm/"),
        method: 'POST',
        type: RegistrarApi.POST_CONFIRM,
        typeToUpdate: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        showErrorMessage: true,
        // onSuccess: () => onSuccess({title: 'Смена статуса', message: 'Статус изменен на: "Подтверждено"'})
    }), onConfirm = _l[0], isLoadingOnApprove = _l[1].isLoading;
    var _m = useMutation({
        api: "/registrar/applications/".concat(uuid, "/dkp-registration/"),
        method: 'POST',
        type: RegistrarApi.POST_CONFIRM,
        typeToUpdate: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        showErrorMessage: true,
        // onSuccess: () => onSuccess({title: 'Смена статуса', message: 'Статус изменен на: "Подтверждено"'})
    }), onDKPRegistration = _m[0], isLoadingOnDkdRegistration = _m[1].isLoading;
    var _o = useMutation({
        api: "/registrar/applications/".concat(uuid, "/sign-dkp/"),
        method: 'POST',
        data: { redirect_url: (_b = window === null || window === void 0 ? void 0 : window.location) === null || _b === void 0 ? void 0 : _b.href },
        type: RegistrarApi.POST_CONFIRM,
        typeToUpdate: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        showErrorMessage: true,
        onSuccess: function (data) {
            var _a;
            if (data === null || data === void 0 ? void 0 : data.url)
                (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.replace(data === null || data === void 0 ? void 0 : data.url);
        }
    }), onSignDKP = _o[0], isLoadingOnSignDkp = _o[1].isLoading;
    var _p = useMutation({
        api: "/registrar/applications/".concat(uuid, "/reject/"),
        method: 'POST',
        data: { redirect_url: (_c = window === null || window === void 0 ? void 0 : window.location) === null || _c === void 0 ? void 0 : _c.href },
        type: RegistrarApi.POST_CONFIRM,
        typeToUpdate: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        showErrorMessage: true,
        onSuccess: function (res) {
            navigate('/cabinet/deals');
        }
    }), onReject = _p[0], isLoadingOnReject = _p[1].isLoading;
    var _q = useMutation({
        api: "/registrar/applications/".concat(uuid, "/new-meet-date/ "),
        method: 'PATCH',
        data: { meet_date: dayjs(changeDate).format('YYYY-MM-DDT') + selectTime },
        type: RegistrarApi.POST_NEW_MEET_DATE,
        typeToUpdate: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        showErrorMessage: true
    }), onNewMeetDate = _q[0], isLoadingOnNewMeetDate = _q[1].isLoading;
    var _r = useMutation({
        api: "/registrar/applications/submit-request/",
        method: 'POST',
        data: { message: message },
        type: RegistrarApi.PUT_SUBMIT_REQUEST,
        typeToUpdate: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        showErrorMessage: true
    }), onSubmitRequest = _r[0], isLoadingOnSubmitRequest = _r[1].isLoading;
    var onTextChange = function (val) { return setText(val); };
    var isDisabledModalFooterByType = function (type) {
        var ACCEPT = false;
        switch (type) {
            case 'DECLINE':
                ACCEPT = !text;
                break;
        }
        return { ACCEPT: ACCEPT };
    };
    var onChangeSchedules = function () {
        setModalData(function (prev) { return (__assign(__assign({}, prev), { isOpen: true, type: 'CHANGE_SCHEDULES', title: t('registrar.date_time') })); });
    };
    var onDownload = function (url) {
        if (!url)
            return;
        var link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    var onDkpDownload = function () { return __awaiter(void 0, void 0, void 0, function () {
        var file, link, data_1, err_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    file = (_a = data === null || data === void 0 ? void 0 : data.docs) === null || _a === void 0 ? void 0 : _a.file;
                    if (!file)
                        return [2 /*return*/];
                    link = document.createElement('a');
                    link.href = "".concat(configs.SERVER.substr(0, configs.SERVER.length - 4)).concat(file);
                    link.setAttribute('target', "_blank");
                    link.click();
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, PrintFormsService.downloadDKP()];
                case 2:
                    data_1 = _b.sent();
                    setModalData(function (prev) { return (__assign(__assign({}, prev), { isOpen: true, type: 'DKP_DOC', title: 'Документ', data: data_1 })); });
                    return [3 /*break*/, 5];
                case 3:
                    err_1 = _b.sent();
                    console.log('err', err_1);
                    return [3 /*break*/, 5];
                case 4: return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var onRejectApplication = function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, onReject()];
    }); }); };
    var onConfirmMeetMessage = function () { var _a; return window.open((_a = data === null || data === void 0 ? void 0 : data.meeting) === null || _a === void 0 ? void 0 : _a.admin_token, '_blank'); };
    var onCheckSignatureModal = function (userType, hash) {
        setModalData(function (prev) { return (__assign(__assign({}, prev), { isOpen: true, type: 'SIGNATURE', title: "".concat(t('steps2.signature'), " ").concat(userType), hash: hash })); });
    };
    var onBeginMeetModal = function (userType, hash) {
        setModalData(function (prev) { return (__assign(__assign({}, prev), { isOpen: true, type: 'BEGIN_MEET', title: t('registrar.begin_meet') })); });
    };
    var onSendMessage = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setModalData(function (prev) { return (__assign(__assign({}, prev), { isOpen: true, type: 'MESSAGE', title: 'Техподдержка' })); });
            return [2 /*return*/];
        });
    }); };
    var isMeetEnded = dayjs(data === null || data === void 0 ? void 0 : data.meet_date).diff(dayjs().add(1, 'minutes'));
    var isMeetBegin = dayjs(data === null || data === void 0 ? void 0 : data.meet_date).diff(dayjs().add(1, 'minutes'));
    return React.createElement(SingleMortgagePageContext.Provider, { value: {
            id: data === null || data === void 0 ? void 0 : data.id,
            isLoading: {
                single: isLoading,
                dkpRegistration: isLoadingOnDkdRegistration,
                dkp: isLoadingOnSignDkp,
            },
            isDisabled: {
                single: isLoading,
            },
            status: data === null || data === void 0 ? void 0 : data.status,
            isMeetEnded: isMeetEnded,
            isMeetBegin: isMeetBegin,
            onConfirmApplication: function () { return onConfirm(); },
            onDkpRegistration: function () { return onDKPRegistration(); },
            onDkpSign: function () { return onSignDKP(); },
            onDkpDownload: onDkpDownload,
            onBeginMeetModal: onBeginMeetModal,
            onChangeSchedules: onChangeSchedules,
            onCheckSignatureModal: onCheckSignatureModal,
            onDownload: onDownload,
            onSendMessage: onSendMessage,
            onRejectApplication: onRejectApplication,
            application: data || {},
            modalParams: {
                modalData: modalData,
                text: text,
                changeDate: changeDate,
                setChangeDate: setChangeDate,
                message: message,
                setMessage: setMessage,
                onTextChange: onTextChange,
                onConfirmMeetMessage: onConfirmMeetMessage,
                onSendMessageHandler: function () { return onSubmitRequest(); },
                onConfirmNewMeetDate: function () { return onNewMeetDate(); },
                selectTime: selectTime,
                setSelectTime: setSelectTime,
                onModalClose: function () { return setModalData(function (prev) { return (__assign(__assign({}, prev), { isOpen: false })); }); },
            }
        } }, children);
};
