import React from "react";
import { EColumnTableType, EHeadTableType } from "@/types/components/table";
import { getTemplate } from "@/helpers/getTemplate";
import SVG from "react-inlinesvg";
import { getStatusInfo } from "@/helpers/getStatusesInfo";
import dayjs from "dayjs";
import { getFormatted } from "@/helpers/getFormatted";
import { Button } from "@/components";
import { getButtonIcon } from "@/helpers/buttonIcons";
var ArrowUp = require('public/ui/table/arrow-up.svg');
var ArrowDown = require('public/ui/table/arrow-down.svg');
var Update = require('public/ui/table/update2.svg');
var Delete = require('public/ui/table/delete.svg');
export var getHeadTemplate = function (column, params) {
    var _a;
    var template = (_a = {},
        _a[EHeadTableType.SORT] = React.createElement("div", { className: 'table__td d-f a-i-c' },
            column.name,
            React.createElement("div", { className: 'table__sort' },
                React.createElement(SVG, { onClick: function () { return params === null || params === void 0 ? void 0 : params.onTableSort('ASC', column); }, src: ArrowUp }),
                React.createElement(SVG, { onClick: function () { return params === null || params === void 0 ? void 0 : params.onTableSort('DESC', column); }, src: ArrowDown }))),
        _a);
    var defaultElem = React.createElement("div", { className: 'table__td d-f a-i-c' }, column.name);
    return getTemplate(template, column.headType, { key: params.key, defaultElem: defaultElem });
};
export var getBodyTemplate = function (row, column, params) {
    var _a;
    var _b, _c, _d, _e;
    var template = (_a = {},
        _a[EColumnTableType.INDEX] = React.createElement("div", { className: 'table__td' }, params.idx + 1),
        _a[EColumnTableType.BUTTON] = React.createElement("div", { className: 'table__td' },
            React.createElement(Button, { className: "table-button ".concat((row === null || row === void 0 ? void 0 : row['action.className']) || ''), onClick: function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    params === null || params === void 0 ? void 0 : params.onButton(row);
                }, label: row[column.field], loading: (_b = params.isLoading) === null || _b === void 0 ? void 0 : _b[column.field], disabled: (_c = params.isDisabled) === null || _c === void 0 ? void 0 : _c[column.field], svg_left: getButtonIcon(row === null || row === void 0 ? void 0 : row['action.icon']) })),
        _a[EColumnTableType.ACTIONS] = React.createElement("div", { className: 'table__td d-f gg-20 a-i-c' }, (_d = ((Array.isArray(row[column.field]) ? row[column.field] : []))) === null || _d === void 0 ? void 0 : _d.map(function (props) {
            var _a;
            return React.createElement(Button, { key: props.label, className: "table-button ".concat(props.className || ''), onClick: function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    props === null || props === void 0 ? void 0 : props.onButton(row);
                }, label: props.label, disabled: (_a = params === null || params === void 0 ? void 0 : params.isDisabled) === null || _a === void 0 ? void 0 : _a[props.type], svg_left: getButtonIcon(row === null || row === void 0 ? void 0 : row['action.icon']) });
        })),
        _a[EColumnTableType.STATUS] = React.createElement("div", { className: 'table__td' },
            React.createElement("div", { className: "table__td ".concat(getStatusInfo(row[column.field]).className) }, params === null || params === void 0 ? void 0 : params.t(getStatusInfo(row[column.field]).text))),
        _a[EColumnTableType.PRICE] = React.createElement("div", { className: 'table__td' },
            React.createElement("div", { className: 'table__td' }, getFormatted(new Intl.NumberFormat('ru-RU').format(Number(row[column.field])), 0)(!!row[column.field]))),
        _a[EColumnTableType.DATE] = React.createElement("div", { className: 'table__td' },
            React.createElement("div", { className: 'table__td' }, getFormatted(dayjs(row[column.field]).format(column.format || 'DD.MM.YYYY HH:mm'))(!!row[column.field]))),
        _a[EColumnTableType.BOOLEAN] = React.createElement("div", { className: 'table__td' },
            React.createElement("div", { className: 'table__td' }, row[column.field] ? 'Да' : 'Нет')),
        _a[EColumnTableType.MODIFY] = React.createElement("div", { className: 'table__td' },
            React.createElement("div", { className: 'table__td d-f' },
                React.createElement("span", { className: "m-r-15" }, row[column.field] || '---'),
                React.createElement("div", { className: "table__buttons" },
                    React.createElement(SVG, { onClick: function () { return params.onUpdate(row); }, src: Update }),
                    React.createElement(SVG, { onClick: function () { return params.onDelete(row.id); }, src: Delete })))),
        _a[EColumnTableType.CONCAT] = React.createElement("div", { className: 'table__td' },
            React.createElement("div", { className: 'table__td' },
                React.createElement("span", { className: "m-r-15" }, ((_e = column.fields) === null || _e === void 0 ? void 0 : _e.reduce(function (acc, f) { return row[f] ? "".concat(acc, " ").concat(row[f]) : acc; }, '')) || '---'))),
        _a[EColumnTableType.CONDITIONS] = (Array.isArray(row === null || row === void 0 ? void 0 : row[column === null || column === void 0 ? void 0 : column.field]) ? row === null || row === void 0 ? void 0 : row[column === null || column === void 0 ? void 0 : column.field] : []).reduce(function (acc, el) {
            if (!acc && (el === null || el === void 0 ? void 0 : el.condition))
                return getBodyTemplate(el, el, el);
            return acc;
        }, null),
        _a);
    var defaultElem = React.createElement("div", { className: 'table__td' }, row[column === null || column === void 0 ? void 0 : column.field] || '---');
    return getTemplate(template, column.columnType, { key: params.key, defaultElem: defaultElem });
};
