import React, { useEffect, useRef } from "react";
import { SubTitle } from "@/components";
import ShowIf from "@/components/other/show-if";
import "./index.scss";
import IMask from "imask";
var MaskInput = function (_a) {
    var _b;
    var placeholder = _a.placeholder, label = _a.label, value = _a.value, onChange = _a.onChange, onFocus = _a.onFocus, disabled = _a.disabled, className = _a.className, inputRef = _a.inputRef, _c = _a.max, max = _c === void 0 ? 9999999999 : _c, _d = _a.min, min = _d === void 0 ? 0 : _d, mask = _a.mask, _e = _a.money, money = _e === void 0 ? true : _e, _f = _a.type, type = _f === void 0 ? "text" : _f;
    var ref = useRef(null);
    var maskRef = useRef(null);
    var handleChange = function (e) {
        setTimeout(function () { return onChange(maskRef.current.unmaskedValue); }, 50);
    };
    useEffect(function () {
        if (ref.current && mask) {
            maskRef.current = IMask(ref.current, {
                mask: mask,
                blocks: {
                    num: {
                        mask: Number,
                        thousandsSeparator: ' ',
                        min: min,
                        max: max,
                    }
                }
            });
            ref.current.addEventListener('input', handleChange);
        }
    }, [ref.current]);
    return (React.createElement("div", { className: "text-input__container ".concat(className), onFocus: onFocus },
        React.createElement(ShowIf, { condition: !!label },
            React.createElement(SubTitle, { text: label, extraClass: 'sub-title__label' })),
        React.createElement("input", { ref: ref, className: "text-input__input", type: type, disabled: disabled, value: ((_b = maskRef.current) === null || _b === void 0 ? void 0 : _b.value) || value, placeholder: placeholder, onChange: handleChange })));
};
export default MaskInput;
