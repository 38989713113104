import { EColumnTableType } from '@/types/components/table';
export var modalFooterData = {
    'ASSIGN_CONFIRMATION': [
        { label: "Да. подтвердить", type: "ACCEPT" },
        { label: "Нет, отмена", type: "DECLINE" },
    ],
};
export var tableHead = [
    {
        name: '№',
        field: 'id',
    },
    {
        name: 'registrar.seller_fio',
        fields: [
            'seller.personal_record.last_name',
            'seller.personal_record.first_name',
            'seller.personal_record.middle_name',
        ],
        columnType: EColumnTableType.CONCAT
    },
    {
        name: 'registrar.seller_iin',
        field: 'seller.user.iin',
    },
    {
        name: 'registrar.seller_phone',
        field: 'seller.user.phone',
    },
    {
        name: 'registrar.apartment_data',
        field: 'building.address.full_address',
    },
    {
        name: 'registrar.buyer_fio',
        fields: [
            'client.personal_record.last_name',
            'client.personal_record.first_name',
            'client.personal_record.middle_name',
        ],
        columnType: EColumnTableType.CONCAT
    },
    {
        name: 'registrar.buyer_iin',
        field: 'client.user.iin',
    },
    {
        name: 'registrar.buyer_phone',
        field: 'client.user.phone',
    },
    {
        name: 'registrar.meet_date',
        field: 'meet_date',
        columnType: EColumnTableType.DATE,
        format: 'DD-MM-YYYY HH:mm'
    }, {
        name: 'flow.profile.status',
        field: 'status',
        columnType: EColumnTableType.STATUS,
    },
];
