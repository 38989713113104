import React, { useState } from 'react';
import './index.scss';
import { BaseContainer, Button, ShowGroup } from "@/components";
import SubTitle from "@/components/ui/sub-title";
import WarningMessage from "@/components/chunks/mortgage-info-form/warning-message";
import SVG from "react-inlinesvg";
import PaymentPage from "@/components/chunks/payment-page";
import { useTranslation } from "react-i18next";
var Check = require('public/chunks/steps/check.svg');
var ComissionForm = function (_a) {
    var _b = _a.isPaid, isPaid = _b === void 0 ? false : _b, onPay = _a.onPay, isLoading = _a.isLoading, full_name = _a.full_name, order = _a.order;
    var t = useTranslation().t;
    var _c = useState(false), openForm = _c[0], setOpenForm = _c[1];
    if (isPaid)
        return (React.createElement(BaseContainer, null,
            React.createElement("div", { className: 'comission-form__paid' },
                React.createElement(SVG, { src: Check, width: 20, height: 20 }),
                React.createElement(SubTitle, { text: t('steps.step4.komissia_ocenwika_payed'), style: { margin: '0' } }))));
    return (React.createElement(BaseContainer, null,
        React.createElement("div", { className: 'comission-form__warnings' },
            React.createElement(SubTitle, { text: t('steps.step4.uslugi_ocenwika') }),
            React.createElement(ShowGroup, { conditions: [(order === null || order === void 0 ? void 0 : order.program) == '1', (order === null || order === void 0 ? void 0 : order.program) == '36', (order === null || order === void 0 ? void 0 : order.program) == '1', (order === null || order === void 0 ? void 0 : order.program) == '36'] },
                React.createElement(WarningMessage, { message: "".concat(full_name, ", ").concat(t('steps.step4.ocenwik_description_2')) }),
                React.createElement(WarningMessage, { message: "".concat(full_name, ", ").concat(t('steps.step4.ocenwik_description_3')) }),
                React.createElement(WarningMessage, { message: t('steps.step4.ocenwik_description_5') }),
                React.createElement(WarningMessage, { message: t('steps.step4.ocenwik_description_6') }))),
        React.createElement(ShowGroup, { conditions: [!openForm, openForm] },
            React.createElement(Button, { onClick: function () { return setOpenForm(true); }, loading: isLoading === null || isLoading === void 0 ? void 0 : isLoading.onPay, label: t('steps.pay_button'), className: 'outline', style: { width: '100%' } }),
            React.createElement(PaymentPage, { onPay: onPay, loading: isLoading === null || isLoading === void 0 ? void 0 : isLoading.onPay }))));
};
export default ComissionForm;
