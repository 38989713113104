export var parseParams = function (url) {
    if (!(url === null || url === void 0 ? void 0 : url.length))
        return {};
    var result = {};
    url.substring(1).split("&").forEach(function (part) {
        var _a = part.split("="), key = _a[0], val = _a[1];
        var decoded = decodeURIComponent(val);
        result[key] = String(decoded).split('__').join(' ');
    });
    return result;
};
