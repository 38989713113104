var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState, } from "react";
import { useAuthorization } from "@/saga-query/query-hooks/useAuthorization";
import { useNavigate, useParams } from "react-router-dom";
import { useNotification } from "@/saga-query/query-hooks/useNotification";
import { useQuery } from "@/saga-query/query-hooks/useQuery";
import { Api } from "@/types/api";
import { useProgress } from "@/hooks/useProgress";
import { ThemeContext } from "@/containers/theme-container";
import configs, { MORTGAGE_URL } from '@/saga-query/constants';
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { modalFooterData } from "@/containers/order-container/data";
import { useMutation } from "@/saga-query/query-hooks/useMutation";
import { useSearchParams } from "@/hooks/useQueryParams/useQueryParams";
import { getStepByStatus } from "@/options/steps/steps";
import { clientStepsNotary, sellerStepsNotary, spouseStepsNotary } from "@/options/steps/notary";
import { clientStepsRegistrar, sellerStepsRegistrar, spouseStepsRegistrar } from "@/options/steps/registrar";
export var OrderContext = createContext({});
export var OrderContextProvider = function (_a) {
    var children = _a.children, options = _a.options;
    var t = useTranslation().t;
    var searchParams = useSearchParams();
    var themeData = useContext(ThemeContext);
    var wsRef = useRef(null);
    var retryRef = useRef(3);
    var uuid = useParams().uuid;
    var _b = useAuthorization(), profile = _b.profile, setCredentials = _b.setCredentials;
    var navigate = useNavigate();
    var progressProps = useProgress('INIT', console.log);
    var _c = useNotification(), onSuccess = _c.onSuccess, onWarn = _c.onWarn;
    var _d = useState(false), isError = _d[0], setIsError = _d[1];
    var _e = useState(false), isRetry = _e[0], setIsRetry = _e[1];
    var _f = useQuery({
        api: "/applications/".concat(uuid, "/"),
        type: [Api.LOAD_APPLICATION_BY_UUID, uuid],
        refetchInterval: 120000,
        forceUpdate: true,
        showErrorMessage: true,
        onSuccess: function () {
            setIsError(false);
            setIsRetry(false);
            retryRef.current = 3;
        },
        onError: function () {
            retryRef.current = --retryRef.current;
            setIsError(true);
            retryRef.current > 0 && setIsRetry(true);
        },
    }), order = _f.data, isLoading = _f.isLoading, refetch = _f.refetch;
    /*const order = Object.assign({}, order1, {
      status: 'CLIENT_TERMS_OF_DEAL_APPROVED',
      registrar_choice: 'NOTARY',
      user_role: 'CLIENT',
      notary: {id: null}
    })*/
    useEffect(function () {
        var timeout = null;
        if (retryRef.current > 0 && isError) {
            timeout = setTimeout(refetch, (3 - retryRef.current) * 2 * 2000);
        }
        else if (retryRef.current == 0) {
            setIsRetry(false);
        }
        return function () { return timeout && clearTimeout(timeout); };
    }, [retryRef.current, isError]);
    var _g = useMutation({
        api: "/applications/".concat(uuid, "/reject/"),
        method: 'POST',
        type: Api.POST_APPLICATION_REJECT,
        typeToUpdate: [Api.LOAD_APPLICATION_BY_UUID, uuid],
        showErrorMessage: true,
        onSuccess: function () {
            onSuccess({ message: (order === null || order === void 0 ? void 0 : order.is_integration) ? "\u0417\u0430\u044F\u0432\u043A\u0430 \u043E\u0442\u043C\u0435\u043D\u0435\u043D\u0430. \u0412\u044B \u0431\u0443\u0434\u0435\u0442\u0435 \u043F\u0435\u0440\u0435\u043D\u0430\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u044B \u043D\u0430 \u0438\u043F\u043E\u0442\u0435\u043A\u0443." : 'Заявка отменена' });
            if (order === null || order === void 0 ? void 0 : order.is_integration)
                setTimeout(function () { return navigate(MORTGAGE_URL); }, 5000);
        }
    }), onReject = _g[0], isLoadingOnReject = _g[1].isLoading;
    var _h = useState(getStepByStatus('DEFAULT', order === null || order === void 0 ? void 0 : order.user_role, order === null || order === void 0 ? void 0 : order.registrar_choice)), step = _h[0], set_step = _h[1];
    var setStep = useCallback(function (status) { return set_step(getStepByStatus(status, order === null || order === void 0 ? void 0 : order.user_role, order === null || order === void 0 ? void 0 : order.registrar_choice)); }, [order === null || order === void 0 ? void 0 : order.user_role, order === null || order === void 0 ? void 0 : order.registrar_choice]);
    useEffect(function () { return setStep(order === null || order === void 0 ? void 0 : order.status); }, [order === null || order === void 0 ? void 0 : order.status]);
    var _j = useState({
        type: null,
        isOpen: false,
        title: '',
        description: '',
    }), modalData = _j[0], setModalData = _j[1];
    var webSocketConnection = useCallback(function () {
        if (wsRef.current)
            return;
        wsRef.current = new WebSocket("".concat(configs.SOCKET, "/sub/").concat(order === null || order === void 0 ? void 0 : order.uuid));
        wsRef.current.onmessage = function (event) {
            var response = JSON.parse(event === null || event === void 0 ? void 0 : event.data);
            console.log('webSocket onmessage', response);
            if ((response === null || response === void 0 ? void 0 : response.status) !== (order === null || order === void 0 ? void 0 : order.status))
                refetch();
        };
        wsRef.current.onclose = function (e) {
            console.log('webSocket onclose', e);
        };
        wsRef.current.onerror = function (error) {
            console.log('webSocket onerror', error);
        };
    }, [order === null || order === void 0 ? void 0 : order.uuid, wsRef.current]);
    useEffect(function () {
        (order === null || order === void 0 ? void 0 : order.uuid) && webSocketConnection();
        return function () {
            var _a;
            console.log('CLOSE', wsRef.current);
            (_a = wsRef.current) === null || _a === void 0 ? void 0 : _a.close();
        };
    }, [order === null || order === void 0 ? void 0 : order.uuid]);
    var startProgressByStatus = function (order, data) {
        var currentProgress = data.find(function (data) { return data[0]; });
        if (Array.isArray(currentProgress))
            progressProps.setStart(currentProgress[1]);
        else
            progressProps.setDone();
    };
    var progressItem = function (cond, text) { return [cond, text]; };
    useEffect(function () {
        startProgressByStatus(order, [
            progressItem(['CLIENT_PAYMENT', 'CLIENT_PAID'].includes(order === null || order === void 0 ? void 0 : order.status) && (order === null || order === void 0 ? void 0 : order.user_role) !== 'CLIENT', t('flow.progress.buyer_pay_commission')),
            progressItem(['REGISTRY_REGISTRATION', 'REGISTRY_REGISTERED'].includes(order === null || order === void 0 ? void 0 : order.status), t('flow.progress.registry_registration')),
            progressItem(['WAITING_GBDRN_REGISTRATION', 'GBDRN_REGISTERED'].includes(order === null || order === void 0 ? void 0 : order.status), t('flow.progress.gbdrn_registration')),
            progressItem(['WAITING_SHIPMENT_TO_ENIS', 'WAITING_TO_RECEIVE_ENIS_CODE'].includes(order === null || order === void 0 ? void 0 : order.status), t('flow.progress.send_dkp_to_enis')),
            progressItem(['PAYMENT_OF_STATE_DUTY'].includes(order === null || order === void 0 ? void 0 : order.status), t('flow.progress.pay_state_duty')),
            progressItem(['ESCROW_REPLENISHMENT'].includes(order === null || order === void 0 ? void 0 : order.status), t('flow.progress.escrow_refill')),
            progressItem(['REGISTRAR_CONFIRMATION'].includes(order === null || order === void 0 ? void 0 : order.status) && (order === null || order === void 0 ? void 0 : order.user_role) !== 'CLIENT', t("flow.progress.video_call") + dayjs(order === null || order === void 0 ? void 0 : order.meet_date).format('DD MMMM в HH:mm ч.')),
            progressItem(['NOTARY_CONFIRMATION'].includes(order === null || order === void 0 ? void 0 : order.status) && (order === null || order === void 0 ? void 0 : order.user_role) !== 'CLIENT', t("flow.progress.video_call_notary") + dayjs(order === null || order === void 0 ? void 0 : order.meet_date).format('DD MMMM в HH:mm ч.')),
            progressItem([
                'CLIENT_TERMS_OF_DEAL_APPROVED',
                'CLIENT_ID_CARD_VERIFICATION',
                'CLIENT_ID_CARD_VERIFIED',
                'CLIENT_SPOUSE_TERMS_OF_DEAL_APPROVED',
                'CLIENT_SPOUSE_CONFIRMING_REAL_ESTATE',
                'CLIENT_SPOUSE_ID_CARD_VERIFIED',
                'CLIENT_SPOUSE_ID_CARD_VERIFICATION',
                'CLIENT_SIGNED_DOCS',
                'CLIENT_SPOUSE_BIOMETRY_VERIFIED',
                'CLIENT_SPOUSE_OTP_VERIFIED',
                'CLIENT_SPOUSE_OTP_VERIFICATION',
            ].includes(order === null || order === void 0 ? void 0 : order.status) && !['CLIENT', 'CLIENT_SPOUSE'].includes(order === null || order === void 0 ? void 0 : order.user_role), t("flow.progress.waiting_sms")),
            progressItem(['REAL_ESTATE_GBDRN_VERIFICATION'].includes(order === null || order === void 0 ? void 0 : order.status), t("flow.progress.building_checking")),
            progressItem(['PAYMENT_BY_THE_BANK'].includes(order === null || order === void 0 ? void 0 : order.status), t("flow.progress.payment_by_the_bank")),
            progressItem(['CLIENT_CHECKING'].includes(order === null || order === void 0 ? void 0 : order.status), t("flow.progress.bank_checking")),
            progressItem(["".concat(order === null || order === void 0 ? void 0 : order.user_role, "_BIOMETRY_VERIFICATION")].includes(order === null || order === void 0 ? void 0 : order.status), null),
        ]);
    }, [order === null || order === void 0 ? void 0 : order.status]);
    var getAcceptFunctionByType = useCallback(function (type) {
        var fnData = {
            'CONFIRMATION_REJECT': function () {
                onReject();
                onModalClose();
            },
        };
        return fnData[type] || console.log;
    }, []);
    var isDisabledModalFooterByType = useCallback(function (type) {
        var ACCEPT = false;
        switch (type) {
            case 'CONFIRMATION_REJECT':
                ACCEPT = false;
                break;
        }
        return { ACCEPT: ACCEPT };
    }, []);
    var onRejectHandler = function () {
        setModalData({
            isOpen: true,
            title: 'Отмена заявки',
            type: 'CONFIRMATION_REJECT',
            description: (order === null || order === void 0 ? void 0 : order.is_integration)
                ? React.createElement("p", null,
                    "\u0412\u044B \u0443\u0432\u0435\u0440\u0435\u043D\u044B \u0447\u0442\u043E \u0445\u043E\u0442\u0438\u0442\u0435 \u043E\u0442\u043C\u0435\u043D\u0438\u0442\u044C \u0437\u0430\u044F\u0432\u043A\u0443? ",
                    React.createElement("br", null),
                    " \u0412\u044B \u0431\u0443\u0434\u0435\u0442\u0435 \u043F\u0435\u0440\u0435\u043D\u0430\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u044B \u043D\u0430 \u044D\u0442\u0430\u043F \u0432\u044B\u0431\u043E\u0440\u0430 \u0432\u0438\u0434\u0430 \u0441\u0434\u0435\u043B\u043A\u0438 \u043D\u0430 \u0441\u0430\u0439\u0442 \u0438\u043F\u043E\u0442\u0435\u043A\u0438.")
                : 'Вы уверены что хотите отменить заявку?'
        });
    };
    var onModalClose = useCallback(function () {
        setModalData(__assign(__assign({}, modalData), { isOpen: false }));
    }, []);
    var getStepsByRole = useCallback(function (type, registrar_choice) {
        var data = {
            CLIENT: registrar_choice === 'NOTARY' ? clientStepsNotary : clientStepsRegistrar,
            SELLER: registrar_choice === 'NOTARY' ? sellerStepsNotary : sellerStepsRegistrar,
            CLIENT_SPOUSE: registrar_choice === 'NOTARY' ? spouseStepsNotary : spouseStepsRegistrar,
            SELLER_SPOUSE: registrar_choice === 'NOTARY' ? spouseStepsNotary : spouseStepsRegistrar,
        };
        return data[type] || Array(10).fill({ step: 0, component: 'DEFAULT' });
    }, []);
    var prepareData = useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40;
        return ({
            CLIENT: {
                idCardDoc: (_b = (_a = order === null || order === void 0 ? void 0 : order['client']) === null || _a === void 0 ? void 0 : _a.ddocs) === null || _b === void 0 ? void 0 : _b.id_card,
                idCardNumber: (_d = (_c = order === null || order === void 0 ? void 0 : order['client']) === null || _c === void 0 ? void 0 : _c.ddocs) === null || _d === void 0 ? void 0 : _d.id_card_number,
                userData: order === null || order === void 0 ? void 0 : order.client,
                seller: order === null || order === void 0 ? void 0 : order.seller,
                client: order === null || order === void 0 ? void 0 : order.client,
                ecpVerified: (_e = order === null || order === void 0 ? void 0 : order.docs) === null || _e === void 0 ? void 0 : _e.client_ecp_verified,
                userName: [
                    (_g = (_f = order === null || order === void 0 ? void 0 : order['client']) === null || _f === void 0 ? void 0 : _f.personal_record) === null || _g === void 0 ? void 0 : _g.last_name,
                    (_j = (_h = order === null || order === void 0 ? void 0 : order['client']) === null || _h === void 0 ? void 0 : _h.personal_record) === null || _j === void 0 ? void 0 : _j.first_name,
                    (_l = (_k = order === null || order === void 0 ? void 0 : order['client']) === null || _k === void 0 ? void 0 : _k.personal_record) === null || _l === void 0 ? void 0 : _l.middle_name,
                ].filter(Boolean).join(' '),
                iin: (_o = (_m = order === null || order === void 0 ? void 0 : order['client']) === null || _m === void 0 ? void 0 : _m.user) === null || _o === void 0 ? void 0 : _o.iin,
                spouse: (_p = order === null || order === void 0 ? void 0 : order['client']) === null || _p === void 0 ? void 0 : _p.spouse,
            },
            SELLER: {
                idCardDoc: (_r = (_q = order === null || order === void 0 ? void 0 : order['seller']) === null || _q === void 0 ? void 0 : _q.ddocs) === null || _r === void 0 ? void 0 : _r.id_card,
                idCardNumber: (_t = (_s = order === null || order === void 0 ? void 0 : order['seller']) === null || _s === void 0 ? void 0 : _s.ddocs) === null || _t === void 0 ? void 0 : _t.id_card_number,
                userData: order === null || order === void 0 ? void 0 : order.seller,
                seller: order === null || order === void 0 ? void 0 : order.seller,
                client: order === null || order === void 0 ? void 0 : order.client,
                ecpVerified: (_u = order === null || order === void 0 ? void 0 : order.docs) === null || _u === void 0 ? void 0 : _u.seller_ecp_verified,
                userName: [
                    (_w = (_v = order === null || order === void 0 ? void 0 : order['seller']) === null || _v === void 0 ? void 0 : _v.personal_record) === null || _w === void 0 ? void 0 : _w.last_name,
                    (_y = (_x = order === null || order === void 0 ? void 0 : order['seller']) === null || _x === void 0 ? void 0 : _x.personal_record) === null || _y === void 0 ? void 0 : _y.first_name,
                    (_0 = (_z = order === null || order === void 0 ? void 0 : order['seller']) === null || _z === void 0 ? void 0 : _z.personal_record) === null || _0 === void 0 ? void 0 : _0.middle_name,
                ].filter(Boolean).join(' '),
                iin: (_2 = (_1 = order === null || order === void 0 ? void 0 : order['seller']) === null || _1 === void 0 ? void 0 : _1.user) === null || _2 === void 0 ? void 0 : _2.iin,
                spouse: (_3 = order === null || order === void 0 ? void 0 : order['seller']) === null || _3 === void 0 ? void 0 : _3.spouse,
            },
            CLIENT_SPOUSE: {
                idCardDoc: (_6 = (_5 = (_4 = order === null || order === void 0 ? void 0 : order['client']) === null || _4 === void 0 ? void 0 : _4.spouse) === null || _5 === void 0 ? void 0 : _5.ddocs) === null || _6 === void 0 ? void 0 : _6.id_card,
                idCardNumber: (_9 = (_8 = (_7 = order === null || order === void 0 ? void 0 : order['client']) === null || _7 === void 0 ? void 0 : _7.spouse) === null || _8 === void 0 ? void 0 : _8.ddocs) === null || _9 === void 0 ? void 0 : _9.id_card_number,
                userData: (_10 = order === null || order === void 0 ? void 0 : order.client) === null || _10 === void 0 ? void 0 : _10.spouse,
                seller: order === null || order === void 0 ? void 0 : order.seller,
                client: order === null || order === void 0 ? void 0 : order.client,
                ecpVerified: (_11 = order === null || order === void 0 ? void 0 : order.docs) === null || _11 === void 0 ? void 0 : _11.client_spouse_ecp_verified,
                userName: [
                    (_14 = (_13 = (_12 = order === null || order === void 0 ? void 0 : order['client']) === null || _12 === void 0 ? void 0 : _12.spouse) === null || _13 === void 0 ? void 0 : _13.personal_record) === null || _14 === void 0 ? void 0 : _14.last_name,
                    (_17 = (_16 = (_15 = order === null || order === void 0 ? void 0 : order['client']) === null || _15 === void 0 ? void 0 : _15.spouse) === null || _16 === void 0 ? void 0 : _16.personal_record) === null || _17 === void 0 ? void 0 : _17.first_name,
                    (_20 = (_19 = (_18 = order === null || order === void 0 ? void 0 : order['client']) === null || _18 === void 0 ? void 0 : _18.spouse) === null || _19 === void 0 ? void 0 : _19.personal_record) === null || _20 === void 0 ? void 0 : _20.middle_name,
                ].filter(Boolean).join(' '),
                spouse: null,
            },
            SELLER_SPOUSE: {
                idCardDoc: (_23 = (_22 = (_21 = order === null || order === void 0 ? void 0 : order['seller']) === null || _21 === void 0 ? void 0 : _21.spouse) === null || _22 === void 0 ? void 0 : _22.ddocs) === null || _23 === void 0 ? void 0 : _23.id_card,
                idCardNumber: (_26 = (_25 = (_24 = order === null || order === void 0 ? void 0 : order['seller']) === null || _24 === void 0 ? void 0 : _24.spouse) === null || _25 === void 0 ? void 0 : _25.ddocs) === null || _26 === void 0 ? void 0 : _26.id_card_number,
                userData: (_27 = order === null || order === void 0 ? void 0 : order.seller) === null || _27 === void 0 ? void 0 : _27.spouse,
                seller: order === null || order === void 0 ? void 0 : order.seller,
                client: order === null || order === void 0 ? void 0 : order.client,
                ecpVerified: (_28 = order === null || order === void 0 ? void 0 : order.docs) === null || _28 === void 0 ? void 0 : _28.seller_spouse_ecp_verified,
                userName: [
                    (_31 = (_30 = (_29 = order === null || order === void 0 ? void 0 : order['seller']) === null || _29 === void 0 ? void 0 : _29.spouse) === null || _30 === void 0 ? void 0 : _30.personal_record) === null || _31 === void 0 ? void 0 : _31.last_name,
                    (_34 = (_33 = (_32 = order === null || order === void 0 ? void 0 : order['seller']) === null || _32 === void 0 ? void 0 : _32.spouse) === null || _33 === void 0 ? void 0 : _33.personal_record) === null || _34 === void 0 ? void 0 : _34.first_name,
                    (_37 = (_36 = (_35 = order === null || order === void 0 ? void 0 : order['seller']) === null || _35 === void 0 ? void 0 : _35.spouse) === null || _36 === void 0 ? void 0 : _36.personal_record) === null || _37 === void 0 ? void 0 : _37.middle_name,
                ].filter(Boolean).join(' '),
                iin: (_40 = (_39 = (_38 = order === null || order === void 0 ? void 0 : order['seller']) === null || _38 === void 0 ? void 0 : _38.spouse) === null || _39 === void 0 ? void 0 : _39.user) === null || _40 === void 0 ? void 0 : _40.iin,
                spouse: null,
            },
        });
    }, [order === null || order === void 0 ? void 0 : order.status]);
    // const userRole = 'CLIENT'
    var showSideBarSteps = true;
    var steps = getStepsByRole(order === null || order === void 0 ? void 0 : order.user_role, order === null || order === void 0 ? void 0 : order.registrar_choice);
    var stepIndex = steps.findIndex(function (s) { return s.step == step; });
    var percent = ((stepIndex + 1) / steps.length) * 100;
    return (React.createElement(OrderContext.Provider, { value: {
            themeData: themeData,
            isLoading: {
                order: isLoading || isLoadingOnReject || isRetry,
                reject: isLoadingOnReject,
            },
            isDisabled: {
                order: isLoading || isLoadingOnReject,
                reject: isLoadingOnReject,
            },
            showElem: {},
            uuid: uuid,
            order: order !== null && order !== void 0 ? order : {},
            userInfoData: prepareData[order === null || order === void 0 ? void 0 : order.user_role] || {},
            progressProps: progressProps,
            profile: profile,
            step: step,
            steps: steps,
            showSideBarSteps: showSideBarSteps,
            percent: percent,
            handlers: {
                onRejectHandler: onRejectHandler
            },
            modalParams: {
                modalData: modalData,
                isDisabled: isDisabledModalFooterByType(modalData === null || modalData === void 0 ? void 0 : modalData.type),
                onDecline: onModalClose,
                onAccept: getAcceptFunctionByType(modalData === null || modalData === void 0 ? void 0 : modalData.type),
                modalFooterData: modalFooterData[modalData === null || modalData === void 0 ? void 0 : modalData.type],
                onModalClose: onModalClose,
            }
        } }, children));
};
