import React, { useRef } from 'react';
import './index.scss';
import SubTitle from "@/components/ui/sub-title";
import { Button, ShowList } from "@/components";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import { downloadFileHelper } from "@/helpers/downloadFileHelper";
var Upload = require('public/upload.svg');
var Document = require('public/chunks/steps/document.svg');
export var CabinetDocumentCard = function (_a) {
    var name = _a.name, onUpload = _a.onUpload, link = _a.link, isLoading = _a.isLoading;
    var uploadRef = useRef();
    var onUploadHandler = function (e) {
        e.stopPropagation();
        var file = e.target.files[0];
        onUpload(file);
    };
    return (React.createElement("div", { className: 'cabinet-document-block__document-card' },
        React.createElement(SVG, { src: Document, width: 20, height: 20, className: "".concat(!link ? 'not-selected' : '') }),
        React.createElement(SubTitle, { text: name, style: { margin: '0 0 0 8px', fontSize: '14px', flex: '1' }, extraClass: "".concat(link ? 'pointer' : ''), onClick: function () {
                link && downloadFileHelper(link, name, { onNewWindow: true });
            } }),
        React.createElement("input", { ref: uploadRef, type: 'file', onChange: onUploadHandler, style: {
                width: 0,
                height: 0,
                padding: 0,
                margin: 0,
                position: "absolute",
                left: -9999
            } }),
        React.createElement(Button, { onClick: function (e) {
                var _a;
                e.preventDefault();
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                (_a = uploadRef === null || uploadRef === void 0 ? void 0 : uploadRef.current) === null || _a === void 0 ? void 0 : _a.click();
            }, label: '', svg_left: Upload, className: 'icon', disabled: isLoading, loading: isLoading })));
};
var CabinetDocumentUploadBlock = function (_a) {
    var title = _a.title, docs = _a.docs, isLoading = _a.isLoading;
    var t = useTranslation().t;
    return (React.createElement("div", { className: 'cabinet-document-block__wrapper' },
        React.createElement(SubTitle, { extraClass: "sub-title_title", text: t(title), style: { margin: '0' } }),
        React.createElement(ShowList, { list: docs }, function (doc, i) {
            return React.createElement(CabinetDocumentCard, { key: i, onUpload: doc.onUpload, name: t(doc.name), link: doc.link, isLoading: isLoading });
        })));
};
export default CabinetDocumentUploadBlock;
