import { ThemeContext } from "@/containers/theme-container";
import { getThemeClassName } from "@/helpers/getThemeClassName";
import React, { useContext } from "react";
import "./index.scss";
var RadioInput = function (_a) {
    var label = _a.label, onChange = _a.onChange, name = _a.name, value = _a.value, checked = _a.checked, extraClass = _a.extraClass;
    var theme = useContext(ThemeContext).theme;
    var handleChange = function (e) {
        onChange(e.target.value);
    };
    return (React.createElement("label", { className: "radio-input__label radio-input_".concat(getThemeClassName(theme), " ").concat(extraClass !== null && extraClass !== void 0 ? extraClass : ''), key: label },
        React.createElement("input", { type: "radio", name: name, value: value, checked: checked, className: "radio-input__input", onChange: handleChange }),
        label));
};
export default RadioInput;
