var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useState } from 'react';
export var useProgress = function (type, onFinish, text) {
    var _a, _b;
    if (text === void 0) { text = ''; }
    var _c = useState({
        isActive: false,
        isDone: false,
        type: type
    }), progressData = _c[0], setProgressData = _c[1];
    var setStart = function (text) { return setProgressData(function (prev) { return (__assign(__assign({}, prev), { isDone: false, isActive: true, text: text })); }); };
    var setDone = function () { return setProgressData(function (prev) { return (__assign(__assign({}, prev), { isDone: true })); }); };
    var onFinishHandler = function () {
        setProgressData(function (prev) { return (__assign(__assign({}, prev), { isActive: false })); });
        onFinish();
    };
    return {
        progressData: [progressData],
        onFinish: (_a = {}, _a[type] = onFinishHandler, _a),
        text: (_b = {}, _b[type] = text || (progressData === null || progressData === void 0 ? void 0 : progressData.text), _b),
        setStart: setStart,
        setDone: setDone,
    };
};
