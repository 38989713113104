import React, { useEffect, useRef } from 'react';
import './index.scss';
import OtpInput from "@/components/ui/otp-input";
import { ShowIf, ShowSwitch } from "@/components";
import { Trans, useTranslation } from "react-i18next";
var PhoneInfo = function (_a) {
    var phone = _a.phone, onBackToResetPass = _a.onBackToResetPass;
    var t = useTranslation().t;
    return React.createElement("div", { className: 'otp-form__phone-info' },
        React.createElement("span", { className: 'otp-form__light-text' }, t('flow.stepOtp.code_was_sent_to_number')),
        React.createElement("h5", { className: 'otp-form__phone m-t-10' }, phone),
        React.createElement(ShowIf, { condition: !!onBackToResetPass },
            React.createElement("div", null,
                React.createElement("span", { onClick: onBackToResetPass, className: 'otp-form__link' }, t('flow.stepOtp.change_number')))));
};
var OtpForm = function (_a) {
    var value = _a.value, phone = _a.phone, changeValue = _a.changeValue, onResend = _a.onResend, isLoading = _a.isLoading, onResetPass = _a.onResetPass, isDisabled = _a.isDisabled, _b = _a.otpLength, otpLength = _b === void 0 ? 4 : _b, _c = _a.timerLength, timerLength = _c === void 0 ? 120 : _c, selectedTab = _a.selectedTab, list = _a.list, onListChange = _a.onListChange, _d = _a.extraClass, extraClass = _d === void 0 ? '' : _d;
    var t = useTranslation().t;
    var intervalRef = useRef(null);
    var _e = React.useState(false), finished = _e[0], setFinished = _e[1];
    var _f = React.useState(false), loading = _f[0], setLoading = _f[1];
    var _g = React.useState(''), timer = _g[0], setTimer = _g[1];
    useEffect(function () {
        startTimer(timerLength);
        return function () { return clearInterval(intervalRef.current); };
    }, []);
    function startTimer(duration) {
        setFinished(false);
        var timer = duration;
        var minutes;
        var seconds;
        intervalRef.current = setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);
            var transformValue = function (time) { var _a; return ((_a = String(time)) === null || _a === void 0 ? void 0 : _a.length) > 1 ? String(time) : "0".concat(time); };
            setTimer("".concat(transformValue(minutes), ":").concat(transformValue(seconds)));
            if (--timer < 0) {
                timer = duration;
                setFinished(true);
                clearInterval(intervalRef.current);
            }
        }, 1000);
    }
    var resendHandler = function () {
        onResend(function () { return startTimer(timerLength); });
        changeValue('');
    };
    return React.createElement("div", { className: 'otp-form ' + extraClass },
        React.createElement("h4", { className: 'otp-form__title' }, t('flow.stepOtp.code_sent')),
        React.createElement(PhoneInfo, { phone: phone, onBackToResetPass: onResetPass }),
        React.createElement("div", { className: 'otp-form__inputs' },
            React.createElement("span", { className: 'otp-form__light-text m-b-20' }, t('flow.stepOtp.enter_code_from_SMS')),
            React.createElement(OtpInput, { disabled: isDisabled, length: otpLength, value: value, onChange: changeValue })),
        React.createElement(ShowSwitch, { conditions: [!finished] },
            React.createElement("div", { className: 'otp-form__timer' },
                React.createElement(Trans, { i18nKey: "flow.stepOtp.request_code_again", count: timer },
                    "\u041F\u043E\u0432\u0442\u043E\u0440\u043D\u044B\u0439 \u0437\u0430\u043F\u0440\u043E\u0441 \u043A\u043E\u0434\u0430 \u0447\u0435\u0440\u0435\u0437",
                    React.createElement("strong", null,
                        timer,
                        " \u0441\u0435\u043A"),
                    ".")),
            React.createElement("span", { onClick: resendHandler, className: 'otp-form__link' }, t('flow.stepOtp.resend'))));
};
export default OtpForm;
