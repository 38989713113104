var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { modalFooterData, tabsNav } from "./data";
import { useQuery } from "@/saga-query/query-hooks/useQuery";
import { useNotification } from "@/saga-query/query-hooks/useNotification";
import { Api, RegistrarApi } from "@/types/api";
import { useAuthorization } from "@/saga-query/query-hooks/useAuthorization";
import { useTranslation } from "react-i18next";
import { useMutation } from "@/saga-query/query-hooks/useMutation";
import { useQueryCache } from "@/saga-query/query-hooks/useQueryCache";
var defaultValue = {
    tabsNav: [],
    tab: tabsNav[0]
};
export var ProfilePageContext = createContext(defaultValue);
export var ProfilePageContextProvider = function (_a) {
    var _b;
    var _c;
    var children = _a.children;
    var _d = useAuthorization(), profile = _d.profile, onUpdateProfile = _d.onUpdateProfile, user = _d.user;
    var navigate = useNavigate();
    var t = useTranslation().t;
    var _e = useState(tabsNav[0]), tab = _e[0], setTab = _e[1];
    var _f = useNotification(), onSuccess = _f.onSuccess, onWarn = _f.onWarn;
    var get = useQueryCache().get;
    var isLoadingProfile = get('C2C/auth/UPDATE_USER').isLoading;
    var _g = useState({
        type: null,
        isOpen: false,
        title: '',
        description: '',
    }), modalData = _g[0], setModalData = _g[1];
    var _h = useQuery({
        api: "/applications/",
        type: Api.LOAD_APPLICATIONS,
        data: { ordering: '-id' },
        immediately: false,
        forceUpdate: true,
        refetchInterval: 120000,
        showErrorMessage: true
    }), appListData = _h.data, isLoadingAppList = _h.isLoading, refetchAppList = _h.refetch;
    var _j = useMutation({
        api: "/notary/cabinet/change-active/",
        method: 'PATCH',
        type: RegistrarApi.PATCH_CHANGE_ACTIVE,
        typeToUpdate: 'C2C/auth/UPDATE_USER',
        showErrorMessage: true,
    }), onChangeActive = _j[0], isLoadingOnChangeActive = _j[1].isLoading;
    var prepareTabsData = (_b = {},
        _b[tabsNav[0].tabsPanel] = { load: onUpdateProfile, data: profile, title: 'flow.profile.profile_data' },
        _b[tabsNav[1].tabsPanel] = { load: refetchAppList, data: appListData, title: 'flow.profile.app_list' },
        _b);
    useEffect(function () {
        var _a;
        (_a = prepareTabsData[tab === null || tab === void 0 ? void 0 : tab.tabsPanel]) === null || _a === void 0 ? void 0 : _a.load();
    }, [tab === null || tab === void 0 ? void 0 : tab.tabsPanel]);
    var getAcceptFunctionByType = function (type) {
        var fnData = {
            'CONFIRMATION': function () {
            },
        };
        return fnData[type] || console.log;
    };
    var isDisabledModalFooterByType = function (type) {
        var ACCEPT = false;
        switch (type) {
            case 'CONFIRMATION':
                ACCEPT = false;
                break;
        }
        return { ACCEPT: ACCEPT };
    };
    var onModalClose = function () {
        setModalData(__assign(__assign({}, modalData), { isOpen: false }));
    };
    var onApplicationClickHandler = function (data) {
        (data === null || data === void 0 ? void 0 : data.uuid) && navigate("/steps/".concat(data === null || data === void 0 ? void 0 : data.uuid));
    };
    return React.createElement(ProfilePageContext.Provider, { value: {
            showElem: {},
            isLoading: {
                appList: isLoadingAppList,
                isLoadingProfile: isLoadingProfile,
                isLoadingOnChangeActive: isLoadingOnChangeActive
            },
            isDisabled: {
                appList: isLoadingAppList,
                isLoadingProfile: isLoadingProfile,
                isLoadingOnChangeActive: isLoadingOnChangeActive
            },
            tab: tab,
            tabsNav: tabsNav.filter(function (tab) { return !(user.user_role == "REGISTRAR" && tab.tabsPanel == 'APP_LIST'); }),
            setTab: setTab,
            options: {
                pageTitle: t((_c = prepareTabsData[tab === null || tab === void 0 ? void 0 : tab.tabsPanel]) === null || _c === void 0 ? void 0 : _c.title),
                appListData: (appListData === null || appListData === void 0 ? void 0 : appListData.results) || [],
                profile: profile,
                onChangeActive: onChangeActive,
                onApplicationClickHandler: onApplicationClickHandler,
                modalParams: {
                    modalData: modalData,
                    isDisabled: isDisabledModalFooterByType(modalData === null || modalData === void 0 ? void 0 : modalData.type),
                    onAccept: getAcceptFunctionByType(modalData === null || modalData === void 0 ? void 0 : modalData.type),
                    modalFooterData: modalFooterData === null || modalFooterData === void 0 ? void 0 : modalFooterData[modalData === null || modalData === void 0 ? void 0 : modalData.type],
                    onModalClose: onModalClose,
                }
            },
        } }, children);
};
