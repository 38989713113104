var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { useAuthorization } from "@/saga-query/query-hooks/useAuthorization";
import { useNavigate, useParams } from "react-router-dom";
import { useNotification } from "@/saga-query/query-hooks/useNotification";
import { useMutation } from "@/saga-query/query-hooks/useMutation";
import { Api } from "@/types/api";
import { conditionsHead, conditionsHeader, infoCadastralData, infoData, texts_client_spouse, texts_seller_spouse, } from "./data";
import { parseParams } from "@/helpers/parseParams";
import { useProgress } from "@/hooks/useProgress";
import { ThemeContext } from "@/containers/theme-container";
import { useQueryCache } from "@/saga-query/query-hooks/useQueryCache";
import { useTranslation } from "react-i18next";
import { clientStepsNotary, sellerStepsNotary, spouseStepsNotary } from "@/options/steps/notary";
import { clientStepsRegistrar, sellerStepsRegistrar, spouseStepsRegistrar } from "@/options/steps/registrar";
import { getStepByStatus } from "@/options/steps/steps";
export var InitSpouseContext = createContext({});
export var InitSpouseContextProvider = function (_a) {
    var _b, _c, _d, _e, _f;
    var children = _a.children, options = _a.options;
    var t = useTranslation().t;
    var uuid = useParams().uuid;
    var get = useQueryCache().get;
    var order = get([Api.LOAD_APPLICATION_BY_UUID, uuid]).data;
    var _g = useState(((_c = (_b = window.location) === null || _b === void 0 ? void 0 : _b.search) === null || _c === void 0 ? void 0 : _c.length) > 0 ? parseParams(window.location.search) : (_d = order === null || order === void 0 ? void 0 : order.client) === null || _d === void 0 ? void 0 : _d.user), params = _g[0], setParams = _g[1];
    var themeData = useContext(ThemeContext);
    var _h = useAuthorization(), profile = _h.profile, isAuth = _h.isAuth, onRecover = _h.onRecover, onVerifySuccess = _h.onVerifySuccess;
    var navigate = useNavigate();
    var _j = useState({}), verifyData = _j[0], setVerifyData = _j[1];
    var _k = useState(getStepByStatus('NEW', params === null || params === void 0 ? void 0 : params.role)), step = _k[0], set_step = _k[1];
    var _l = React.useState({ 'a': true, 'b': true, 'c': true, 'd': true }), checkBoxes = _l[0], setCheckBoxes = _l[1];
    var _m = useNotification(), onSuccess = _m.onSuccess, onWarn = _m.onWarn;
    var progressProps = useProgress('INIT', function () { return navigate("/steps/".concat(verifyData === null || verifyData === void 0 ? void 0 : verifyData.application_id)); });
    var setParamsHandler = function () {
        var _a, _b, _c, _d, _e;
        if (((_b = (_a = window.location) === null || _a === void 0 ? void 0 : _a.search) === null || _b === void 0 ? void 0 : _b.length) > 0)
            setParams(parseParams((_c = window.location) === null || _c === void 0 ? void 0 : _c.search));
        else if ((_d = order === null || order === void 0 ? void 0 : order.client) === null || _d === void 0 ? void 0 : _d.user)
            setParams((_e = order === null || order === void 0 ? void 0 : order.client) === null || _e === void 0 ? void 0 : _e.user);
    };
    useEffect(function () {
        setParamsHandler();
    }, [(_f = (_e = order === null || order === void 0 ? void 0 : order.client) === null || _e === void 0 ? void 0 : _e.user) === null || _f === void 0 ? void 0 : _f.phone]);
    var setStep = useCallback(function (status) { return set_step(getStepByStatus(status, params === null || params === void 0 ? void 0 : params.role)); }, [params === null || params === void 0 ? void 0 : params.role]);
    var changeParam = useCallback(function (key) { return function (value) {
        if (['iin', 'phone'].includes(key)) {
            return setParams(function (prev) {
                var _a;
                return (__assign(__assign({}, prev), (_a = {}, _a[key] = value.replace(/[^+\d]/g, ''), _a)));
            });
        }
        setParams(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[key] = value, _a)));
        });
    }; }, []);
    var _o = useMutation({
        api: "/applications/new/",
        method: 'POST',
        meta: { authorization: false },
        type: Api.POST_APPLICATIONS_NEW,
        showErrorMessage: true,
        onSuccess: function () {
            onSuccess({ title: '', message: 'Введите код по СМС' });
            setStep('CLIENT_OTP_VERIFICATION');
        }
    }), onNew = _o[0], isLoadingOnNew = _o[1].isLoading;
    var onSuccessVerify = useCallback(function (data) {
        progressProps.setStart();
        setVerifyData(data);
        onVerifySuccess(data);
        setTimeout(progressProps.setDone, 3000);
    }, []);
    var _p = useMutation({
        api: "/applications/clients/otp-verify/",
        method: 'POST',
        type: Api.POST_APPLICATIONS_VERIFY,
        meta: { authorization: false },
        showErrorMessage: true,
        onSuccess: onSuccessVerify
    }), onClientsVerify = _p[0], isLoadingOnClientsVerify = _p[1].isLoading;
    var _q = useMutation({
        api: "/applications/sellers/otp-verify/",
        method: 'POST',
        type: Api.POST_APPLICATIONS_VERIFY,
        meta: { authorization: false },
        showErrorMessage: true,
        onSuccess: onSuccessVerify
    }), onSellersVerify = _q[0], isLoadingOnSellersVerify = _q[1].isLoading;
    var _r = useMutation({
        api: "/applications/".concat(params === null || params === void 0 ? void 0 : params.uuid, "/sellers/confirm-real-estate/"),
        method: 'POST',
        type: Api.POST_APPLICATIONS_VERIFY,
        meta: { authorization: false },
        showErrorMessage: true,
        onSuccess: onSuccessVerify
    }), onSellersConfirm = _r[0], isLoadingOnSellersConfirm = _r[1].isLoading;
    var _s = useMutation({
        api: "/applications/".concat(params === null || params === void 0 ? void 0 : params.uuid, "/spouses/confirm-real-estate/"),
        method: 'POST',
        type: Api.POST_APPLICATIONS_VERIFY,
        meta: { authorization: false },
        showErrorMessage: true,
        onSuccess: onSuccessVerify
    }), onSpousesConfirm = _s[0], isLoadingOnSpousesConfirm = _s[1].isLoading;
    var _t = useMutation({
        api: "/applications/spouses/otp-verify/",
        method: 'POST',
        type: Api.POST_APPLICATIONS_VERIFY,
        meta: { authorization: false },
        showErrorMessage: true,
        onSuccess: onSuccessVerify
    }), onSpousesVerify = _t[0], isLoadingOnSpousesVerify = _t[1].isLoading;
    var _u = useState({
        type: null,
        isOpen: false,
        title: '',
        description: '',
    }), modalData = _u[0], setModalData = _u[1];
    var getAcceptFunctionByType = useCallback(function (type) {
        var fnData = {
            'CONFIRMATION': function () {
            },
        };
        return fnData[type] || console.log;
    }, []);
    var prepareData = useMemo(function () { return ({
        CLIENT: {
            onApply: function () { return onNew(params); },
            onVerify: function () { return onClientsVerify(params); },
        },
        SELLER: {
            texts: params === null || params === void 0 ? void 0 : params.client_iin,
            infoData: !(params === null || params === void 0 ? void 0 : params.cadastral_number) ? infoData : infoCadastralData,
            conditionsHeader: conditionsHeader,
            conditionsHead: conditionsHead,
            onApply: function () { return onSellersConfirm({}, { onSuccess: function () { return setStep('CLIENT_OTP_VERIFICATION'); } }); },
            onVerify: function () { return onSellersVerify({ phone: params.phone, code: params.code, }); },
        },
        CLIENT_SPOUSE: {
            texts: texts_client_spouse(params === null || params === void 0 ? void 0 : params.iin),
            infoData: (params === null || params === void 0 ? void 0 : params.address_choice) !== 'FROM_CADASTRAL' ? infoData : infoCadastralData,
            conditionsHeader: conditionsHeader,
            conditionsHead: conditionsHead,
            onApply: function () { return onSpousesConfirm({}, { onSuccess: function () { return setStep('CLIENT_OTP_VERIFICATION'); } }); },
            onVerify: function () { return onSpousesVerify({ phone: params.phone, code: params.code, }); },
        },
        SELLER_SPOUSE: {
            texts: texts_seller_spouse(params === null || params === void 0 ? void 0 : params.iin),
            infoData: (params === null || params === void 0 ? void 0 : params.address_choice) !== 'FROM_CADASTRAL' ? infoData : infoCadastralData,
            conditionsHeader: conditionsHeader,
            conditionsHead: conditionsHead,
            onApply: function () { return onSpousesConfirm({}, { onSuccess: function () { return setStep('CLIENT_OTP_VERIFICATION'); } }); },
            onVerify: function () { return onSpousesVerify({ phone: params.phone, code: params.code, }); },
        },
    }); }, [params]);
    var isDisabledModalFooterByType = useCallback(function (type) {
        var ACCEPT = false;
        switch (type) {
            case 'CONFIRMATION':
                ACCEPT = false;
                break;
        }
        return { ACCEPT: ACCEPT };
    }, []);
    var onModalClose = useCallback(function () {
        setModalData(__assign(__assign({}, modalData), { isOpen: false }));
    }, []);
    var getStepsByType = useCallback(function (type, registrar_choice) {
        var data = {
            CLIENT: registrar_choice === 'NOTARY' ? clientStepsNotary : clientStepsRegistrar,
            SELLER: registrar_choice === 'NOTARY' ? sellerStepsNotary : sellerStepsRegistrar,
            CLIENT_SPOUSE: registrar_choice === 'NOTARY' ? spouseStepsNotary : spouseStepsRegistrar,
            SELLER_SPOUSE: registrar_choice === 'NOTARY' ? spouseStepsNotary : spouseStepsRegistrar,
        };
        return data[type] || Array(10).fill({ step: 0, component: 'DEFAULT' });
    }, []);
    var setPrevStep = useCallback(function () { return setStep('NEW'); }, []);
    var showSideBarSteps = true;
    var steps = getStepsByType(params === null || params === void 0 ? void 0 : params.role, params === null || params === void 0 ? void 0 : params.registrar_choice);
    var stepIndex = steps.findIndex(function (s) { return s.step == step; });
    var percent = ((stepIndex + 1) / (steps === null || steps === void 0 ? void 0 : steps.length)) * 100;
    return (React.createElement(InitSpouseContext.Provider, { value: {
            themeData: themeData,
            registrar_choice: params === null || params === void 0 ? void 0 : params.registrar_choice,
            isLoading: {
                onApply: isLoadingOnNew || isLoadingOnSpousesConfirm || isLoadingOnSellersConfirm,
                onVerify: isLoadingOnClientsVerify || isLoadingOnSellersVerify || isLoadingOnSpousesVerify,
            },
            isDisabled: {
                onApply: !Object.entries(checkBoxes).every(function (_a) {
                    var _ = _a[0], val = _a[1];
                    return val;
                }) || isLoadingOnNew || isLoadingOnSpousesConfirm || isLoadingOnSellersConfirm,
                onVerify: isLoadingOnClientsVerify || isLoadingOnSellersVerify || isLoadingOnSpousesVerify,
            },
            showElem: {},
            profile: profile,
            step: step,
            steps: steps,
            showSideBarSteps: showSideBarSteps,
            percent: percent,
            progressProps: progressProps,
            step1Props: __assign(__assign({ params: params, changeParam: changeParam, checkBoxes: checkBoxes, setModalData: setModalData, setCheckBoxes: setCheckBoxes }, prepareData[(params === null || params === void 0 ? void 0 : params.role) || (order === null || order === void 0 ? void 0 : order.user_role)]), { setPrevStep: setPrevStep }),
            modalParams: {
                modalData: modalData,
                isDisabled: isDisabledModalFooterByType(modalData === null || modalData === void 0 ? void 0 : modalData.type),
                onAccept: getAcceptFunctionByType(modalData === null || modalData === void 0 ? void 0 : modalData.type),
                modalFooterData: [],
                onModalClose: onModalClose,
                t: t,
            }
        } }, children));
};
