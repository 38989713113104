var parsedStatuses = {
    FINISHED: 'finished',
    IN_PROGRESS: 'in-progress',
    NOT_BEGIN: 'not-begin',
};
export var getStepCircleClassNameByStatus = function (status) {
    return parsedStatuses[status];
};
export var getCircleColorByClassName = function (status) {
    return status === 'FINISHED' ? '#1A1A1A' : 'rgba(255, 255, 255, 0.87)';
};
