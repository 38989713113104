var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from "react";
import "./styles.scss";
import { ESideInner, } from "@/types/components/sidebar";
import { stepsData } from "@/options/sidebar";
import { useNavigate } from "react-router-dom";
import { getTemplate } from "@/helpers/getTemplate";
import AsideStepStatus from "../aside-step-status";
import { HeaderStepStatus, ShowIf, SidebarFooter } from "@/components";
export var getSideInnerByType = function (params, key) {
    var _a;
    var template = (_a = {},
        _a[ESideInner.STEP_ITEM] = (React.createElement(AsideStepStatus, __assign({}, params.props))),
        _a[ESideInner.STEP_ITEM_HEADER] = (React.createElement(HeaderStepStatus, __assign({}, params.props))),
        _a);
    return getTemplate(template, params.type, { key: key });
};
var StepsSidebar = function (_a) {
    var theme = _a.theme, step = _a.step, _b = _a.showFooter, showFooter = _b === void 0 ? true : _b, registrar_choice = _a.registrar_choice;
    var navigate = useNavigate();
    var innerDataMap = useMemo(function () { return stepsData
        .filter(function (step) { var _a, _b; return ((_a = step.props) === null || _a === void 0 ? void 0 : _a.filterType) ? ((_b = step.props) === null || _b === void 0 ? void 0 : _b.filterType) === (registrar_choice || 'REGISTRAR') : true; })
        .reduce(function (acc, cur) {
        var current = cur;
        var convertedStep = parseInt(step);
        var currentStep = parseInt(cur.props.step);
        if (convertedStep < currentStep) {
            current = Object.assign({}, cur, { props: __assign(__assign({}, cur.props), { status: 'NOT_BEGIN' }) });
        }
        else if (convertedStep == currentStep) {
            current = Object.assign({}, cur, { props: __assign(__assign({}, cur.props), { status: 'IN_PROGRESS' }) });
        }
        else if (convertedStep > currentStep) {
            current = Object.assign({}, cur, { props: __assign(__assign({}, cur.props), { status: 'FINISHED' }) });
        }
        return acc.concat(current);
    }, [])
        .map(getSideInnerByType); }, [theme, step, registrar_choice]);
    return (React.createElement("aside", { className: "steps-sidebar" },
        React.createElement("nav", { className: 'steps-sidebar__nav' },
            React.createElement("ul", null, innerDataMap)),
        React.createElement(ShowIf, { condition: showFooter },
            React.createElement(SidebarFooter, null))));
};
export default StepsSidebar;
