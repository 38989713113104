import React from "react";
import './styles.scss';
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
var WARN = require('public/assets/icons/warning.svg');
var NeedResetPass = function () {
    var t = useTranslation().t;
    return React.createElement("div", { className: 'success-reset-pass' },
        React.createElement(SVG, { src: WARN, width: 75, height: 75 }),
        React.createElement("h4", { style: { maxWidth: "335px", textAlign: "center" } }, t('flow.profile.need_reset_pass')),
        React.createElement("h5", { style: { maxWidth: "335px", textAlign: "center" } }, t('flow.profile.need_reset_pass2')));
};
export default NeedResetPass;
