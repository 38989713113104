var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { SubTitle } from "@/components";
import ShowIf from "@/components/other/show-if";
import ShowSwitch from "@/components/other/show-switch";
import "./index.scss";
import PassInput from "./pass-input";
import InputMask from "react-input-mask";
var InputMask2 = InputMask;
var TextInput = function (_a) {
    var placeholder = _a.placeholder, label = _a.label, value = _a.value, onChange = _a.onChange, disabled = _a.disabled, className = _a.className, inputRef = _a.inputRef, mask = _a.mask, onFocus = _a.onFocus, _b = _a.type, type = _b === void 0 ? "text" : _b;
    var handleChange = function (e) {
        onChange(e.target.value);
    };
    function beforeMaskedStateChange(_a) {
        var nextState = _a.nextState;
        var value = nextState.value;
        if (value.includes(" ")) {
            value = value.join('');
        }
        return __assign(__assign({}, nextState), { value: value });
    }
    return (React.createElement("div", { onClick: onFocus, className: "text-input__container ".concat(className) },
        React.createElement(ShowIf, { condition: !!label },
            React.createElement(SubTitle, { text: label, extraClass: 'sub-title__label' })),
        React.createElement(ShowSwitch, { conditions: [type === "password", !!mask] },
            React.createElement(PassInput, { ref: inputRef, value: value, onChange: handleChange, placeholder: placeholder }),
            React.createElement(InputMask2, { inputRef: inputRef, mask: mask, className: "text-input__input", onChange: handleChange, disabled: disabled, placeholder: placeholder, value: value }),
            React.createElement("input", { ref: inputRef, className: "text-input__input", type: type, disabled: disabled, value: value, placeholder: placeholder, onChange: handleChange }))));
};
export default TextInput;
