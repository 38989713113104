import React, { useContext } from "react";
import ModalPortal from "@/components/ui/modal/portal";
import "./styles.scss";
import { ShowIf, ShowList, ShowSwitch } from "@/components";
import { ThemeContext } from "@/containers/theme-container";
import { getThemeClassName } from "@/helpers/getThemeClassName";
import { getModalFooter } from "@/options/modal/modal-footer";
var Modal = function (_a) {
    var children = _a.children, isOpen = _a.isOpen, _b = _a.showOverlay, showOverlay = _b === void 0 ? true : _b, title = _a.title, closeElem = _a.closeElem, footerData = _a.footerData, _c = _a.onClose, onClose = _c === void 0 ? function () {
    } : _c, _d = _a.rootId, rootId = _d === void 0 ? "modal-root" : _d, className = _a.className, options = _a.options, isLoading = _a.isLoading, isDisabled = _a.isDisabled;
    var closeHandler = function () { return onClose(); };
    var theme = useContext(ThemeContext).theme;
    return React.createElement(ModalPortal, { modalRoot: rootId },
        React.createElement(ShowIf, { condition: isOpen },
            React.createElement("div", { className: "ui-modal__container" },
                React.createElement("div", { className: "ui-modal__wrapper ".concat(className || "") },
                    React.createElement(ShowIf, { condition: showOverlay },
                        React.createElement("div", { className: "ui-modal__overlay", onClick: closeHandler })),
                    React.createElement("div", { className: "ui-modal ui-modal_".concat(getThemeClassName(theme)) },
                        React.createElement("div", { className: "ui-modal__header" },
                            React.createElement(ShowIf, { condition: !!title, wrapElem: { elem: React.createElement("h4", null) } },
                                React.createElement(React.Fragment, null, title)),
                            React.createElement("div", { onClick: closeHandler, className: "ui-modal__close" },
                                React.createElement(ShowSwitch, { conditions: [!!closeElem, closeElem === false] },
                                    React.createElement(React.Fragment, null, closeElem),
                                    React.createElement(React.Fragment, null),
                                    React.createElement(React.Fragment, null, "\u00D7")))),
                        React.createElement(ShowIf, { condition: !!children },
                            React.createElement("div", { className: "ui-modal__body" }, children)),
                        React.createElement(ShowIf, { condition: !!(footerData === null || footerData === void 0 ? void 0 : footerData.length), wrapElem: { elem: React.createElement("div", null), className: "ui-modal__footer" } },
                            React.createElement(ShowList, { list: footerData }, getModalFooter((options === null || options === void 0 ? void 0 : options.footerProps) || {}, isLoading || {}, isDisabled || {}))))))));
};
export default Modal;
