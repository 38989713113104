import { getStepsClientNotary, getStepsSellerNotary, getStepsSpouseNotary } from "@/options/steps/notary";
import { getStepsClientRegistrar, getStepsSellerRegistrar, getStepsSpouseRegistrar } from "@/options/steps/registrar";
export var getStepByStatus = function (status, type, registrar_choice) {
    if (['REJECTED'].includes(status))
        return 0.1;
    if (type === 'CLIENT')
        return registrar_choice === 'NOTARY' ? getStepsClientNotary(status) : getStepsClientRegistrar(status);
    else if (type === 'SELLER')
        return registrar_choice === 'NOTARY' ? getStepsSellerNotary(status) : getStepsSellerRegistrar(status);
    else if (type === 'CLIENT_SPOUSE')
        return registrar_choice === 'NOTARY' ? getStepsSpouseNotary(status) : getStepsSpouseRegistrar(status);
    else if (type === 'SELLER_SPOUSE')
        return registrar_choice === 'NOTARY' ? getStepsSpouseNotary(status) : getStepsSpouseRegistrar(status);
    return 0;
};
