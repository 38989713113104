import React, { useContext } from "react";
import "./styles.scss";
import { Search, ShowIf, ShowSwitch } from "@/components";
import { flatObject } from "@/helpers/flatObject";
import { getBodyTemplate, getHeadTemplate } from "@/options/table";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "@/containers/theme-container/";
import { getThemeClassName } from "@/helpers/getThemeClassName";
import { useTranslation } from "react-i18next";
var ChevronClose = require('public/ui/accordion/chevron-close.svg');
var defaultOptions = {
    withSearch: true,
    loading: false,
    isClickable: true,
    navigateTo: function (row) { return ''; },
};
var Table = function (_a) {
    var _b = _a.className, className = _b === void 0 ? "" : _b, _c = _a.headData, headData = _c === void 0 ? [] : _c, _d = _a.tableData, tableData = _d === void 0 ? [] : _d, onSearch = _a.onSearch, onRowClick = _a.onRowClick, onClear = _a.onClear, searchValue = _a.searchValue, searchPlaceholder = _a.searchPlaceholder, sideButton = _a.sideButton, searchClassName = _a.searchClassName, options = _a.options;
    var t = useTranslation().t;
    var theme = useContext(ThemeContext).theme;
    var _e = options
        ? Object.assign(defaultOptions, options)
        : defaultOptions, withSearch = _e.withSearch, loading = _e.loading, isClickable = _e.isClickable, navigateTo = _e.navigateTo;
    var navigate = useNavigate();
    var transformTableData = tableData === null || tableData === void 0 ? void 0 : tableData.map(function (row) { return flatObject(row, options === null || options === void 0 ? void 0 : options.joinOn); });
    var tableHead = (React.createElement("div", { className: "table__head" }, headData === null || headData === void 0 ? void 0 : headData.map(function (column, key) {
        var _a;
        return getHeadTemplate(column, {
            key: key,
            onTableSort: (_a = options === null || options === void 0 ? void 0 : options.onTableSort) !== null && _a !== void 0 ? _a : console.log,
        });
    })));
    var tableBody = transformTableData === null || transformTableData === void 0 ? void 0 : transformTableData.map(function (row, idx) { return (React.createElement("div", { key: idx, onClick: function () { return isClickable && (onRowClick ? onRowClick(row) : navigate(navigateTo(row) || String(row === null || row === void 0 ? void 0 : row.id))); }, className: "table__body ".concat(isClickable ? "pointer" : "", " ").concat((row === null || row === void 0 ? void 0 : row['rowProps.className']) ? row === null || row === void 0 ? void 0 : row['rowProps.className'] : "") }, headData === null || headData === void 0 ? void 0 : headData.map(function (column, key) {
        return getBodyTemplate(row, column, {
            key: key,
            idx: idx,
            t: t,
            onButton: options === null || options === void 0 ? void 0 : options.onButton,
            onDelete: options === null || options === void 0 ? void 0 : options.onDelete,
            onUpdate: options === null || options === void 0 ? void 0 : options.onUpdate,
            isDisabled: options === null || options === void 0 ? void 0 : options.isDisabled,
            isLoading: options === null || options === void 0 ? void 0 : options.isLoading,
        });
    }))); });
    return (React.createElement("div", { className: "table__wrapper ".concat(className, " ").concat(className, "_").concat(getThemeClassName(theme)) },
        React.createElement(ShowIf, { condition: withSearch },
            React.createElement("div", { className: "d-f a-i-c j-c-s-b b-line" },
                React.createElement(Search, { className: searchClassName && searchClassName, onChange: onSearch, value: searchValue, placeholder: searchPlaceholder }),
                sideButton && sideButton,
                React.createElement(ShowIf, { condition: !!onClear },
                    React.createElement("div", { className: 'table__clear', onClick: onClear }, "\u2715")))),
        React.createElement("div", { className: "table" },
            React.createElement("div", { className: "scroll-wrapper" },
                React.createElement("div", { className: "scroll-inner" },
                    tableHead,
                    React.createElement(ShowSwitch, { conditions: [loading, !!(transformTableData === null || transformTableData === void 0 ? void 0 : transformTableData.length)] },
                        React.createElement("div", { className: "table__loading" },
                            React.createElement("div", { className: "table-loader" })),
                        React.createElement(React.Fragment, null, tableBody),
                        React.createElement("div", { className: "table__empty" }, "\u041D\u0435\u0442 \u0434\u0430\u043D\u043D\u044B\u0445")))))));
};
export default Table;
