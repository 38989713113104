import React, { useContext } from "react";
import "./index.scss";
import dayjs from "dayjs";
import SVG from "react-inlinesvg";
import { ThemeContext } from "@/containers/theme-container/";
import { getThemeClassName } from "@/helpers/getThemeClassName";
import { getFormatted } from "@/helpers/getFormatted";
import { useQueryCache } from "@/saga-query/query-hooks/useQueryCache";
import { ShowSwitch } from "@/components";
var Download = require("public/download.svg");
var Loading = require('public/loading.svg');
var SignatureCard = function (_a) {
    var created_at = _a.created_at, name = _a.name, onClick = _a.onClick, id = _a.id;
    var theme = useContext(ThemeContext).theme;
    var get = useQueryCache().get;
    return React.createElement("div", { className: "signature-card signature-card_".concat(getThemeClassName(theme)) },
        React.createElement("div", null,
            React.createElement("span", { className: "signature-card__date" }, getFormatted(dayjs(created_at).format('DD.MM.YYYY HH:mm'))(!!created_at)),
            React.createElement("h5", null, name)),
        React.createElement("button", { onClick: function () { return onClick(id); }, className: "signature-card__download-btn" },
            React.createElement(ShowSwitch, { conditions: [false] },
                React.createElement("div", { className: 'signature-card__btn-loading' },
                    React.createElement(SVG, { src: Loading, fill: "#4F9D3A" })),
                React.createElement(SVG, { src: Download, width: 20, height: 20, fill: "#4F9D3A" }))));
};
export default SignatureCard;
