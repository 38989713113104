import Button from "@/components/ui/button";
import SubTitle from "@/components/ui/sub-title";
import Text from "@/components/ui/text/Text";
import React from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
var ArrowRight = require("public/chunks/steps/arrow-right-white.svg");
var FinalData = function (_a) {
    var monthlyPayment = _a.monthlyPayment, loanDuration = _a.loanDuration, onClick = _a.onClick;
    var t = useTranslation().t;
    return (React.createElement("div", { className: "final-data__wrapper" },
        React.createElement("div", { className: "final-data__conditions" },
            React.createElement("div", { className: "final-data__condition" },
                React.createElement(SubTitle
                // text={`${monthlyPayment} ₸`}
                , { 
                    // text={`${monthlyPayment} ₸`}
                    text: monthlyPayment, style: { marginBottom: "2px", fontSize: "24px" } }),
                React.createElement(Text, { text: t('calc.calc.monthly_payment') })),
            React.createElement("div", { className: "final-data__separator" }),
            React.createElement("div", { className: "final-data__condition" },
                React.createElement(SubTitle, { text: loanDuration.toString(), style: { marginBottom: "2px", fontSize: "24px" } }),
                React.createElement(Text, { text: loanDuration < 5 ? t("steps.age") : t("steps.age2") }))),
        React.createElement(Button, { label: "", className: "icon button_fill", svg_left: ArrowRight, onClick: onClick, style: { width: "50px", height: "50px", borderRadius: "16px" } })));
};
export default FinalData;
