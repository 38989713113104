var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { appMutation, queryDataStateByIdSelector, } from "@/saga-query/ducks/app";
import { useDispatch, useSelector } from "react-redux";
import configs from "@/saga-query/constants";
import { useNotification } from "@/saga-query/query-hooks/useNotification";
import { showErrorHelper } from "@/helpers/showErrorHelper";
export var useMutation = function (options) {
    // VARS
    var refreshAfter = !!(options === null || options === void 0 ? void 0 : options.refreshAfter);
    var baseUrl = (options === null || options === void 0 ? void 0 : options.baseUrl) || configs.SERVER;
    var meta = Object.assign({ authorization: true }, (options === null || options === void 0 ? void 0 : options.meta) || {});
    var type = String(Array.isArray(options.type) ? options.type.join('_') : options.type);
    var typeToUpdate = Array.isArray(options.typeToUpdate) ? options.typeToUpdate.join('_') : options.typeToUpdate;
    var showError = (options === null || options === void 0 ? void 0 : options.showErrorMessage) || false;
    // HOOKS
    var dispatch = useDispatch();
    var selectorData = useSelector(function (state) {
        return queryDataStateByIdSelector(state, type)(state);
    });
    var onError = useNotification().onError;
    var onErrorHandler = function (err) {
        var _a;
        (_a = options === null || options === void 0 ? void 0 : options.onError) === null || _a === void 0 ? void 0 : _a.call(options, err);
        showErrorHelper(onError)(err);
    };
    // FUNCTIONS
    var request = function (data, mutationOptions) {
        if (data === void 0) { data = {}; }
        dispatch(appMutation(__assign({ meta: meta, baseUrl: baseUrl, data: data, refreshAfter: refreshAfter, onError: showError && onErrorHandler }, Object.assign(options, mutationOptions, {
            type: String((mutationOptions === null || mutationOptions === void 0 ? void 0 : mutationOptions.type) ? Array.isArray(mutationOptions.type) ? mutationOptions.type.join('_') : mutationOptions.type : type),
            typeToUpdate: String((mutationOptions === null || mutationOptions === void 0 ? void 0 : mutationOptions.typeToUpdate) ? Array.isArray(mutationOptions.typeToUpdate) ? mutationOptions.typeToUpdate.join('_') : mutationOptions.typeToUpdate : typeToUpdate),
        }))));
    };
    return [request, selectorData];
};
