import React, { useEffect, useRef, useState } from "react";
import './index.scss';
import { getAlertClassNameByType } from "@/helpers/getAlertClassNameByType";
import { useNotification } from "@/saga-query/query-hooks/useNotification";
var Notification = function (_a) {
    var id = _a.id, message = _a.message, _b = _a.title, title = _b === void 0 ? '' : _b, _c = _a.time, time = _c === void 0 ? 5000 : _c, type = _a.type;
    var onRemove = useNotification().onRemove;
    var interval = useRef(null);
    var interval2 = useRef(null);
    var timer = useRef(time / 1000);
    var lineWidth = useRef(100);
    var _d = useState(100), width = _d[0], setWidth = _d[1];
    useEffect(function () {
        startTimer();
        return function () { return stopTimer(); };
    }, []);
    var animationHandler = function () {
        lineWidth.current = lineWidth.current - 1;
        setWidth(lineWidth.current);
        if (lineWidth.current <= 0)
            hideAlert();
    };
    var startTimer = function () {
        interval.current = setInterval(tickHandler, 1000);
        interval2.current = setInterval(animationHandler, (timer.current / 100) * 1000);
    };
    var stopTimer = function () {
        clearInterval(interval.current);
        clearInterval(interval2.current);
    };
    var hideAlert = function () {
        stopTimer();
        onRemove(id);
    };
    var tickHandler = function () {
        timer.current = timer.current - 1;
        if (timer.current <= 0)
            hideAlert();
    };
    return React.createElement("div", { className: 'toastify-card', onMouseEnter: stopTimer, onMouseLeave: startTimer },
        React.createElement("div", { className: 'toastify-card__header' },
            React.createElement("h5", null, title),
            React.createElement("span", { onClick: hideAlert, className: 'toastify-card__close-btn' }, "x")),
        React.createElement("div", { className: "toastify-card__body" },
            React.createElement("span", null, message)),
        React.createElement("div", { className: 'toastify-card__line ' + getAlertClassNameByType(type), style: { width: "".concat(width, "%") } }));
};
export default Notification;
