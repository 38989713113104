var defaultRules = {
    PHONE: function (val) { var _a; return ((_a = val.replace(/[^0-9]/g, "")) === null || _a === void 0 ? void 0 : _a.length) < 11; },
};
export var validateFields = function (data, rulesTypes) {
    if (rulesTypes === void 0) { rulesTypes = defaultRules; }
    return function (fields, rules) {
        var isValid = function (key, val) {
            var _a, _b;
            return !!(rules === null || rules === void 0 ? void 0 : rules[key])
                ? typeof rules[key] === 'string'
                    ? (_a = rulesTypes[rules[key]]) === null || _a === void 0 ? void 0 : _a.call(rulesTypes, val)
                    : (_b = rules[key]) === null || _b === void 0 ? void 0 : _b.call(rules, val)
                : !!val;
        };
        return fields.every(function (field) { return Object.keys(data).includes(field) && isValid(field, data[field]); });
    };
};
