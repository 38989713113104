var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import { ShowList } from "@/components";
var OtpInput = function (_a) {
    var _b = _a.length, length = _b === void 0 ? 4 : _b, onChange = _a.onChange, className = _a.className, value = _a.value, disabled = _a.disabled;
    var _c = useState(Array.from({ length: length }, function () { return ''; })), otp = _c[0], setOtp = _c[1];
    var otp_ref = useRef([]);
    useEffect(function () {
        var _a;
        (_a = otp_ref.current[0]) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    useEffect(function () {
        getNextFocusRef(otp);
        onChange(otp.join(''));
    }, otp);
    useEffect(function () {
        if (value) {
            setOtp(value === null || value === void 0 ? void 0 : value.split('').concat(Array.from({ length: length - value.length }, function () { return ''; })));
        }
    }, [value]);
    var getNextFocusRef = function (otp) {
        otp.forEach(function (cur, idx, arr) {
            var _a, _b;
            if ((arr === null || arr === void 0 ? void 0 : arr.length) - 1 === idx && (cur === null || cur === void 0 ? void 0 : cur.length) > 0)
                return (_a = otp_ref.current[idx]) === null || _a === void 0 ? void 0 : _a.blur();
            if ((cur === null || cur === void 0 ? void 0 : cur.length) > 0)
                (_b = otp_ref.current[idx + 1]) === null || _b === void 0 ? void 0 : _b.focus();
        });
    };
    return React.createElement("div", { className: 'otp-input__container ' + className },
        React.createElement(ShowList, { list: Array.from({ length: length }, function (_, idx) { return idx; }) }, function (key) { return React.createElement("div", { key: key, className: 'otp-input__item' },
            React.createElement("input", { type: 'tel', className: 'otp-input__input', ref: function (instance) { return otp_ref.current[key] = instance; }, value: otp[key], disabled: disabled, onKeyDown: function (e) {
                    if (e.key === "Backspace") {
                        if (key == 0)
                            return;
                        otp_ref.current[key - 1].focus();
                        setOtp(function (prev) {
                            var copy = __spreadArray([], prev, true);
                            copy[key - 1] = '';
                            return copy;
                        });
                    }
                }, onChange: function (e) { return setOtp(function (prev) {
                    var copy = __spreadArray([], prev, true);
                    copy[key] = e.target.value;
                    return copy;
                }); } })); }));
};
export default OtpInput;
