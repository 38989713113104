import React, { useRef } from 'react';
import './index.scss';
import SubTitle from "@/components/ui/sub-title";
import { Button, ShowList } from "@/components";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import { useMutation } from "@/saga-query/query-hooks/useMutation";
import { Api } from "@/types/api";
import { useNotification } from "@/saga-query/query-hooks/useNotification";
import { useQueryCache } from "@/saga-query/query-hooks/useQueryCache";
var Upload = require('public/upload.svg');
var Document = require('public/chunks/steps/document.svg');
export var DocumentCard = function (_a) {
    var name = _a.name, link = _a.link, api = _a.api, uuid = _a.uuid;
    var get = useQueryCache().get;
    var order = get([Api.LOAD_APPLICATION_BY_UUID, uuid]).data;
    var uploadRef = useRef();
    var onSuccess = useNotification().onSuccess;
    var _b = useMutation({
        api: "",
        method: 'POST',
        type: Api.POST_UPLOAD,
        typeToUpdate: [Api.LOAD_APPLICATION_BY_UUID, uuid],
        showErrorMessage: true,
        refreshAfter: true,
        headers: {
            'Content-Type': 'multipart/form-data;'
        },
        onSuccess: function () {
            onSuccess({ message: 'Файл загружен!' });
        }
    }), onUploadFile = _b[0], isLoading = _b[1].isLoading;
    var onUpload = function (e) {
        var file = e.target.files[0];
        onUploadFile({ file: file }, {
            api: api,
            type: [Api.POST_UPLOAD, name],
        });
    };
    return (React.createElement("div", { className: 'document-block__document-card' },
        React.createElement(SVG, { src: Document, width: 20, height: 20 }),
        React.createElement(SubTitle, { text: name, style: { margin: '0 0 0 8px', fontSize: '14px', flex: '1' } }),
        React.createElement("input", { ref: uploadRef, type: 'file', onChange: onUpload, style: {
                width: 0,
                height: 0,
                padding: 0,
                margin: 0,
                position: "absolute",
                left: -9999
            } }),
        React.createElement(Button, { onClick: function () { var _a; return (_a = uploadRef === null || uploadRef === void 0 ? void 0 : uploadRef.current) === null || _a === void 0 ? void 0 : _a.click(); }, label: '', svg_left: Upload, className: 'icon', disabled: isLoading, loading: isLoading })));
};
var DocumentUploadBlock = function (_a) {
    var title = _a.title, docs = _a.docs, uuid = _a.uuid;
    var t = useTranslation().t;
    return (React.createElement("div", { className: 'document-block__wrapper' },
        React.createElement(SubTitle, { extraClass: "sub-title_title", text: t(title), style: { margin: '0' } }),
        React.createElement(ShowList, { list: docs }, function (doc, i) {
            return React.createElement(DocumentCard, { key: i, uuid: uuid, name: t(doc.name), api: doc.api, link: doc.link });
        })));
};
export default DocumentUploadBlock;
