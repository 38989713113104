import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import configs from '@/saga-query/constants';
import reducer from './reducer';
import rootSaga from '../saga';
import { init as initAuth } from '../ducks/auth';
var sagaMiddleware = createSagaMiddleware();
var enhancer = applyMiddleware(sagaMiddleware);
var store = createStore(reducer, ['development', 'common'].includes(configs.MODE) ? composeWithDevTools(enhancer) : enhancer);
sagaMiddleware.run(rootSaga);
initAuth(store);
export default store;
