export var getStepsClientRegistrar = function (status) {
    if (['NEW'].includes(status))
        return 1;
    else if (['CLIENT_OTP_VERIFICATION'].includes(status))
        return 1.1;
    else if (['CLIENT_OTP_VERIFIED', 'CLIENT_BIOMETRY_VERIFICATION'].includes(status))
        return 1.2;
    else if (['CLIENT_BIOMETRY_VERIFIED'].includes(status))
        return 2;
    else if ([
        'CLIENT_FILLING_REAL_ESTATE_DATA',
        'CLIENT_CONFIRMED_REAL_ESTATE_DATA',
        'SELLER_OTP_VERIFICATION',
        'SELLER_OTP_VERIFIED',
        'REAL_ESTATE_GBDRN_VERIFICATION',
        'REAL_ESTATE_GBDRN_VERIFIED',
        'SELLER_ID_CARD_VERIFICATION',
        'SELLER_ID_CARD_VERIFIED',
        'SELLER_SPOUSE_TERMS_OF_DEAL_APPROVED',
        'SELLER_SPOUSE_CONFIRMING_REAL_ESTATE',
        'SELLER_SPOUSE_ID_CARD_VERIFICATION',
        'SELLER_SPOUSE_ID_CARD_VERIFIED',
        'SELLER_SPOUSE_BIOMETRY_VERIFIED',
        'SELLER_SPOUSE_OTP_VERIFIED',
        'SELLER_SPOUSE_OTP_VERIFICATION',
    ].includes(status))
        return 2.1;
    else if ([
        'SELLER_TERMS_OF_DEAL_APPROVED',
        'CLIENT_ID_CARD_VERIFICATION',
        'CLIENT_ID_CARD_VERIFIED',
        'CLIENT_SPOUSE_TERMS_OF_DEAL_APPROVED',
        'CLIENT_SPOUSE_CONFIRMING_REAL_ESTATE',
        'CLIENT_SPOUSE_ID_CARD_VERIFIED',
        'CLIENT_SPOUSE_ID_CARD_VERIFICATION',
        'CLIENT_SPOUSE_BIOMETRY_VERIFIED',
        'CLIENT_SPOUSE_OTP_VERIFIED',
        'CLIENT_SPOUSE_OTP_VERIFICATION',
    ].includes(status))
        return 3;
    else if (['CLIENT_TERMS_OF_DEAL_APPROVED', 'REGISTRAR_CONFIRMATION', 'REGISTRAR_CONFIRMED'].includes(status))
        return 3.1;
    else if (['VIDEO_CALL'].includes(status))
        return 3.2;
    else if (['CLIENT_ID_CARD_VERIFICATION', 'CLIENT_PAYMENT'].includes(status))
        return 4;
    else if (['WAITING_SALE_CONTRACT_SIGN'].includes(status))
        return 5;
    else if (['WAITING_REGISTRAR_SALE_CONTRACT_SIGN'].includes(status))
        return 5.1;
    else if (['COMPLETED'].includes(status))
        return 6;
    else
        return 0;
};
export var getStepsSellerRegistrar = function (status) {
    if (['NEW'].includes(status))
        return 1;
    else if (['CLIENT_OTP_VERIFICATION'].includes(status))
        return 1.1;
    else if (['SELLER_OTP_VERIFIED', 'SELLER_BIOMETRY_VERIFICATION'].includes(status))
        return 1.2;
    else if (['REAL_ESTATE_GBDRN_VERIFICATION', 'REAL_ESTATE_GBDRN_VERIFIED'].includes(status))
        return 2;
    else if ([
        'SELLER_ID_CARD_VERIFICATION',
        'SELLER_ID_CARD_VERIFIED',
        'SELLER_SPOUSE_TERMS_OF_DEAL_APPROVED',
        'SELLER_SPOUSE_CONFIRMING_REAL_ESTATE',
        'SELLER_SPOUSE_ID_CARD_VERIFIED',
        'SELLER_SPOUSE_ID_CARD_VERIFICATION',
        'SELLER_SPOUSE_BIOMETRY_VERIFIED',
        'SELLER_SPOUSE_OTP_VERIFIED',
        'SELLER_SPOUSE_OTP_VERIFICATION',
        'SELLER_TERMS_OF_DEAL_APPROVED',
    ].includes(status))
        return 2.1;
    else if (['REGISTRAR_CONFIRMED', 'VIDEO_CALL'].includes(status))
        return 3;
    else if (['WAITING_SALE_CONTRACT_SIGN'].includes(status))
        return 5;
    else if (['WAITING_REGISTRAR_SALE_CONTRACT_SIGN'].includes(status))
        return 5.1;
    else if (['COMPLETED'].includes(status))
        return 6;
    else
        return 0;
};
export var getStepsSpouseRegistrar = function (status) {
    if (['NEW'].includes(status))
        return 1;
    else if (['CLIENT_OTP_VERIFICATION'].includes(status))
        return 1.1;
    else if ([
        'SELLER_SPOUSE_OTP_VERIFIED',
        'CLIENT_SPOUSE_OTP_VERIFIED',
        'CLIENT_SPOUSE_BIOMETRY_VERIFICATION',
        'CLIENT_SPOUSE_BIOMETRY_VERIFICATION',
    ].includes(status))
        return 1.2;
    else if ([
        'CLIENT_SPOUSE_BIOMETRY_VERIFIED',
        'CLIENT_SPOUSE_ID_CARD_VERIFICATION',
        'CLIENT_SPOUSE_ID_CARD_VERIFIED',
        'CLIENT_SPOUSE_TERMS_OF_DEAL_APPROVED',
        'CLIENT_SPOUSE_CONFIRMING_REAL_ESTATE',
        'SELLER_SPOUSE_BIOMETRY_VERIFIED',
        'SELLER_SPOUSE_ID_CARD_VERIFICATION',
        'SELLER_SPOUSE_ID_CARD_VERIFIED',
        'SELLER_SPOUSE_TERMS_OF_DEAL_APPROVED',
        'SELLER_SPOUSE_CONFIRMING_REAL_ESTATE',
        'SELLER_TERMS_OF_DEAL_APPROVED'
    ].includes(status))
        return 2;
    else if (['REGISTRAR_CONFIRMED', 'VIDEO_CALL'].includes(status))
        return 3;
    else if (['WAITING_SALE_CONTRACT_SIGN'].includes(status))
        return 5;
    else if (['WAITING_REGISTRAR_SALE_CONTRACT_SIGN'].includes(status))
        return 5.1;
    else if (['COMPLETED'].includes(status))
        return 6;
    else
        return 0;
};
export var clientStepsRegistrar = [
    { step: 0, component: 'DEFAULT' },
    { step: 0.1, component: 'RejectedStep' },
    { step: 1, component: 'Step1' },
    { step: 1.1, component: 'StepOtp' },
    { step: 1.2, component: 'Step2' },
    { step: 2, component: 'FillRealEstate' },
    { step: 2.1, component: 'FillRealEstate' },
    { step: 3, component: 'DDocs' },
    { step: 3.1, component: 'Meeting' },
    { step: 3.2, component: 'MeetCompleted' },
    { step: 4, component: 'PayCommission' },
    { step: 5, component: 'DocumentSign' },
    { step: 5.1, component: 'DocumentSign' },
    { step: 6, component: 'Step6' },
];
export var sellerStepsRegistrar = [
    { step: 0, component: 'DEFAULT' },
    { step: 0.1, component: 'RejectedStep' },
    { step: 1, component: 'Step1Seller' },
    { step: 1.1, component: 'StepOtp' },
    { step: 1.2, component: 'Step2' },
    { step: 2, component: 'DDocsSeller' },
    { step: 2.1, component: 'DDocsSeller' },
    { step: 3, component: 'BeforeMeetSeller' },
    { step: 5, component: 'DocumentSign' },
    { step: 5.1, component: 'DocumentSign' },
    { step: 6, component: 'Step6' },
];
export var spouseStepsRegistrar = [
    { step: 0, component: 'DEFAULT' },
    { step: 0.1, component: 'RejectedStep' },
    { step: 1, component: 'Step1Spouse' },
    { step: 1.1, component: 'StepOtp' },
    { step: 1.2, component: 'Step2' },
    { step: 2, component: 'DDocsSpouse' },
    { step: 3, component: 'BeforeMeetSpouse' },
    { step: 5, component: 'DocumentSign' },
    { step: 5.1, component: 'DocumentSign' },
    { step: 6, component: 'Step6' },
];
