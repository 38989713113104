var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useRef, useState } from "react";
import { appQuery, appQueryClear, queryDataStateByIdSelector } from "@/saga-query/ducks/app";
import configs from '@/saga-query/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useNotification } from "@/saga-query/query-hooks/useNotification";
import { showErrorHelper } from "@/helpers/showErrorHelper";
export var useQuery = function (options) {
    // VARS
    var _a = options.immediately, immediately = _a === void 0 ? true : _a;
    var baseUrl = (options === null || options === void 0 ? void 0 : options.baseUrl) || configs.SERVER;
    var showError = (options === null || options === void 0 ? void 0 : options.showErrorMessage) || false;
    var autoRetry = (options === null || options === void 0 ? void 0 : options.autoRetry) || 3;
    var meta = Object.assign({ authorization: true }, (options === null || options === void 0 ? void 0 : options.meta) || {});
    var type = String(Array.isArray(options.type) ? options.type.join('_') : options.type);
    // HOOKS
    var timeoutRef = useRef(null);
    var cacheRef = useRef(null);
    var retryRef = useRef(autoRetry);
    var cacheClearedRef = useRef(false);
    var dispatch = useDispatch();
    var _b = useState(false), update = _b[0], setUpdate = _b[1];
    var selectorData = useSelector(function (state) { return queryDataStateByIdSelector(state, type)(state); });
    var onError = useNotification().onError;
    // FUNCTIONS
    var refetch = function () {
        setUpdate(true);
        cacheClearedRef.current = false;
    };
    var onErrorHandler = function (err) {
        var _a;
        (_a = options === null || options === void 0 ? void 0 : options.onError) === null || _a === void 0 ? void 0 : _a.call(options, err);
        showErrorHelper(onError);
    };
    var refetchIfClear = function () { return typeof (selectorData === null || selectorData === void 0 ? void 0 : selectorData.isLoaded) === 'undefined' && refetch(); };
    var onRetryDelay = function (delay) { return timeoutRef.current = setTimeout(refetch, delay); };
    var onClearCache = function (delay) { return cacheRef.current = setTimeout(function () {
        dispatch(appQueryClear(type));
        cacheClearedRef.current = true;
    }, delay); };
    var refetchWithParams = function (params, newOptions) {
        var newType = String(Array.isArray(newOptions === null || newOptions === void 0 ? void 0 : newOptions.type) ? newOptions === null || newOptions === void 0 ? void 0 : newOptions.type.join('_') : newOptions === null || newOptions === void 0 ? void 0 : newOptions.type);
        dispatch(appQuery(__assign(__assign(__assign(__assign({ data: params, meta: meta, method: 'GET', baseUrl: baseUrl }, options), { onError: showError && onErrorHandler }), (newOptions || {})), { type: newType !== 'undefined' ? newType : type })));
        cacheClearedRef.current = false;
    };
    // LOGIC
    useEffect(function () {
        if (typeof (selectorData === null || selectorData === void 0 ? void 0 : selectorData.isLoaded) !== 'undefined' && (options === null || options === void 0 ? void 0 : options.forceUpdate) && type) {
            setUpdate(true);
        }
    }, [type]);
    useEffect(function () {
        if ((typeof (selectorData === null || selectorData === void 0 ? void 0 : selectorData.isLoaded) === 'undefined' && (immediately || update)) && !cacheClearedRef.current) {
            dispatch(appQuery(__assign(__assign({ meta: meta, method: 'GET', baseUrl: baseUrl, onError: showError && onErrorHandler }, options), { type: type })));
            setUpdate(false);
            cacheClearedRef.current = false;
        }
        else if (typeof (selectorData === null || selectorData === void 0 ? void 0 : selectorData.isLoaded) !== 'undefined' && update && !cacheClearedRef.current) {
            dispatch(appQuery(__assign(__assign({ meta: meta, method: 'GET', baseUrl: baseUrl, onError: showError && onErrorHandler }, options), { type: type })));
            setUpdate(false);
            cacheClearedRef.current = false;
        }
        if (typeof (selectorData === null || selectorData === void 0 ? void 0 : selectorData.isLoaded) !== 'undefined' && !options.cacheTime && (options === null || options === void 0 ? void 0 : options.refetchInterval) > 1) {
            if (timeoutRef.current)
                clearTimeout(timeoutRef.current);
            onRetryDelay(options.refetchInterval);
        }
        else if (options.cacheTime && (options === null || options === void 0 ? void 0 : options.refetchInterval) > 1) {
            if (cacheClearedRef.current) {
                if (timeoutRef.current)
                    clearTimeout(timeoutRef.current);
                onRetryDelay(options.refetchInterval);
            }
        }
        if (typeof (selectorData === null || selectorData === void 0 ? void 0 : selectorData.isLoaded) !== 'undefined' && options.cacheTime) {
            if (cacheRef.current)
                clearTimeout(cacheRef.current);
            onClearCache(options.cacheTime);
        }
        return function () {
            clearTimeout(timeoutRef.current);
            clearTimeout(cacheRef.current);
        };
    }, [update, selectorData === null || selectorData === void 0 ? void 0 : selectorData.data, cacheClearedRef.current]);
    return __assign(__assign({}, selectorData), { refetch: refetch, refetchWithParams: refetchWithParams, refetchIfClear: refetchIfClear });
};
