var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { createContext, useCallback, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from "@/saga-query/query-hooks/useQuery";
import { useMutation } from "@/saga-query/query-hooks/useMutation";
import { useNotification } from "@/saga-query/query-hooks/useNotification";
import { usePermissions } from "@/saga-query/query-hooks/usePermissions";
import { useAuthorization } from "@/saga-query/query-hooks/useAuthorization";
import { RegistrarApi } from "@/types/api";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useNCALayer } from "@/hooks/useNCALayer";
import { modalFooterData } from "./data";
import SVG from "react-inlinesvg";
import { Tooltip } from "react-tooltip";
var WarningSrc = require('public/chunks/steps/exclamation-circle.svg');
export var SingleNotaryPageContext = createContext({});
export var SingleNotaryPageContextProvider = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var children = _a.children, options = _a.options;
    var signFileRef = useRef(null);
    var uuid = useParams().uuid;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var hasAccess = usePermissions().hasAccess;
    var _h = useNotification(), onSuccess = _h.onSuccess, onWarn = _h.onWarn, onError = _h.onError;
    var user = useAuthorization().user;
    var _j = useState(''), text = _j[0], setText = _j[1];
    var _k = useState(''), changeDate = _k[0], setChangeDate = _k[1];
    var _l = useState(''), message = _l[0], setMessage = _l[1];
    var _m = useState(null), selectTime = _m[0], setSelectTime = _m[1];
    var _o = useState(false), isEditable = _o[0], setIsEditable = _o[1];
    var _p = useState(null), dkpData = _p[0], setDkpData = _p[1];
    var _q = useState({
        type: '',
        isOpen: false,
        title: '',
        description: '',
    }), modalData = _q[0], setModalData = _q[1];
    var _r = useQuery({
        api: "/notary/applications/".concat(uuid, "/"),
        type: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        forceUpdate: true,
        refetchInterval: 120000,
        showErrorMessage: true
    }), data = _r.data, isLoading = _r.isLoading, refetch = _r.refetch;
    var _s = useQuery({
        api: "/notary/applications/".concat(uuid, "/enis-code/"),
        type: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        immediately: false,
        onSuccess: function () { return refetch(); },
        showErrorMessage: true
    }), isLoadingOnEnisCode = _s.isLoading, onEnisCode = _s.refetch;
    var _t = useQuery({
        api: "/print-forms/print/dkp_body/".concat(uuid),
        type: RegistrarApi.LOAD_DKP_BODY,
        headers: {
            'Content-Type': 'text/html; charset=utf-8'
        },
        immediately: false,
        onSuccess: function (data) {
            setDkpData(data);
            setModalData(function (prev) { return (__assign(__assign({}, prev), { isOpen: true, type: 'DKP_DOC_NOTARY', title: React.createElement("div", { className: "d-f a-i-c gg-10" },
                    React.createElement("span", null, "\u0414\u043E\u043A\u0443\u043C\u0435\u043D\u0442"),
                    React.createElement(SVG, { src: WarningSrc, width: 20, height: 20, "data-tooltip-id": "warn-tooltip" }),
                    React.createElement(Tooltip, { id: "warn-tooltip", className: 'warn-tooltip', style: { zIndex: 9999999999, fontSize: 12 }, place: 'bottom', wrapper: 'div' }, t('Редактирование документа возможно только во время видеозвонка'))), data: data })); });
        },
        onError: function () {
            onError({ message: 'Ошибка скачивания шаблона ДКП' });
        },
        showErrorMessage: true
    }), isLoadingOnDkpBody = _t.isLoading, onDkpBody = _t.refetch;
    var _u = useQuery({
        api: "/notary/applications/".concat(uuid, "/get-balance/"),
        type: RegistrarApi.LOAD_GET_BALANCE,
        immediately: false,
        onSuccess: function (res) {
            refetch();
            if (res.balance)
                onSuccess({ message: t('registrar.escrow_replenished') });
            else
                onWarn({ message: t('registrar.escrow_not_replenished') });
        },
        onError: function () {
            refetch();
            onWarn({ message: t('registrar.escrow_not_replenished') });
        },
        showErrorMessage: true
    }), isLoadingOnGetBalance = _u.isLoading, onGetBalance = _u.refetch;
    var _v = useMutation({
        api: "/notary/applications/".concat(uuid, "/confirm/"),
        method: 'POST',
        type: RegistrarApi.POST_CONFIRM,
        typeToUpdate: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        showErrorMessage: true,
        // onSuccess: () => onSuccess({title: 'Смена статуса', message: 'Статус изменен на: "Подтверждено"'})
    }), onConfirm = _v[0], isLoadingOnApprove = _v[1].isLoading;
    var _w = useMutation({
        api: "/notary/applications/".concat(uuid, "/dkp-registration/"),
        method: 'POST',
        type: RegistrarApi.POST_CONFIRM,
        typeToUpdate: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        showErrorMessage: true,
        // onSuccess: () => onSuccess({title: 'Смена статуса', message: 'Статус изменен на: "Подтверждено"'})
    }), onDKPRegistration = _w[0], isLoadingOnDkdRegistration = _w[1].isLoading;
    var _x = useMutation({
        api: "/notary/applications/".concat(uuid, "/clients-sale-contract-sign/"),
        method: 'POST',
        type: RegistrarApi.POST_CONFIRM,
        typeToUpdate: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        showErrorMessage: true,
        // onSuccess: () => onSuccess({title: 'Смена статуса', message: 'Статус изменен на: "Подтверждено"'})
    }), onClientsSaleContractSign = _x[0], isLoadingOnClientsSaleContractSign = _x[1].isLoading;
    var _y = useMutation({
        api: "/notary/applications/".concat(uuid, "/waiting-escrow/"),
        method: 'POST',
        type: RegistrarApi.POST_WAITING_ESCROW,
        typeToUpdate: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        showErrorMessage: true,
        // onSuccess: () => onSuccess({title: 'Смена статуса', message: 'Статус изменен на: "Подтверждено"'})
    }), onWaitingEscrow = _y[0], isLoadingOnWaitingEscrow = _y[1].isLoading;
    var _z = useMutation({
        api: "/notary/applications/".concat(uuid, "/sign-dkp/"),
        method: 'POST',
        data: { redirect_url: (_b = window === null || window === void 0 ? void 0 : window.location) === null || _b === void 0 ? void 0 : _b.href },
        type: RegistrarApi.POST_CONFIRM,
        typeToUpdate: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        showErrorMessage: true,
        onSuccess: function (data) {
            var _a;
            if (data === null || data === void 0 ? void 0 : data.url)
                (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.replace(data === null || data === void 0 ? void 0 : data.url);
        }
    }), onSignDKP = _z[0], isLoadingOnSignDkp = _z[1].isLoading;
    var _0 = useMutation({
        api: "/notary/applications/".concat(uuid, "/upload-document/"),
        method: 'POST',
        type: RegistrarApi.POST_UPLOAD_DOCUMENT,
        typeToUpdate: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        showErrorMessage: true,
        headers: {
            'Content-Type': 'multipart/form-data;'
        },
        onSuccess: function () {
            onSuccess({ message: 'Документ загружен!' });
        }
    }), onUploadDocument = _0[0], isLoadingOnUploadDocument = _0[1].isLoading;
    var _1 = useMutation({
        api: "/notary/applications/".concat(uuid, "/reject/"),
        method: 'POST',
        data: { redirect_url: (_c = window === null || window === void 0 ? void 0 : window.location) === null || _c === void 0 ? void 0 : _c.href },
        type: RegistrarApi.POST_CONFIRM,
        typeToUpdate: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        showErrorMessage: true,
        onSuccess: function (res) {
            navigate('/cabinet/notary-deals');
        }
    }), onReject = _1[0], isLoadingOnReject = _1[1].isLoading;
    var _2 = useMutation({
        api: "/notary/applications/".concat(uuid, "/registry-registration/"),
        method: 'POST',
        type: RegistrarApi.POST_CONFIRM,
        typeToUpdate: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        showErrorMessage: true,
    }), onRegistryRegistration = _2[0], isLoadingOnRegistryRegistration = _2[1].isLoading;
    var _3 = useMutation({
        api: "/notary/applications/".concat(uuid, "/new-meet-date/ "),
        method: 'PATCH',
        data: { meet_date: dayjs(changeDate).format('YYYY-MM-DDT') + selectTime },
        type: RegistrarApi.POST_NEW_MEET_DATE,
        typeToUpdate: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        showErrorMessage: true
    }), onNewMeetDate = _3[0], isLoadingOnNewMeetDate = _3[1].isLoading;
    var _4 = useMutation({
        api: "/notary/applications/submit-request/",
        method: 'POST',
        data: { message: message },
        type: RegistrarApi.PUT_SUBMIT_REQUEST,
        typeToUpdate: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        showErrorMessage: true
    }), onSubmitRequest = _4[0], isLoadingOnSubmitRequest = _4[1].isLoading;
    var _5 = useMutation({
        api: "/applications/".concat(uuid, "/update_dkp/"),
        method: 'PATCH',
        // headers: {'Content-Type': 'multipart/form-data;'},
        type: RegistrarApi.PUT_SUBMIT_REQUEST,
        typeToUpdate: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        onSuccess: function () { return onSuccess({ message: 'Документ сохранен!' }); },
        showErrorMessage: true
    }), onUploadDKP = _5[0], isLoadingOnUploadDKP = _5[1].isLoading;
    var _6 = useMutation({
        api: "/notary/applications/".concat(uuid, "/send-dkp-to-enis/"),
        method: 'POST',
        // headers: {'Content-Type': 'multipart/form-data;'},
        type: RegistrarApi.PUT_SUBMIT_REQUEST,
        typeToUpdate: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        showErrorMessage: true
    }), onSendDkpToEnis = _6[0], isLoadingOnSendDkpToEnis = _6[1].isLoading;
    var _7 = useMutation({
        api: "/notary/applications/".concat(uuid, "/sign-cms/"),
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data;' },
        type: RegistrarApi.PUT_SUBMIT_REQUEST,
        typeToUpdate: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        showErrorMessage: true
    }), onSignCms = _7[0], isLoadingOnSignCms = _7[1].isLoading;
    var _8 = useMutation({
        api: "/notary/applications/".concat(uuid, "/video-call/"),
        method: 'POST',
        type: RegistrarApi.POST_VIDEO_CALL,
        typeToUpdate: RegistrarApi.LOAD_APPLICATION_BY_UUID,
        showErrorMessage: true,
        onSuccess: function () { var _a; return window.open((_a = data === null || data === void 0 ? void 0 : data.meeting) === null || _a === void 0 ? void 0 : _a.admin_token, '_blank'); }
    }), onVideoCall = _8[0], isLoadingOnVideoCall = _8[1].isLoading;
    var onTextChange = function (val) { return setText(val); };
    var transformDocumentsData = function (documents) {
        return documents.reduce(function (acc, cur) {
            var copy = acc;
            copy[cur.type] = cur.file;
            return copy;
        }, {});
    };
    var documentsData = transformDocumentsData((data === null || data === void 0 ? void 0 : data.documents) || []);
    var getDocumentsUpload = function (application) {
        var _a;
        if (((_a = application === null || application === void 0 ? void 0 : application.documents) === null || _a === void 0 ? void 0 : _a.length) < 0)
            return [];
        var docsList = [
            {
                name: t('УДЛ покупателя'),
                type: 'ID_CARD',
                onUpload: function (type_id) { return function (file) { return onUploadDocument({ file: file, type_id: type_id }); }; }
            },
            {
                name: t('ДКП онлайн'),
                type: 'DKP',
                onUpload: function (type_id) { return function (file) { return onUploadDocument({ file: file, type_id: type_id }); }; }
            },
            {
                name: t('ДКП продавца'),
                type: 'SELLER_DKP',
                onUpload: function (type_id) { return function (file) { return onUploadDocument({ file: file, type_id: type_id }); }; }
            },
            {
                name: t('Согласие супруга(и) продаца'),
                type: 'SELLER_SPOUSE_CONSENT',
                onUpload: function (type_id) { return function (file) { return onUploadDocument({ file: file, type_id: type_id }); }; }
            },
            {
                name: t('Согласие супруга(и) покупателя'),
                type: 'CLIENT_SPOUSE_CONSENT',
                onUpload: function (type_id) { return function (file) { return onUploadDocument({ file: file, type_id: type_id }); }; }
            },
            {
                name: t('Тех. паспорт'),
                type: 'TECH_PASSPORT',
                onUpload: function (type_id) { return function (file) { return onUploadDocument({ file: file, type_id: type_id }); }; }
            },
            {
                name: t('Уведомление о перерегистрации'),
                type: 'NOTICE_OF_REGISTRATION',
                onUpload: function (type_id) { return function (file) { return onUploadDocument({ file: file, type_id: type_id }); }; }
            },
        ];
        var docs = docsList === null || docsList === void 0 ? void 0 : docsList.reduce(function (acc, doc) {
            var _a;
            var curDoc = (_a = application === null || application === void 0 ? void 0 : application.documents) === null || _a === void 0 ? void 0 : _a.find(function (el) { return (el === null || el === void 0 ? void 0 : el.type) == (doc === null || doc === void 0 ? void 0 : doc.type); });
            var newData = Object.assign({}, doc, { link: (curDoc === null || curDoc === void 0 ? void 0 : curDoc.file) || null, onUpload: doc.onUpload(doc.type) });
            return acc.concat(newData);
        }, []);
        return [{ docs: docs }];
    };
    var documentsUploadList = getDocumentsUpload(data);
    console.log('documentsUploadList', documentsUploadList);
    var isDisabledModalFooterByType = function (type) {
        var ACCEPT = false;
        switch (type) {
            case 'DECLINE':
                ACCEPT = !text;
                break;
            case 'CONFIRMATION_REJECT':
                ACCEPT = false;
                break;
        }
        return { ACCEPT: ACCEPT };
    };
    var onChangeSchedules = function () {
        setModalData(function (prev) { return (__assign(__assign({}, prev), { isOpen: true, type: 'CHANGE_SCHEDULES', title: t('registrar.date_time') })); });
    };
    var onDownload = function (url) {
        if (!url)
            return;
        var link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    var onUploadDKPHandler = function () {
        onUploadDKP({ html_data: dkpData });
    };
    var onModalClose = function () {
        setModalData(__assign(__assign({}, modalData), { isOpen: false, className: '' }));
    };
    var onCopyToClipboard = function (text) {
        if (!text)
            return onError({ title: 'Буфер обмена', message: 'Пустое значение' });
        navigator.clipboard.writeText(text)
            .then(function () { return onWarn({ title: 'Буфер обмена', message: "\"".concat(text, "\" c\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u043E!") }); })
            .catch(function (err) { return onError(err); });
    };
    var onSignDKPHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        var docUrl, res, fileData_1, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    docUrl = documentsData === null || documentsData === void 0 ? void 0 : documentsData['DKP'];
                    if (!docUrl)
                        return [2 /*return*/, onError({ message: 'Документ не найден' })];
                    return [4 /*yield*/, fetch(docUrl)];
                case 1:
                    res = _a.sent();
                    return [4 /*yield*/, res.blob()];
                case 2:
                    fileData_1 = _a.sent();
                    signFileRef.current = fileData_1;
                    init(function () { return onSignXml(fileData_1); });
                    return [3 /*break*/, 5];
                case 3:
                    err_1 = _a.sent();
                    console.log('err', err_1);
                    return [3 /*break*/, 5];
                case 4: return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var onDkpDownload = function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, onDkpBody()];
    }); }); };
    var onSignXmlBack = function (data) {
        var file = new File([data.blob], 'dkp.pdf');
        onSignCms({
            file: file,
            registrar_ecp_xml_data: data.xml,
            ecp_hash: data.hash,
            // iin: data.iin,
            type_id: 'DKP',
            registrar_ecp_signed: new Date().toJSON()
        });
    };
    console.log("printFormDKP", { data: data, dkpData: dkpData });
    var onRejectApplication = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // onReject()
            setModalData(function (prev) { return (__assign(__assign({}, prev), { isOpen: true, title: 'Отмена заявки', className: 'reject-modal', type: 'CONFIRMATION_REJECT', description: 'Вы уверены что хотите отменить заявку?' })); });
            return [2 /*return*/];
        });
    }); };
    var onCheckSignatureModal = function (userType, hash) {
        setModalData(function (prev) { return (__assign(__assign({}, prev), { isOpen: true, type: 'SIGNATURE', title: "".concat(t('steps2.signature'), " ").concat(userType), hash: hash })); });
    };
    var onUploadDocumentsModal = function () {
        setModalData(function (prev) { return (__assign(__assign({}, prev), { isOpen: true, type: 'UPLOAD_DOCUMENTS', title: "".concat(t('Загрузка документов')) })); });
    };
    var onBeginMeetModal = function (userType, hash) {
        setModalData(function (prev) { return (__assign(__assign({}, prev), { isOpen: true, type: 'BEGIN_MEET', title: t('registrar.begin_meet') })); });
    };
    var onSendMessage = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setModalData(function (prev) { return (__assign(__assign({}, prev), { isOpen: true, type: 'MESSAGE', title: 'Техподдержка' })); });
            return [2 /*return*/];
        });
    }); };
    var getAcceptFunctionByType = useCallback(function (type) {
        var fnData = {
            'CONFIRMATION_REJECT': function () {
                onReject();
                onModalClose();
            },
        };
        return fnData[type] || console.log;
    }, []);
    var sellerDocs = [
        { link: (_e = (_d = data === null || data === void 0 ? void 0 : data.seller) === null || _d === void 0 ? void 0 : _d.ddocs) === null || _e === void 0 ? void 0 : _e.id_card, name: t('steps2.udl') },
        { link: documentsData.SELLER_DKP, name: t('ДКП') },
        { link: documentsData.TECH_PASSPORT, name: t('Тех. паспорт') },
        { link: documentsData.SELLER_SPOUSE_CONSENT, name: t('Согласие') },
        { link: documentsData.GUARANTEE_LETTER, name: t('Гарантийное письмо') }
    ];
    var clientDocs = [
        { link: (_g = (_f = data === null || data === void 0 ? void 0 : data.client) === null || _f === void 0 ? void 0 : _f.ddocs) === null || _g === void 0 ? void 0 : _g.id_card, name: t('steps2.udl') },
        { link: documentsData.CLIENT_SPOUSE_CONSENT, name: t('Согласие') },
        // {link: documentsData.CLIENT_DKP, name: t('ДКП')},
    ];
    var _9 = useNCALayer({
        onSignXmlBack: onSignXmlBack,
    }), init = _9.init, onSignXml = _9.onSignXml;
    var isMeetEnded = dayjs(data === null || data === void 0 ? void 0 : data.meet_date).diff(dayjs().add(1, 'minutes'));
    var isMeetBegin = dayjs(data === null || data === void 0 ? void 0 : data.meet_date).diff(dayjs().add(1, 'minutes'));
    return React.createElement(SingleNotaryPageContext.Provider, { value: {
            id: data === null || data === void 0 ? void 0 : data.id,
            uuid: data === null || data === void 0 ? void 0 : data.uuid,
            isLoading: {
                single: isLoading,
                dkpRegistration: isLoadingOnDkdRegistration,
                dkp: isLoadingOnSignDkp,
                uploadDKP: isLoadingOnUploadDKP,
                isLoadingOnWaitingEscrow: isLoadingOnWaitingEscrow,
                isLoadingOnVideoCall: isLoadingOnVideoCall,
                isLoadingOnSendDkpToEnis: isLoadingOnSendDkpToEnis,
                isLoadingOnGetBalance: isLoadingOnGetBalance,
                isLoadingOnEnisCode: isLoadingOnEnisCode,
                isLoadingOnDkpBody: isLoadingOnDkpBody,
                isLoadingOnRegistryRegistration: isLoadingOnRegistryRegistration,
                isLoadingOnClientsSaleContractSign: isLoadingOnClientsSaleContractSign,
                isLoadingOnUploadDocument: isLoadingOnUploadDocument,
            },
            isDisabled: {
                single: isLoading,
                dkpRegistration: isLoadingOnDkdRegistration,
                dkp: isLoadingOnSignDkp,
                uploadDKP: isLoadingOnUploadDKP,
                isLoadingOnWaitingEscrow: isLoadingOnWaitingEscrow,
                isLoadingOnSendDkpToEnis: isLoadingOnSendDkpToEnis,
                isLoadingOnGetBalance: isLoadingOnGetBalance,
                isLoadingOnVideoCall: isLoadingOnVideoCall,
                isLoadingOnDkpBody: isLoadingOnDkpBody,
                isLoadingOnEnisCode: isLoadingOnEnisCode,
                isLoadingOnRegistryRegistration: isLoadingOnRegistryRegistration,
                isLoadingOnClientsSaleContractSign: isLoadingOnClientsSaleContractSign,
                isLoadingOnUploadDocument: isLoadingOnUploadDocument || ['REJECTED', 'COMPLETED'].includes(data === null || data === void 0 ? void 0 : data.status),
            },
            status: data === null || data === void 0 ? void 0 : data.status,
            isMeetEnded: isMeetEnded,
            documentsData: documentsData,
            clientDocs: clientDocs,
            sellerDocs: sellerDocs,
            isMeetBegin: isMeetBegin,
            onCopyToClipboard: onCopyToClipboard,
            onUploadDocumentsModal: onUploadDocumentsModal,
            documentsUploadList: documentsUploadList,
            onConfirmApplication: function () { return onConfirm(); },
            onRegistryRegistration: function () { return onRegistryRegistration(); },
            onDkpRegistration: function () { return onDKPRegistration(); },
            onDkpSign: function () { return onSignDKPHandler(); },
            onClientsSaleContractSign: function () { return onClientsSaleContractSign(); },
            onGetBalance: function () { return onGetBalance(); },
            onDkpDownload: onDkpDownload,
            onBeginMeetModal: onBeginMeetModal,
            onSendDkpToEnis: function () { return onSendDkpToEnis(); },
            onChangeSchedules: onChangeSchedules,
            onCheckSignatureModal: onCheckSignatureModal,
            onDownload: onDownload,
            onWaitingEscrow: function () { return onWaitingEscrow(); },
            onEnisCode: function () { return onEnisCode(); },
            onSendMessage: onSendMessage,
            onRejectApplication: onRejectApplication,
            application: data || {},
            modalParams: {
                modalData: modalData,
                text: text,
                changeDate: changeDate,
                setChangeDate: setChangeDate,
                message: message,
                setMessage: setMessage,
                isEditable: isEditable,
                dkpData: dkpData,
                setDkpData: setDkpData,
                onUploadDKP: onUploadDKPHandler,
                setIsEditable: setIsEditable,
                onTextChange: onTextChange,
                onVideoCall: onVideoCall,
                onSendMessageHandler: function () { return onSubmitRequest(); },
                onConfirmNewMeetDate: function () { return onNewMeetDate(); },
                selectTime: selectTime,
                setSelectTime: setSelectTime,
                isDisabled: isDisabledModalFooterByType(modalData === null || modalData === void 0 ? void 0 : modalData.type),
                onAccept: getAcceptFunctionByType(modalData === null || modalData === void 0 ? void 0 : modalData.type),
                onDecline: onModalClose,
                modalFooterData: modalFooterData === null || modalFooterData === void 0 ? void 0 : modalFooterData[modalData === null || modalData === void 0 ? void 0 : modalData.type],
                onModalClose: onModalClose,
            }
        } }, children);
};
