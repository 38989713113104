var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthorization } from "@/saga-query/query-hooks/useAuthorization";
import { useNotification } from "@/saga-query/query-hooks/useNotification";
import { validateFields } from "@/helpers/validateFields";
import { verifySuccess } from "@/saga-query/ducks/auth";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { generateTotpQrUrlHelper } from "@/helpers/generateTotpUrl";
import { useQuery } from "@/saga-query/query-hooks/useQuery";
import { Api } from "@/types/api";
import { useMutation } from "@/saga-query/query-hooks/useMutation";
import { useSearchParams } from "@/hooks/useQueryParams/useQueryParams";
export var LoginPageContext = createContext({});
var defaultValue = {
    params: { phone: '', password: '' }
};
var navList = [
    { id: 'GET_NUMBER', name: 'flow.profile.login_sms' },
    { id: 'LOGIN', name: 'flow.profile.login_pass' }
];
export var LoginPageContextProvider = function (_a) {
    var children = _a.children;
    var navigate = useNavigate();
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var _b = useNotification(), onSuccess = _b.onSuccess, onWarn = _b.onWarn, onError = _b.onError;
    var _c = useState(navList[0].id), selectedTab = _c[0], setSelectedTab = _c[1];
    var _d = useState(navList[0].id), step = _d[0], setStep = _d[1];
    var searchParams = useSearchParams();
    var phoneParam = searchParams.get('phone');
    var uuidParam = searchParams.get('uuid');
    var _e = useState(defaultValue.params), params = _e[0], setParams = _e[1];
    var _f = useAuthorization(), isAuth = _f.isAuth, user = _f.user, signInSelector = _f.signInSelector, recoverSelector = _f.recoverSelector, changePassSelector = _f.changePassSelector, onSignIn = _f.onSignIn, onVerify = _f.onVerify, onRecover = _f.onRecover, onChangePassword = _f.onChangePassword;
    var _g = useQuery({
        api: '/auth/totp/verify/',
        type: Api.LOAD_TOTP_VERIFY,
        immediately: false,
        showErrorMessage: true,
    }), totpVerify = _g.data, isLoadingTotp = _g.isLoading, fetchTotpVerify = _g.refetch;
    var _h = useMutation({
        api: "/auth/totp/verify/",
        method: 'POST',
        data: { code: (params === null || params === void 0 ? void 0 : params.code) || '' },
        type: Api.POST_LIVENESS_FORENSICS,
        showErrorMessage: true,
        refreshAfter: true,
        onSuccess: function () {
            localStorage.removeItem('totp_completed');
            onSuccess({ message: 'Вы успешно авторизовались!' });
        }
    }), onTotpVerify = _h[0], isLoadingOnTotpVerify = _h[1].isLoading;
    useEffect(function () {
        if (phoneParam)
            changeParam('phone')("+".concat(phoneParam));
    }, [phoneParam]);
    useEffect(function () {
        if (uuidParam && isAuth)
            navigate("/steps/".concat(uuidParam));
        else if (isAuth)
            navigate('/profile');
    }, [isAuth, uuidParam]);
    useEffect(function () {
        var totpCompleted = localStorage.getItem('totp_completed');
        if (totpCompleted)
            setStep("TOTP");
    }, []);
    useEffect(function () {
        var _a, _b;
        if (typeof (params === null || params === void 0 ? void 0 : params.otp) === 'string' && ((_a = params === null || params === void 0 ? void 0 : params.otp) === null || _a === void 0 ? void 0 : _a.length) > 3 && selectedTab === navList[1].id) {
            setStep('CHANGE_PASS');
        }
        else if (typeof (params === null || params === void 0 ? void 0 : params.otp) === 'string' && ((_b = params === null || params === void 0 ? void 0 : params.otp) === null || _b === void 0 ? void 0 : _b.length) > 3 && selectedTab === navList[0].id) {
            onVerify(params, {
                onSuccess: function (data) {
                    dispatch(verifySuccess(data));
                    onSuccess({ message: t('flow.profile.logged_in'), time: 10000 });
                    navigate("/profile");
                },
                onError: function (err) {
                    var _a;
                    changeParam('otp')('');
                    onError({ message: String((_a = err.data) === null || _a === void 0 ? void 0 : _a.detail) });
                    setStep('GET_NUMBER');
                },
            });
        }
    }, [params === null || params === void 0 ? void 0 : params.otp]);
    var changeParam = function (key) { return function (value) { return setParams(function (prev) {
        var _a;
        return (__assign(__assign({}, prev), (_a = {}, _a[key] = value, _a)));
    }); }; };
    var onSubmitChangePassword = function () { return onChangePassword(params, {
        onSuccess: function (m) {
            setStep("SUCCESS_RESET");
            onSuccess({ title: t('flow.profile.change_pass'), message: t('flow.profile.changed_pass_success'), time: 10000 });
            setTimeout(function () {
                setStep("LOGIN");
                onWarn({ message: t('flow.profile.login_new_pass'), time: 5000 });
                setParams(__assign(__assign({}, defaultValue.params), { phone: params.phone }));
            }, 5000);
        }
    }); };
    var onTotpBack = function () {
        setStep("LOGIN");
        localStorage.removeItem('totp_completed');
    };
    var successTotp = function () {
        setStep("TOTP");
    };
    var errorTotp = function () {
        fetchTotpVerify();
        setStep("TOTP");
    };
    var needChangePass = function () {
        setStep("NEED_RESET_PASS");
        setTimeout(function () { return setStep("RESET_PASS"); }, 5000);
    };
    var onSubmitSignIn = function () {
        onSignIn(params, {
            successTotp: successTotp,
            errorTotp: errorTotp,
            needChangePass: needChangePass,
        });
    };
    var onSubmitResetPass = function () { return onRecover(params.phone, {
        onSuccess: function (m) {
            setStep("OTP_FORM");
        }
    }); };
    var onSubmitGetNumber = function () { return onRecover(params.phone, {
        onSuccess: function (m) {
            setStep("OTP_FORM_2");
        }
    }); };
    var onResend = function (fn) { return onRecover(params.phone, {
        onSuccess: function (m) {
            fn();
            setStep("OTP_FORM");
        }
    }); };
    var validate = validateFields(params);
    var validateRules = {
        login: [
            ["phone", "password"], {
                phone: "PHONE",
                password: function (val) { return (val === null || val === void 0 ? void 0 : val.length) < 5; },
            }
        ],
        reset: [["phone"], { phone: "PHONE" }],
        totp: [["code"], { code: function (val) { return (val === null || val === void 0 ? void 0 : val.length) < 4; } }],
        changePass: [["password", 'password_confirmation'], {
                password: function (val) { return (val === null || val === void 0 ? void 0 : val.length) < 5; },
                password_confirmation: function (val) { return (val === null || val === void 0 ? void 0 : val.length) < 5; }
            }],
    };
    return React.createElement(LoginPageContext.Provider, { value: {
            isLoading: {
                login: signInSelector === null || signInSelector === void 0 ? void 0 : signInSelector.isLoading,
                reset: recoverSelector === null || recoverSelector === void 0 ? void 0 : recoverSelector.isLoading,
                totp: isLoadingTotp || isLoadingOnTotpVerify,
                changePass: changePassSelector === null || changePassSelector === void 0 ? void 0 : changePassSelector.isLoading,
            },
            isDisabled: {
                login: (signInSelector === null || signInSelector === void 0 ? void 0 : signInSelector.isLoading) || validate.apply(void 0, validateRules['login']),
                totp: isLoadingTotp || isLoadingOnTotpVerify || validate.apply(void 0, validateRules['totp']),
                reset: (recoverSelector === null || recoverSelector === void 0 ? void 0 : recoverSelector.isLoading) || validate.apply(void 0, validateRules['reset']),
                changePass: (changePassSelector === null || changePassSelector === void 0 ? void 0 : changePassSelector.isLoading) || validate.apply(void 0, validateRules['changePass']),
            },
            pageTitle: ['RESET_PASS'].includes(step) ? "flow.profile.change_pass" : "flow.profile.enter",
            params: params,
            step: step,
            list: navList,
            selectedTab: selectedTab,
            onListChange: function (id) {
                setStep(id);
                setSelectedTab(id);
            },
            changeParam: changeParam,
            onSubmitSignIn: onSubmitSignIn,
            onTotpVerify: onTotpVerify,
            onTotpBack: onTotpBack,
            onSubmitChangePassword: onSubmitChangePassword,
            onResend: onResend,
            has_totp: user.has_totp,
            qr_link: generateTotpQrUrlHelper(user === null || user === void 0 ? void 0 : user.user_id, totpVerify === null || totpVerify === void 0 ? void 0 : totpVerify.secret_key),
            onSubmitGetNumber: onSubmitGetNumber,
            onResetPass: function () {
                setStep("RESET_PASS");
                setParams(__assign(__assign({}, defaultValue.params), { phone: params.phone }));
            },
            onPassStep: function () {
                setStep("LOGIN");
                setParams(__assign(__assign({}, defaultValue.params), { phone: params.phone }));
            },
            onSubmitResetPass: onSubmitResetPass
        } }, children);
};
