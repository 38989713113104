import { getFormatted } from "@/helpers/getFormatted";
import { deadlineFormat } from "@/helpers/deadlineFormat";
import { useTranslation } from "react-i18next";
export var valueFormat = function (value, type) {
    var _a;
    var t = useTranslation().t;
    var template = (_a = {},
        _a['MONEY'] = "".concat(getFormatted(new Intl.NumberFormat('ru-RU').format(Number(value)), 0)(!!value), " \u20B8"),
        _a['DEADLINE'] = "".concat(deadlineFormat(value), " ").concat(deadlineFormat(value) < 5 ? t("steps.age") : t("steps.age2")),
        _a['PERCENT'] = Number(value).toFixed(1) + ' %',
        _a['METHOD'] = value == "ANNUITY" ? t('calc.calc.annuity') : t('calc.calc.equal'),
        _a);
    return type ? template[type] : value;
};
