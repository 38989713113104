var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Checkbox, MaskInput, Select, SelectSearch, ShowList, TextInput } from "@/components";
import './index.scss';
import { useTranslation } from "react-i18next";
var MortgageInfoForm = function (_a) {
    var theme = _a.theme, list = _a.list, changeable = _a.changeable, onClick = _a.onClick, disabled = _a.disabled, loading = _a.loading, _b = _a.showMessages, showMessages = _b === void 0 ? false : _b, _c = _a.extraClass, extraClass = _c === void 0 ? '' : _c, props = __rest(_a, ["theme", "list", "changeable", "onClick", "disabled", "loading", "showMessages", "extraClass"]);
    var t = useTranslation().t;
    return (React.createElement("div", { className: "mortgage-info-form ".concat(extraClass) },
        React.createElement("div", { className: 'mortgage-info-form__wrapper' },
            React.createElement(ShowList, { list: list }, function (item, i) {
                if (Array.isArray(item))
                    return (React.createElement("div", { key: i, className: 'mortgage-info-form__row' }, item.map((function (itm) {
                        switch (itm.type) {
                            case 'SELECT':
                                return React.createElement(Select, { key: itm.label, value: itm.value, disabled: disabled || loading, list: itm.list, onChange: itm.onChange, placeholder: itm.placeholder, label: itm.label, onFocus: itm.onFocus, className: itm.className });
                            case 'SELECT_SEARCH':
                                return React.createElement(SelectSearch, { key: itm.label, value: itm.value, disabled: disabled || loading, list: itm.list, changeSelect: itm.changeSelect, searchSelect: itm.searchSelect, placeholder: itm.placeholder, label: itm.label, onFocus: itm.onFocus, className: itm.className, isInfinite: itm.isInfinite, onInfiniteHandler: itm.onInfiniteHandler, isInfiniteLoading: itm.isInfiniteLoading });
                            case 'INPUT':
                                return React.createElement(TextInput, { key: itm.label, value: itm.value, disabled: disabled || loading, onChange: itm.onChange, mask: itm.mask, onFocus: itm.onFocus, placeholder: itm.placeholder, label: itm.label, className: itm.className });
                            case 'MONEY':
                                return React.createElement(MaskInput, { key: itm.label, value: itm.value, disabled: disabled || loading, onChange: itm.onChange, onFocus: itm.onFocus, min: itm === null || itm === void 0 ? void 0 : itm.min, max: itm === null || itm === void 0 ? void 0 : itm.max, mask: '₸ num', placeholder: itm.placeholder, label: itm.label, className: itm.className });
                            case 'CHECKBOX':
                                return React.createElement("div", { className: "pointer" },
                                    React.createElement(Checkbox, { onSelect: function (e) {
                                            var _a;
                                            e.stopPropagation();
                                            (_a = itm.onSelect) === null || _a === void 0 ? void 0 : _a.call(itm);
                                        }, label: itm.label, key: itm.label, disabled: itm.disabled, modalLink: itm === null || itm === void 0 ? void 0 : itm.modalLink, checked: itm.value }));
                            default:
                                return null;
                        }
                    }))));
                if (item.type == 'INPUT') {
                    return React.createElement(TextInput, { key: item.label, value: item.value, disabled: disabled || loading, onChange: item.onChange, mask: item.mask, onFocus: item.onFocus, placeholder: item.placeholder, label: item.label, className: item.className });
                }
                else if (item.type == 'SELECT') {
                    return React.createElement(Select, { key: item.label, value: item.value, list: item.list, onFocus: item.onFocus, disabled: disabled || loading, onChange: item.onChange, placeholder: item.placeholder, label: item.label, className: item.className });
                }
                else if (item.type == 'MONEY') {
                    return React.createElement(MaskInput, { key: item.label, value: item.value, disabled: disabled || loading, onChange: item.onChange, onFocus: item.onFocus, min: item === null || item === void 0 ? void 0 : item.min, max: item === null || item === void 0 ? void 0 : item.max, mask: '₸ num', placeholder: item.placeholder, label: item.label, className: item.className });
                }
                else if (item.type == 'CHECKBOX') {
                    return React.createElement("div", { className: "pointer" },
                        React.createElement(Checkbox, { onSelect: function (e) {
                                var _a;
                                e.stopPropagation();
                                (_a = item.onSelect) === null || _a === void 0 ? void 0 : _a.call(item);
                            }, label: item.label, disabled: item.disabled, key: item.label, modalLink: item === null || item === void 0 ? void 0 : item.modalLink, checked: item.value }));
                }
                else if (item.type == 'SELECT_SEARCH') {
                    return React.createElement(SelectSearch, { key: item.label, value: item.value, disabled: disabled || loading, onFocus: item.onFocus, list: item.list, changeSelect: item.changeSelect, searchSelect: item.searchSelect, placeholder: item.placeholder, title: item.label, className: item.className, isInfinite: item.isInfinite, onInfiniteHandler: item.onInfiniteHandler, isInfiniteLoading: item.isInfiniteLoading });
                }
                else {
                    return null;
                }
            })),
        props.children));
};
export default MortgageInfoForm;
