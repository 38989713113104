import TextInput from "@/components/ui/text-input";
import React from "react";
import BaseForm from "../base-form";
import { useTranslation } from "react-i18next";
var ChangePassForm = function (_a) {
    var params = _a.params, changeParam = _a.changeParam, onSubmit = _a.onSubmit, isLoading = _a.isLoading, isDisabled = _a.isDisabled, selectedTab = _a.selectedTab, title = _a.title, list = _a.list, onListChange = _a.onListChange;
    var t = useTranslation().t;
    return (React.createElement(BaseForm, { title: t(title || "flow.profile.enter"), btnLabel: t("flow.profile.save"), onSubmit: onSubmit, isLoading: isLoading, isDisabled: isDisabled, onListChange: onListChange, selectedTab: selectedTab, list: list },
        React.createElement(React.Fragment, null,
            React.createElement("h4", { style: { marginBottom: "10px", width: "335px" } }, t('flow.profile.enter_new_pass')),
            React.createElement(TextInput, { label: t('flow.profile.pass'), type: "password", onChange: changeParam('password'), value: (params === null || params === void 0 ? void 0 : params.password) || '', placeholder: t('flow.profile.enter_new_pass') }),
            React.createElement(TextInput, { label: t('flow.profile.repeat_pass'), type: "password", onChange: changeParam('password_confirmation'), value: (params === null || params === void 0 ? void 0 : params.password_confirmation) || '', placeholder: t('flow.profile.repeat_new_pass') }))));
};
export default ChangePassForm;
