import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
var ModalPortal = function (_a) {
    var children = _a.children, modalRoot = _a.modalRoot;
    var body = document.getElementsByTagName('body')[0];
    var rootRef = useRef(document.createElement('div'));
    useEffect(function () {
        rootRef.current.className = modalRoot;
        body.appendChild(rootRef.current);
        return function () { return body.removeChild(rootRef.current); };
    }, [modalRoot]);
    return ReactDOM.createPortal(children, rootRef.current);
};
export default ModalPortal;
