import React from 'react';
import './style.scss';
import { ShowList } from "@/components";
var Limit = function (_a) {
    var _b = _a.list, list = _b === void 0 ? [5, 10, 20] : _b, value = _a.value, _c = _a.title, title = _c === void 0 ? '' : _c, onClick = _a.onClick;
    return (React.createElement("div", { className: "ui-limit" },
        React.createElement("span", { className: "ui-limit__title" }, title),
        React.createElement("div", { className: "ui-limit__box" },
            React.createElement(ShowList, { list: list }, function (val) { return (React.createElement("div", { key: val, onClick: function () { return value !== val && onClick(val); }, className: "ui-limit__item".concat(value === val ? ' active' : '') }, val)); }))));
};
export default Limit;
