import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeContextProvider } from "./containers/theme-container";
import "./index.scss";
import Routing from "./routes";
import store from "@/saga-query/store";
import { Warning } from "@/components";
import '@/translate/i18n';
import { kzKZ, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { ruRU } from '@mui/x-date-pickers/locales';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/ru';
import 'dayjs/locale/kk';
import { useTranslation } from "react-i18next";
dayjs.extend(utc);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
// dayjs.locale('ru')
var pagePath = "/";
var App = function () {
    var i18n = useTranslation().i18n;
    return (React.createElement(Provider, { store: store },
        React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs, adapterLocale: i18n === null || i18n === void 0 ? void 0 : i18n.language, localeText: ((i18n === null || i18n === void 0 ? void 0 : i18n.language) === 'kz' ? kzKZ : ruRU).components.MuiLocalizationProvider.defaultProps.localeText },
            React.createElement(ThemeContextProvider, null,
                React.createElement(Router, { basename: pagePath },
                    React.createElement(Routing, null)),
                React.createElement(Warning, null)))));
};
var root = ReactDOM.createRoot(document.getElementById("app"));
root.render(React.createElement(App, null));
