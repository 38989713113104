export var modalFooterData = {
    'ADD_DOCUMENT': [
        { label: "Отправить документ", type: "ACCEPT" },
        { label: "Отмена", type: "DECLINE" },
    ],
    'DECLINE': [
        { label: "Отправить на доработку", type: "ACCEPT" },
        { label: "Отмена", type: "DECLINE" },
    ],
    'CONFIRMATION': [
        { label: "Да. подтвердить", type: "ACCEPT" },
        { label: "Нет, отмена", type: "DECLINE" },
    ],
    'CONFIRMATION_DOCUMENT': [
        { label: "Да. подтвердить", type: "ACCEPT" },
        { label: "Нет, отмена", type: "DECLINE" },
    ],
    'CONFIRMATION_SIGNATURE': [
        { label: "Да. подтвердить", type: "ACCEPT" },
        { label: "Нет, отмена", type: "DECLINE" },
    ],
    'SIGNATURE_DETAIL': [
        { label: "Закрыть", type: "DECLINE" },
    ],
    'ASSIGN_CONFIRMATION': [
        { label: "Да. подтвердить", type: "ACCEPT" },
        { label: "Нет, отмена", type: "DECLINE" },
    ],
    'CREATE_DOCUMENT': [
        { label: "Отправить документ", type: "ACCEPT" },
        { label: "Нет, отмена", type: "DECLINE" },
    ],
    'REASSIGNMENT': [
        { label: "Да. переназначить", type: "ACCEPT" },
        { label: "Нет, отмена", type: "DECLINE" },
    ],
};
