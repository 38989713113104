import React, { createContext, useState, } from "react";
import { ETheme } from "@/types/containers/theme";
import { useTheme } from "@/hooks/useTheme";
var initial = {
    theme: ETheme.LIGHT,
    changeTheme: function () {
    },
    menu: 'burger-close',
    changeSetMenu: function () {
    },
};
export var ThemeContext = createContext(initial);
export var ThemeContextProvider = function (_a) {
    var children = _a.children;
    var _b = useTheme(), theme = _b.theme, changeTheme = _b.changeTheme;
    var _c = useState('burger-close'), menu = _c[0], setMenu = _c[1];
    var changeSetMenu = function (status) {
        setMenu(function () { return status; });
    };
    return React.createElement(ThemeContext.Provider, { value: {
            theme: theme,
            changeTheme: changeTheme,
            menu: menu,
            changeSetMenu: changeSetMenu,
        } }, children);
};
