var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthorization } from "@/saga-query/query-hooks/useAuthorization";
import { useNotification } from "@/saga-query/query-hooks/useNotification";
import { validateFields } from "@/helpers/validateFields";
import { useTranslation } from "react-i18next";
export var ChangePassContext = createContext({});
var defaultValue = {
    params: { phone: '', password: '', password_confirmation: '' }
};
export var ChangePassContextProvider = function (_a) {
    var children = _a.children;
    var navigate = useNavigate();
    var t = useTranslation().t;
    var _b = useNotification(), onSuccess = _b.onSuccess, onWarn = _b.onWarn;
    var _c = useState("RESET_PASS"), step = _c[0], setStep = _c[1];
    var _d = useAuthorization(), signInSelector = _d.signInSelector, recoverSelector = _d.recoverSelector, changePassSelector = _d.changePassSelector, onSignIn = _d.onSignIn, onRecover = _d.onRecover, onChangePassword = _d.onChangePassword;
    var _e = useState(defaultValue.params), params = _e[0], setParams = _e[1];
    useEffect(function () {
        var _a;
        if (typeof (params === null || params === void 0 ? void 0 : params.otp) === 'string' && ((_a = params === null || params === void 0 ? void 0 : params.otp) === null || _a === void 0 ? void 0 : _a.length) > 3) {
            setStep('CHANGE_PASS');
        }
    }, [params === null || params === void 0 ? void 0 : params.otp]);
    var changeParam = function (key) { return function (value) { return setParams(function (prev) {
        var _a;
        return (__assign(__assign({}, prev), (_a = {}, _a[key] = value, _a)));
    }); }; };
    var onSubmitChangePassword = function () { return onChangePassword(params, {
        onSuccess: function (m) {
            setStep("SUCCESS_RESET");
            onSuccess({ title: t('flow.profile.change_pass'), message: t('flow.profile.changed_pass_success'), time: 10000 });
            setTimeout(function () {
                navigate('/profile');
            }, 5000);
        }
    }); };
    var onSubmitResetPass = function () { return onRecover(params.phone, {
        onSuccess: function (m) {
            setStep("OTP_FORM");
            onSuccess({ message: (m === null || m === void 0 ? void 0 : m.detail) || '' });
        }
    }); };
    var onResend = function (fn) { return onRecover(params.phone, {
        onSuccess: function (m) {
            fn();
            setStep("OTP_FORM");
            onSuccess({ message: (m === null || m === void 0 ? void 0 : m.detail) || '' });
        }
    }); };
    var onSubmitSignIn = function () {
        onSignIn(params);
    };
    var validate = validateFields(params);
    var validateRules = {
        login: [
            ["phone", "password"],
            {
                phone: "PHONE",
                password: function (val) { return (val === null || val === void 0 ? void 0 : val.length) < 5; },
            }
        ],
        reset: [["phone"], { phone: "PHONE" }],
        changePass: [["password", 'password_confirmation'], {
                password: function (val) { return (val === null || val === void 0 ? void 0 : val.length) < 5; },
                password_confirmation: function (val) { return (val === null || val === void 0 ? void 0 : val.length) < 5; }
            }],
    };
    return React.createElement(ChangePassContext.Provider, { value: {
            isLoading: {
                login: signInSelector === null || signInSelector === void 0 ? void 0 : signInSelector.isLoading,
                reset: recoverSelector === null || recoverSelector === void 0 ? void 0 : recoverSelector.isLoading,
                changePass: changePassSelector === null || changePassSelector === void 0 ? void 0 : changePassSelector.isLoading,
            },
            isDisabled: {
                login: (signInSelector === null || signInSelector === void 0 ? void 0 : signInSelector.isLoading) || validate.apply(void 0, validateRules['login']),
                reset: (recoverSelector === null || recoverSelector === void 0 ? void 0 : recoverSelector.isLoading) || validate.apply(void 0, validateRules['reset']),
                changePass: (changePassSelector === null || changePassSelector === void 0 ? void 0 : changePassSelector.isLoading) || validate.apply(void 0, validateRules['changePass']),
            },
            pageTitle: 'Страница авторизации',
            params: params,
            step: step,
            changeParam: changeParam,
            onSubmitSignIn: onSubmitSignIn,
            onSubmitChangePassword: onSubmitChangePassword,
            onResend: onResend,
            onResetPass: function () {
                setStep("RESET_PASS");
                setParams(__assign(__assign({}, defaultValue.params), { phone: params.phone }));
            },
            onSubmitResetPass: onSubmitResetPass
        } }, children);
};
