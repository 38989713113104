import { useRef } from 'react';
import { useNotification } from "@/saga-query/query-hooks/useNotification";
import { NCALayerService } from "@/helpers/ncaLayerService";
export var useNCALayer = function (params) {
    var onError = useNotification().onError;
    var ncaLayerInstance = useRef(new NCALayerService({
        onError: onError,
        onSuccess: function (res) { return console.log("onSuccess", res); },
        onMessage: function (res) { return console.log("onMessage", res); },
        onCreateCMSSignatureFromBase64Back: (params === null || params === void 0 ? void 0 : params.onCreateCMSSignatureFromBase64Back) || (function () { return console.log("onCreateCMSSignatureFromBase64Back"); }),
        onCreateCAdESFromBase64HashBack: (params === null || params === void 0 ? void 0 : params.onCreateCAdESFromBase64HashBack) || (function () { return console.log("onCreateCAdESFromBase64HashBack"); }),
        onSignXmlGroupBack: (params === null || params === void 0 ? void 0 : params.onSignXmlGroupBack) || (function () { return console.log("onSignXmlGroupBack"); }),
        onSignBack: (params === null || params === void 0 ? void 0 : params.onSignBack) || (function () { return console.log("onSignBack"); }),
        onSignXmlBack: (params === null || params === void 0 ? void 0 : params.onSignXmlBack) || (function (data) { return console.log("onSignXmlBack", data); })
    }));
    var init = function (callBack) {
        ncaLayerInstance.current.init(callBack);
    };
    var getInfo = function () {
        return ncaLayerInstance.current.getInfo();
    };
    var onGetKeyInfo = function () {
        return ncaLayerInstance.current.getKeyInfo();
    };
    var onCreateCMSSignatureFromBase64 = function (file) {
        return ncaLayerInstance.current.createCMSSignatureFromBase64(file);
    };
    var onSignXml = function (blob) {
        ncaLayerInstance.current.signXml(blob);
    };
    var onCreateCAdESFromBase64Hash = function (file) {
        ncaLayerInstance.current.createCAdESFromBase64Hash(file);
    };
    var onSignXmlGroup = function (file) {
        ncaLayerInstance.current.signXmlGroup(file);
    };
    var closeConnection = function () {
        ncaLayerInstance.current.closeConnection();
    };
    return {
        init: init,
        closeConnection: closeConnection,
        onSignXml: onSignXml,
        getInfo: getInfo,
        onSignXmlGroup: onSignXmlGroup,
        onGetKeyInfo: onGetKeyInfo,
        onCreateCMSSignatureFromBase64: onCreateCMSSignatureFromBase64,
        onCreateCAdESFromBase64Hash: onCreateCAdESFromBase64Hash,
    };
};
