import TextInput from "@/components/ui/text-input";
import React, { useEffect, useRef } from "react";
import { findDOMNode } from "react-dom";
import BaseForm from "../base-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ShowIf, ShowSwitch } from "@/components";
import QRCodeCanvas from 'react-qr-code';
var TotpForm = function (_a) {
    var params = _a.params, changeParam = _a.changeParam, onSubmit = _a.onSubmit, title = _a.title, has_totp = _a.has_totp, qr_link = _a.qr_link, onTotpBack = _a.onTotpBack, isLoading = _a.isLoading, isDisabled = _a.isDisabled;
    var inputRef = useRef(null);
    var navigate = useNavigate();
    var t = useTranslation().t;
    useEffect(function () {
        inputRef.current.focus();
    }, []);
    return (React.createElement(ShowSwitch, { conditions: [has_totp] },
        React.createElement(BaseForm, { title: t(title || "flow.profile.enter_code_title"), btnLabel: t("flow.confirm_button"), onSubmit: onSubmit, isLoading: isLoading, isDisabled: isDisabled },
            React.createElement(React.Fragment, null,
                React.createElement(TextInput, { inputRef: function (ref) { return inputRef.current = findDOMNode(ref); }, placeholder: t("flow.profile.enter_code"), onChange: changeParam('code'), label: t("flow.profile.enter_code"), value: params === null || params === void 0 ? void 0 : params.code }),
                React.createElement(ShowIf, { condition: !!onTotpBack },
                    React.createElement("div", null,
                        React.createElement("span", { onClick: onTotpBack, className: 'otp-form__link' }, t('flow.stepOtp.change_number')))))),
        React.createElement(BaseForm, { title: t(title || "flow.profile.enter_code_title2"), btnLabel: t("flow.profile.save"), onSubmit: onSubmit, isLoading: isLoading, isDisabled: isDisabled },
            React.createElement(React.Fragment, null,
                React.createElement("div", { style: { padding: '0' } },
                    React.createElement(ShowSwitch, { conditions: [!!qr_link] },
                        React.createElement(QRCodeCanvas, { style: { width: '100%', height: "auto" }, value: qr_link }),
                        React.createElement("div", { style: { color: 'darkred' } }, "\u041E\u0448\u0438\u0431\u043A\u0430 \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u044F \u043A\u043E\u0434\u0430"))),
                React.createElement(TextInput, { inputRef: function (ref) { return inputRef.current = findDOMNode(ref); }, placeholder: t("flow.profile.enter_code"), onChange: changeParam('code'), label: t("flow.profile.enter_code"), value: params === null || params === void 0 ? void 0 : params.code }),
                React.createElement(ShowIf, { condition: !!onTotpBack },
                    React.createElement("div", null,
                        React.createElement("span", { onClick: onTotpBack, className: 'otp-form__link' }, t('flow.stepOtp.change_number'))))))));
};
export default TotpForm;
