import React from 'react';
import './styles.scss';
import SVG from 'react-inlinesvg';
import { ShowIf } from "@/components";
var Loading = require('public/loading.svg');
var Bucket = require('public/chunks/document-card/bucket.svg');
var Download = require('public/download.svg');
var Button = function (_a) {
    var style = _a.style, className = _a.className, disabled = _a.disabled, onClick = _a.onClick, loading = _a.loading, svg_left = _a.svg_left, label = _a.label, download = _a.download, onDownload = _a.onDownload, remove = _a.remove, onRemove = _a.onRemove, svg_right = _a.svg_right, isSimple = _a.isSimple, caption = _a.caption, _b = _a.extraClass, extraClass = _b === void 0 ? '' : _b;
    return React.createElement("button", { className: "btn ".concat(className ? "btn-".concat(className) : '', " ").concat(disabled ? 'disabled' : '', " ").concat(extraClass), style: style, disabled: disabled, onClick: onClick },
        React.createElement(ShowIf, { condition: loading },
            React.createElement("div", { className: 'btn-loading' },
                React.createElement(SVG, { src: Loading }))),
        React.createElement(ShowIf, { condition: !!svg_left },
            React.createElement("div", { className: 'svg-left m-r-10' },
                React.createElement(SVG, { src: svg_left, height: 12 }))),
        React.createElement(ShowIf, { condition: !!label },
            React.createElement("div", { className: (className && !isSimple) ? "".concat('btn-' + className, "__label-container") : 'btn__label-container' },
                React.createElement("span", { className: (className && !isSimple) ? "".concat('btn-' + className, "__label") : 'btn__label' }, label),
                caption &&
                    React.createElement("span", { className: (className && !isSimple) ? "".concat('btn-' + className, "__caption") : 'btn__caption' }, caption))),
        React.createElement(ShowIf, { condition: download },
            React.createElement("div", { className: "svg-right download" },
                React.createElement(SVG, { src: Download, onClick: function (event) {
                        event.stopPropagation();
                        onDownload(event);
                    } }))),
        React.createElement(ShowIf, { condition: remove },
            React.createElement("div", { className: "svg-right remove" },
                React.createElement(SVG, { src: Bucket, onClick: function (event) {
                        event.stopPropagation();
                        onRemove(event);
                    } }))),
        React.createElement(ShowIf, { condition: !!svg_right },
            React.createElement("div", { className: "svg-right" },
                React.createElement(SVG, { src: svg_right }))));
};
export default Button;
