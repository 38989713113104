import React, { useEffect, useRef, useState } from "react";
import './index.scss';
export var BurgerMenu = function (props) {
    var onClick = props.onClick, _a = props.extraClass, extraClass = _a === void 0 ? '' : _a;
    var buttonRef = useRef(null);
    var _b = useState(false), burgerStatus = _b[0], setBurgerStatus = _b[1];
    var handleClick = function (e) {
        var status = !checkHasClassName(buttonRef.current, 'open');
        changeButtonClassName();
        setBurgerStatus(status);
        onClick(status ? 'burger-open' : 'burger-close');
    };
    var changeButtonClassName = function () {
        var button = buttonRef.current;
        button.classList.toggle('open');
    };
    var checkHasClassName = function (elem, className) {
        var _a;
        if ((_a = elem === null || elem === void 0 ? void 0 : elem.classList) === null || _a === void 0 ? void 0 : _a.contains(className)) {
            return true;
        }
        if (!(elem === null || elem === void 0 ? void 0 : elem.parentElement))
            return false;
        return checkHasClassName(elem === null || elem === void 0 ? void 0 : elem.parentElement, className) || false;
    };
    var onClickOutSide = function (e) {
        var target = e.target;
        var burger = document.querySelector('#burger-menu');
        var content = document.querySelector('.header__burger-content');
        if (burger === null || burger === void 0 ? void 0 : burger.contains(target))
            return;
        if (!(content === null || content === void 0 ? void 0 : content.contains(target))) {
            closeBurgerMenu();
        }
    };
    var closeBurgerMenu = function () {
        var _a, _b;
        (_b = (_a = buttonRef.current) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.remove('open');
        setBurgerStatus(false);
        onClick('burger-close');
    };
    useEffect(function () {
        var body = document.querySelector('body');
        body.addEventListener('click', onClickOutSide);
        if (burgerStatus) {
            body.style.overflow = 'hidden';
        }
        else {
            body.style.overflow = '';
        }
        return function () {
            body.style.overflow = '';
            body.removeEventListener('click', onClickOutSide);
        };
    }, [burgerStatus]);
    return (React.createElement("div", { ref: buttonRef, className: "burger-menu burger-menu__button ".concat(extraClass), id: "burger-menu", onClick: handleClick },
        React.createElement("span", null),
        React.createElement("span", null),
        React.createElement("span", null),
        React.createElement("span", null)));
};
