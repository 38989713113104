export var flatObject = function (data, by, prevKey) {
    if (by === void 0) { by = '.'; }
    if (prevKey === void 0) { prevKey = ''; }
    return data ? Object.entries(data)
        .reduce(function (res, _a) {
        var _b;
        var key = _a[0], val = _a[1];
        var path = prevKey ? [prevKey, key].join(by) : key;
        return Object.assign(res, val && typeof val === 'object' && !Array.isArray(val)
            ? flatObject(val, by, path)
            : (_b = {},
                _b[path] = Array.isArray(val) && val.map(function (v) { return flatObject(v, by); }) || val,
                _b));
    }, {}) : {};
};
