import { combineReducers } from 'redux';
import app from '@/saga-query/ducks/app';
import auth, { LOGOUT } from '@/saga-query/ducks/auth';
import notification from '@/saga-query/ducks/notification';
var mainReducer = combineReducers({
    app: app,
    auth: auth,
    notification: notification
});
var rootReducer = function (state, action) {
    var stateData = action.type === LOGOUT ? undefined : state;
    return mainReducer(stateData, action);
};
export default rootReducer;
