var Delete = require('public/ui/table/delete.svg');
var Update = require('public/ui/table/update2.svg');
var Return = require('public/ui/table/return.svg');
export var getButtonIcon = function (type) {
    var icons = {
        'DELETE': Delete,
        'UPDATE': Update,
        'RETURN': Return
    };
    return icons[type] || null;
};
