var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LoginPageContext, LoginPageContextProvider } from "@/containers/login-page-container";
import { ChangePassContext, ChangePassContextProvider } from "@/containers/change-pass-container";
import { HomePageContext, HomePageContextProvider } from "@/containers/home-page-container";
import { OrderContext, OrderContextProvider } from "@/containers/order-container";
import { ListPageContext, ListPageContextProvider } from "@/containers/list-all-container";
import { SingleMortgagePageContext, SingleMortgagePageContextProvider } from "@/containers/single-application-container";
import { ListPageNotaryContext, ListPageNotaryContextProvider } from "@/containers/list-all-notary-container";
import { SingleNotaryPageContext, SingleNotaryPageContextProvider } from "@/containers/single-notary-application-container";
import { InitContext, InitContextProvider, } from "@/containers/init-container";
import { InitSpouseContext, InitSpouseContextProvider } from "@/containers/init-spouse-container";
import { ProfilePageContext, ProfilePageContextProvider } from "@/containers/profile-page-container";
import stepsContexts from '@/containers/steps';
export var generalScheme = __assign({ login: {
        context: LoginPageContext,
        provider: LoginPageContextProvider
    }, change_pass: {
        context: ChangePassContext,
        provider: ChangePassContextProvider
    }, profile: {
        context: ProfilePageContext,
        provider: ProfilePageContextProvider
    }, list: {
        context: ListPageContext,
        provider: ListPageContextProvider
    }, detail: {
        context: SingleMortgagePageContext,
        provider: SingleMortgagePageContextProvider
    }, list_notary: {
        context: ListPageNotaryContext,
        provider: ListPageNotaryContextProvider
    }, detail_notary: {
        context: SingleNotaryPageContext,
        provider: SingleNotaryPageContextProvider
    }, home: {
        context: HomePageContext,
        provider: HomePageContextProvider
    }, order: {
        context: OrderContext,
        provider: OrderContextProvider
    }, init: {
        context: InitContext,
        provider: InitContextProvider
    }, init_spouse: {
        context: InitSpouseContext,
        provider: InitSpouseContextProvider
    } }, stepsContexts);
