import React, { useRef, useState } from 'react';
import SVG from "react-inlinesvg";
import './styles.scss';
var Lupa = require('public/ui/search/search.svg');
var Search = function (_a) {
    var onChange = _a.onChange, placeholder = _a.placeholder, value = _a.value, _b = _a.wait, wait = _b === void 0 ? 2000 : _b, className = _a.className;
    var _c = useState(''), search = _c[0], onSearch = _c[1];
    var timeout = useRef(null);
    var handleChange = function (e) {
        return onChange(e.target.value);
    };
    return (React.createElement("div", { className: 'search' + " ".concat(className ? className : '') },
        React.createElement(SVG, { src: Lupa }),
        React.createElement("input", { type: 'text', placeholder: placeholder, value: value, onChange: handleChange, onKeyUp: function (e) {
                if (timeout.current)
                    clearTimeout(timeout.current);
                timeout.current = setTimeout(onSearch, wait);
            }, onKeyDown: function (e) {
                clearTimeout(timeout.current);
            } })));
};
export default Search;
