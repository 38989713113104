import React from 'react';
import { BaseContainer, Button, ShowIf, ShowList } from "@/components";
import SubTitle from "@/components/ui/sub-title";
import WarningMessage from "@/components/chunks/mortgage-info-form/warning-message";
import data, { notaryData } from './data';
import Text from "@/components/ui/text/Text";
import './index.scss';
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
var Loading = require('public/chunks/steps/loading-bar.svg');
var Check = require('public/chunks/steps/check.svg');
export var NotaryBlock = function (_a) {
    var children = _a.children;
    return (React.createElement(BaseContainer, null, children));
};
export var NotaryInfoCard = function (_a) {
    var title = _a.title, value = _a.value;
    return (React.createElement("div", { className: 'notary-checking__card' },
        React.createElement(Text, { text: title }),
        React.createElement(SubTitle, { text: value, style: { margin: '0 10px 0 0' } })));
};
export var NotaryStatuses = function (_a) {
    var isLoading = _a.isLoading, statuses = _a.statuses;
    var t = useTranslation().t;
    if (isLoading)
        return (React.createElement(BaseContainer, { style: { borderRadius: '0 0 16px 16px' } },
            React.createElement("div", { className: 'notary-checking__loading' },
                React.createElement(SVG, { src: Loading, className: 'notary-checking__spinner', width: 30, height: 30 }),
                React.createElement(SubTitle, { text: t(data.loadingMsg), style: { margin: '0' } }))));
    return (React.createElement(BaseContainer, { style: { display: 'flex', flexDirection: 'column', gap: '20px 0', borderRadius: '0 0 16px 16px' } }, statuses.map(function (status) {
        return (React.createElement("div", { className: 'notary-checking__status' },
            React.createElement(SVG, { src: Check, width: 20, height: 20 }),
            React.createElement(SubTitle, { text: t(status), style: { margin: '0' } })));
    })));
};
var NotaryChecking = function (_a) {
    var _b = _a.selected, selected = _b === void 0 ? true : _b, onNotary = _a.onNotary, isLoading = _a.isLoading, isDisable = _a.isDisable;
    var t = useTranslation().t;
    if (selected)
        return React.createElement(NotaryBlock, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(NotaryBlock, null,
            React.createElement("div", { className: 'notary-checking__info' },
                React.createElement(SubTitle, { text: t('steps.step4.your_notary') }),
                React.createElement(ShowList, { list: notaryData }, function (data) {
                    if (Array.isArray(data))
                        return (React.createElement("div", { className: 'notary-checking__row' }, data.map(function (card) { return (React.createElement(NotaryInfoCard, { key: card.value, title: t(card.title), value: card.value })); })));
                    return React.createElement(NotaryInfoCard, { title: t(data.title), value: data.value });
                })),
            React.createElement("div", { className: "m-b-5" }),
            React.createElement("div", { className: 'm-b-20' }),
            React.createElement(WarningMessage, { message: t(data.notaryMeassage) }),
            React.createElement(ShowIf, { condition: isDisable.showButton },
                React.createElement(Button, { onClick: onNotary, loading: isLoading.onNotary, disabled: isLoading.onNotary || isDisable.onNotary, label: t('steps.confirm_button'), className: 'outline', style: { marginTop: '16px', width: '100%' } })))));
};
export default NotaryChecking;
