var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { queryDataStateByIdSelector } from "@/saga-query/ducks/app";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_PASSWORD, changePassword, isAuthorizedSelector, logout, profileSelector, RECOVER, recover, setCredentials, SIGN_IN, signIn, tokenKeySelector, updateProfile, userSelector, verify, verifySuccess } from "@/saga-query/ducks/auth";
import { useNotification } from "@/saga-query/query-hooks/useNotification";
import { showErrorHelper } from "@/helpers/showErrorHelper";
import { SUCCESS } from "@/saga-query/prefix";
import jwtDecode from "jwt-decode";
export var useAuthorization = function () {
    var dispatch = useDispatch();
    var _a = useNotification(), onSuccess = _a.onSuccess, onError = _a.onError, onWarn = _a.onWarn;
    var isAuth = useSelector(function (state) { return isAuthorizedSelector(state); });
    var accessToken = useSelector(function (state) { return tokenKeySelector(state); });
    var isNotary = useSelector(function (state) { return tokenKeySelector(state); });
    var isRegistrar = useSelector(function (state) { return tokenKeySelector(state); });
    var user = useSelector(function (state) { return userSelector(state); });
    var profile = useSelector(function (state) { return profileSelector(state); });
    var signInSelector = useSelector(function (state) {
        return queryDataStateByIdSelector(state, SIGN_IN)(state);
    });
    var recoverSelector = useSelector(function (state) {
        return queryDataStateByIdSelector(state, RECOVER + SUCCESS)(state);
    });
    var changePassSelector = useSelector(function (state) {
        return queryDataStateByIdSelector(state, CHANGE_PASSWORD + SUCCESS)(state);
    });
    var onSignIn = function (params, options) {
        dispatch(signIn(params, __assign({ onSuccess: function (data) {
                var _a, _b, _c;
                var userData = jwtDecode(data.access);
                if (['REGISTRAR', 'NOTARY'].includes(userData.user_role)) {
                    if (userData.need_password_change)
                        return (_a = options.needChangePass) === null || _a === void 0 ? void 0 : _a.call(options, userData);
                    if (userData.has_totp)
                        (_b = options.successTotp) === null || _b === void 0 ? void 0 : _b.call(options, userData);
                    else
                        (_c = options.errorTotp) === null || _c === void 0 ? void 0 : _c.call(options, userData);
                }
                else {
                    onSuccess({ message: 'Вы успешно авторизовались!' });
                }
            }, onError: showErrorHelper(onError) }, options)));
    };
    var onRecover = function (phone, options) {
        dispatch(recover(phone, __assign({ onSuccess: function (m) { var _a, _b; return ((_a = m === null || m === void 0 ? void 0 : m.data) === null || _a === void 0 ? void 0 : _a.detail) && onSuccess({ message: ((_b = m === null || m === void 0 ? void 0 : m.data) === null || _b === void 0 ? void 0 : _b.detail) || '' }); }, onError: showErrorHelper(onError) }, options)));
    };
    var onVerify = function (params, options) {
        dispatch(verify(params, __assign({ onSuccess: function (m) { var _a, _b; return ((_a = m === null || m === void 0 ? void 0 : m.data) === null || _a === void 0 ? void 0 : _a.detail) && onSuccess({ message: ((_b = m === null || m === void 0 ? void 0 : m.data) === null || _b === void 0 ? void 0 : _b.detail) || '' }); }, onError: showErrorHelper(onError) }, options)));
    };
    var onChangePassword = function (params, options) {
        dispatch(changePassword(params, __assign({ onSuccess: function (m) { var _a, _b; return ((_a = m === null || m === void 0 ? void 0 : m.data) === null || _a === void 0 ? void 0 : _a.detail) && onSuccess({ message: ((_b = m === null || m === void 0 ? void 0 : m.data) === null || _b === void 0 ? void 0 : _b.detail) || '' }); }, onError: showErrorHelper(onError) }, options)));
    };
    var onLogout = function () {
        dispatch(logout());
        onWarn({ message: 'Вы успешно вышли из аккаунта!' });
    };
    var onVerifySuccess = function (params) {
        dispatch(verifySuccess(params));
        // onSuccess({message: 'Вы можете задать пароль для входа в профиль'})
    };
    var onUpdateProfile = function () {
        dispatch(updateProfile());
    };
    return {
        isAuth: isAuth,
        signInSelector: signInSelector,
        changePassSelector: changePassSelector,
        recoverSelector: recoverSelector,
        accessToken: accessToken,
        user: user,
        profile: profile,
        onSignIn: onSignIn,
        onLogout: onLogout,
        setCredentials: function (access) { return dispatch(setCredentials(access)); },
        onRecover: onRecover,
        onVerify: onVerify,
        onChangePassword: onChangePassword,
        onVerifySuccess: onVerifySuccess,
        onUpdateProfile: onUpdateProfile
    };
};
