var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { Record } from 'immutable';
import { createSelector } from 'reselect';
import jwtDecode from "jwt-decode";
import { sagaQuery } from '@/saga-query/saga/query';
import configs from '@/saga-query/constants';
import { START, SUCCESS } from '@/saga-query/prefix';
// import { UploadImageType, UserRoleListType } from 'models';
/**
 * Constants
 * */
export var moduleName = 'auth';
var prefix = "".concat(configs.APP_NAME, "/").concat(moduleName);
export var SET_CREDENTIALS = "".concat(prefix, "/SET_CREDENTIALS");
export var SET_CURRENT = "".concat(prefix, "/SET_CURRENT");
export var CLEAR = "".concat(prefix, "/CLEAR");
export var SIGN_IN = "".concat(prefix, "/SIGN_IN");
export var UPDATE_USER = "".concat(prefix, "/UPDATE_USER");
export var RECOVER = "".concat(prefix, "/RECOVER");
export var VERIFY = "".concat(prefix, "/VERIFY");
export var SIGN_UP = "".concat(prefix, "/SIGN_UP");
export var CHANGE_PASSWORD = "".concat(prefix, "/CHANGE_PASSWORD");
export var FORGOT_PASSWORD = "".concat(prefix, "/FORGOT_PASSWORD");
export var COMPLETE_REGISTER = "".concat(prefix, "/COMPLETE_REGISTER");
export var LOGOUT = "".concat(prefix, "/LOGOUT");
export var AuthState = Record({
    access: '',
    recover: {},
    profile: {},
    user: {},
    isRegistrar: false,
    isNotary: false,
    has_totp: false,
    need_password_change: false,
    changePassword: {},
}, moduleName);
export default function reducer(state, action) {
    if (state === void 0) { state = new AuthState(); }
    var type = action.type;
    var payload;
    switch (type) {
        case SET_CREDENTIALS:
            payload = action.payload;
            return state
                .set('access', payload.access)
                .set('profile', payload.profile)
                .set('user', payload.user)
                .set('isRegistrar', payload.isRegistrar)
                .set('isNotary', payload.isNotary)
                .set('has_totp', payload.has_totp)
                .set('need_password_change', payload.need_password_change);
        case RECOVER:
            payload = action.payload;
            return state.set('recover', payload);
        case SET_CURRENT:
            payload = action.payload;
            return state.set('profile', payload);
        case CHANGE_PASSWORD:
            payload = action.payload;
            return state.set('changePassword', payload);
        case CLEAR:
            return state.clear();
        default:
            return state;
    }
}
/**
 * Selectors
 * */
export var authSelector = function (state) { return state[moduleName]; };
export var isAuthorizedSelector = createSelector(authSelector, function (auth) {
    var totpCompleted = localStorage.getItem('totp_completed');
    // return auth?.isRegistrar || auth?.isNotary ? (!totpCompleted && auth?.has_totp && !!auth.access && !auth.need_password_change) : !!auth.access && !auth.need_password_change;
    return (auth === null || auth === void 0 ? void 0 : auth.isRegistrar) || (auth === null || auth === void 0 ? void 0 : auth.isNotary) ? (!!auth.access) : !!auth.access;
});
export var tokenKeySelector = createSelector(authSelector, function (auth) {
    return auth.access;
});
export var userSelector = createSelector(authSelector, function (auth) {
    return auth.user;
});
export var profileSelector = createSelector(authSelector, function (auth) {
    return auth.profile;
});
/**
 * Action Creators
 * */
export function signIn(payload, params) {
    return __assign({ type: SIGN_IN, payload: payload }, params);
}
export function updateProfile() {
    return {
        type: UPDATE_USER,
    };
}
export function recover(phone, params) {
    return __assign(__assign({ type: RECOVER + START }, params), { payload: {
            phone: phone,
        } });
}
export function verify(payload, params) {
    return __assign(__assign({ type: VERIFY + START }, params), { payload: payload });
}
export function changePassword(payload, params) {
    return __assign(__assign({ type: CHANGE_PASSWORD + START }, params), { payload: payload });
}
//
// export function changePassword(payload: Record<string, string | number>, params?: OptionsParamsType) {
//   return {
//     type: RECOVER + START,
//     ...params,
//     payload,
//   };
// }
export function signUp(payload) {
    return {
        type: SIGN_UP,
        payload: payload,
    };
}
export function logout() {
    return {
        type: LOGOUT,
    };
}
export function forgotPassword(email) {
    return {
        type: FORGOT_PASSWORD,
        payload: {
            email: email,
        },
    };
}
export function verifySuccess(payload) {
    return {
        type: SIGN_IN + SUCCESS,
        payload: payload,
    };
}
/**
 * Init logic
 */
export function init(store) {
    var _a, _b, _c, _d;
    var access = window.localStorage.getItem('access');
    var profile = window.localStorage.getItem('profile');
    if (access) {
        store.dispatch({
            type: SET_CREDENTIALS,
            payload: {
                access: access,
                profile: JSON.parse(profile || '{}'),
                user: jwtDecode(access),
                isRegistrar: ((_a = jwtDecode(access)) === null || _a === void 0 ? void 0 : _a.user_role) === "REGISTRAR",
                isNotary: ((_b = jwtDecode(access)) === null || _b === void 0 ? void 0 : _b.user_role) === "NOTARY",
                has_totp: (_c = jwtDecode(access)) === null || _c === void 0 ? void 0 : _c.has_totp,
                need_password_change: (_d = jwtDecode(access)) === null || _d === void 0 ? void 0 : _d.need_password_change,
            },
        });
        store.dispatch({
            type: 'INIT_APP',
        });
    }
}
// @ts-ignore
/**
 *Sagas
 **/
export function setCredentials(access) {
    var _a, _b, _c, _d;
    if (!access)
        return {};
    localStorage.setItem('access', access);
    return ({
        type: SET_CREDENTIALS,
        payload: {
            access: access,
            user: jwtDecode(access),
            isRegistrar: ((_a = jwtDecode(access)) === null || _a === void 0 ? void 0 : _a.user_role) === "REGISTRAR",
            isNotary: ((_b = jwtDecode(access)) === null || _b === void 0 ? void 0 : _b.user_role) === "NOTARY",
            has_totp: (_c = jwtDecode(access)) === null || _c === void 0 ? void 0 : _c.has_totp,
            need_password_change: (_d = jwtDecode(access)) === null || _d === void 0 ? void 0 : _d.has_totp
        },
    });
}
export function signInSaga(_a) {
    var payload = _a.payload, onSuccess = _a.onSuccess, onError = _a.onError;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, call(sagaQuery, {
                    type: SIGN_IN,
                    method: 'POST',
                    baseUrl: configs.SERVER,
                    callAPI: '/auth/token/',
                    payload: payload,
                    onSuccess: onSuccess,
                    onError: onError
                })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function updateCurrentUserSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(sagaQuery, {
                    type: UPDATE_USER,
                    method: 'GET',
                    baseUrl: configs.SERVER,
                    callAPI: '/profile/',
                    payload: {},
                    meta: { authorization: true },
                })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function recoverSaga(_a) {
    var payload = _a.payload, onSuccess = _a.onSuccess, onError = _a.onError;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, call(sagaQuery, {
                    type: RECOVER + SUCCESS,
                    method: 'POST',
                    baseUrl: configs.SERVER,
                    callAPI: '/auth/send-otp/',
                    payload: payload,
                    onSuccess: onSuccess,
                    onError: onError
                })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function verifySaga(_a) {
    var payload = _a.payload, onSuccess = _a.onSuccess, onError = _a.onError;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, call(sagaQuery, {
                    type: VERIFY + SUCCESS,
                    method: 'POST',
                    baseUrl: configs.SERVER,
                    callAPI: '/auth/verify-otp/',
                    payload: payload,
                    onSuccess: onSuccess,
                    onError: onError
                })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function changePasswordSaga(_a) {
    var payload = _a.payload, onSuccess = _a.onSuccess, onError = _a.onError;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, call(sagaQuery, {
                    type: CHANGE_PASSWORD + SUCCESS,
                    method: 'POST',
                    baseUrl: configs.SERVER,
                    callAPI: '/auth/password-change/',
                    payload: payload,
                    onSuccess: onSuccess,
                    onError: onError
                })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function signInSuccessSaga(_a) {
    var _b, _c, _d, _e, _f;
    var payload = _a.payload;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                // ["REGISTRAR", "NOTARY"].includes((jwtDecode(payload.access) as any)?.user_role) && localStorage.setItem('totp_completed', 'false')
                ["REGISTRAR", "NOTARY"].includes((_b = jwtDecode(payload.access)) === null || _b === void 0 ? void 0 : _b.user_role);
                return [4 /*yield*/, put({
                        type: SET_CREDENTIALS,
                        payload: {
                            access: payload.access,
                            profile: {},
                            user: jwtDecode(payload.access),
                            isRegistrar: ((_c = jwtDecode(payload.access)) === null || _c === void 0 ? void 0 : _c.user_role) === "REGISTRAR",
                            isNotary: ((_d = jwtDecode(payload.access)) === null || _d === void 0 ? void 0 : _d.user_role) === "NOTARY",
                            has_totp: (_e = jwtDecode(payload.access)) === null || _e === void 0 ? void 0 : _e.has_totp,
                            need_password_change: (_f = jwtDecode(payload.access)) === null || _f === void 0 ? void 0 : _f.need_password_change
                        },
                    })];
            case 1:
                _g.sent();
                window.localStorage.setItem('access', payload.access);
                window.localStorage.setItem('refresh', payload.refresh);
                return [4 /*yield*/, call(updateCurrentUserSaga)];
            case 2:
                _g.sent();
                return [2 /*return*/];
        }
    });
}
export function updateCurrentUserSuccessSaga(_a) {
    var userData;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                userData = payload;
                return [4 /*yield*/, put({
                        type: SET_CURRENT,
                        payload: payload,
                    })];
            case 1:
                _b.sent();
                window.localStorage.setItem('profile', JSON.stringify(userData));
                return [2 /*return*/];
        }
    });
}
export function recoverSuccessSaga(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, put({
                    type: RECOVER + SUCCESS,
                    payload: {
                        message: payload.detail,
                    },
                })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function verifySuccessSaga(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                console.log('verifySuccessSaga', payload);
                return [4 /*yield*/, put({
                        type: SET_CREDENTIALS,
                        payload: {
                            access: payload.access,
                            profile: {},
                            user: jwtDecode(payload.access)
                        },
                    })];
            case 1:
                _b.sent();
                window.localStorage.setItem('access', payload.access);
                window.localStorage.setItem('refresh', payload.refresh);
                return [4 /*yield*/, call(updateCurrentUserSaga)];
            case 2:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function changePasswordSuccessSaga(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, put({
                    type: CHANGE_PASSWORD + SUCCESS,
                    payload: payload,
                })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function signUpSaga(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, call(sagaQuery, {
                    type: SIGN_UP,
                    method: 'POST',
                    baseUrl: configs.SERVER,
                    callAPI: 'partnerUser',
                    payload: payload,
                })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function forgotPasswordSaga(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, call(sagaQuery, {
                    type: FORGOT_PASSWORD,
                    method: 'GET',
                    baseUrl: configs.SERVER,
                    callAPI: "user/password/passwordRecoverInit/".concat(payload.email),
                    payload: {},
                })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function logoutSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                window.localStorage.removeItem('access');
                window.localStorage.removeItem('refresh');
                window.localStorage.removeItem('profile');
                return [4 /*yield*/, put({
                        type: CLEAR,
                    })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function completeRegisterSaga(_a) {
    var userData;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                userData = payload.user;
                userData.userType = payload.userType;
                return [4 /*yield*/, put({
                        type: SET_CREDENTIALS,
                        payload: {
                            access: payload.access,
                        },
                    })];
            case 1:
                _b.sent();
                localStorage.setItem('access', payload.access);
                localStorage.setItem('refresh', payload.refresh);
                return [2 /*return*/];
        }
    });
}
export function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all([takeLatest(UPDATE_USER, updateCurrentUserSaga)])];
            case 1:
                _a.sent();
                return [4 /*yield*/, all([takeLatest(UPDATE_USER + SUCCESS, updateCurrentUserSuccessSaga)])];
            case 2:
                _a.sent();
                return [4 /*yield*/, all([takeLatest(RECOVER + START, recoverSaga)])];
            case 3:
                _a.sent();
                return [4 /*yield*/, all([takeLatest(RECOVER + SUCCESS, recoverSuccessSaga)])];
            case 4:
                _a.sent();
                return [4 /*yield*/, all([takeLatest(VERIFY + START, verifySaga)])];
            case 5:
                _a.sent();
                return [4 /*yield*/, all([takeLatest(VERIFY + SUCCESS, verifySuccessSaga)])];
            case 6:
                _a.sent();
                return [4 /*yield*/, all([takeLatest(CHANGE_PASSWORD + START, changePasswordSaga)])];
            case 7:
                _a.sent();
                return [4 /*yield*/, all([takeLatest(CHANGE_PASSWORD + SUCCESS, changePasswordSuccessSaga)])];
            case 8:
                _a.sent();
                return [4 /*yield*/, all([takeLatest(SIGN_IN, signInSaga)])];
            case 9:
                _a.sent();
                return [4 /*yield*/, all([takeLatest(SIGN_IN + SUCCESS, signInSuccessSaga)])];
            case 10:
                _a.sent();
                return [4 /*yield*/, all([takeLatest(COMPLETE_REGISTER + SUCCESS, completeRegisterSaga)])];
            case 11:
                _a.sent();
                return [4 /*yield*/, all([takeLatest(SIGN_UP, signUpSaga)])];
            case 12:
                _a.sent();
                return [4 /*yield*/, all([takeLatest(FORGOT_PASSWORD, forgotPasswordSaga)])];
            case 13:
                _a.sent();
                return [4 /*yield*/, all([takeEvery(LOGOUT, logoutSaga)])];
            case 14:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
