export var MODE = process.env.NODE_ENV;
export var APP_NAME = 'C2C';
export var SOCKET = MODE === 'development' ? 'wss://ff-c2c-dev.trafficwave.kz/api/ws' : 'wss://c2c.bankffin.kz/api/ws';
export var SERVER = MODE === 'development' ? 'https://ff-c2c-dev.trafficwave.kz/api' : 'https://c2c.bankffin.kz/api';
export var MORTGAGE_URL = MODE === 'development' ? 'https://new-dev-ipoteka.trafficwave.kz/' : 'https://ipoteka.bankffin.kz/';
export var LIVENESS_TOKEN = MODE === 'development'
    ? '08182eb868a1a6763cdb3db12db834b54c76d4c9602b30b7199d1d79d41dcde9efe6bade0252992f81cf4faeaa3df1c9c4c49027db7f9edbfbdda047dc095881'
    : '9019c69ef198847d69f8ce3275a53a4f6a1f37ed60f1a05fd19370e746320a96ee452897cf93b88511b1d7ce09619bb469f85e203908a2898b47e014ea841ad6';
export default {
    MODE: MODE,
    APP_NAME: APP_NAME,
    SOCKET: SOCKET,
    SERVER: SERVER,
    MORTGAGE_URL: MORTGAGE_URL,
    LIVENESS_TOKEN: LIVENESS_TOKEN,
};
