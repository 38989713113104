import React from "react";
import './index.scss';
import { ShowIf, SubTitle, ToggleButton } from "@/components";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "@/saga-query/query-hooks/useAuthorization";
var Profile = function (_a) {
    var data = _a.data, pageTitle = _a.pageTitle, onChangeActive = _a.onChangeActive, isLoading = _a.isLoading;
    var t = useTranslation().t;
    var user = useAuthorization().user;
    return (React.createElement("div", { className: "profile-details" },
        pageTitle && React.createElement(SubTitle, { text: pageTitle, style: { marginBottom: '10px', marginLeft: '10px' } }),
        React.createElement("div", { className: 'm-b-20 d-f gg-10' },
            React.createElement("span", { className: "gray" },
                t('flow.profile.fio'),
                ":"),
            React.createElement("span", null, "".concat(data.first_name, " ").concat(data.last_name, " ").concat(data.middle_name))),
        React.createElement("div", { className: 'd-f gg-10' },
            React.createElement("span", { className: "gray" },
                t('flow.profile.registration_date'),
                ":"),
            React.createElement("span", null, dayjs(data === null || data === void 0 ? void 0 : data.created_at).format('HH:mm - DD MMMM YYYY'))),
        React.createElement(ShowIf, { condition: (user === null || user === void 0 ? void 0 : user.user_role) === 'NOTARY' },
            React.createElement("div", { className: 'notary-activity d-f gg-10 a-i-c j-c-c f-d-c' },
                React.createElement("span", { className: "gray" }, t(data.is_active ? 'Активен' : 'Неактивен')),
                React.createElement(ToggleButton, { value: data.is_active, loading: isLoading === null || isLoading === void 0 ? void 0 : isLoading.isLoadingProfile, disabled: isLoading === null || isLoading === void 0 ? void 0 : isLoading.isLoadingProfile, onChange: function () { return onChangeActive({ is_active: !data.is_active }); } })))));
};
export default Profile;
