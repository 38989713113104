import { useCallback, useLayoutEffect, useState } from 'react';
import { ETheme } from '@/types/containers/theme';
export var useTheme = function () {
    var _a = useState(null), theme = _a[0], setTheme = _a[1];
    var changeTheme = function () {
        var nextTheme = theme === ETheme.DARK ? ETheme.LIGHT : ETheme.DARK;
        setTheme(nextTheme);
        localStorage.setItem('ui-theme', nextTheme);
        window.dispatchEvent(new Event('changeTheme'));
    };
    var onStorage = useCallback(function () {
        if (localStorage.getItem('ui-theme')) {
            setTheme(localStorage.getItem('ui-theme'));
        }
    }, []);
    useLayoutEffect(function () {
        var _a, _b, _c, _d;
        var body = document.body;
        if (theme === ETheme.DARK) {
            (_a = body.classList) === null || _a === void 0 ? void 0 : _a.remove('light-theme');
            (_b = body.classList) === null || _b === void 0 ? void 0 : _b.add('dark-theme');
        }
        else {
            (_c = body.classList) === null || _c === void 0 ? void 0 : _c.remove('dark-theme');
            (_d = body.classList) === null || _d === void 0 ? void 0 : _d.add('light-theme');
        }
    }, [theme]);
    useLayoutEffect(function () {
        if (localStorage.getItem('ui-theme')) {
            setTheme(localStorage.getItem('ui-theme'));
        }
        else {
            localStorage.setItem('ui-theme', ETheme.LIGHT);
            setTheme(ETheme.LIGHT);
        }
        window.addEventListener('changeTheme', onStorage);
        return function () { return window.removeEventListener('changeTheme', onStorage); };
    }, []);
    console.log('theme2', theme);
    return {
        theme: theme,
        changeTheme: changeTheme,
    };
};
