import { ESideInner } from '@/types/components/sidebar';
export var stepsData = [
    {
        type: ESideInner.STEP_ITEM,
        props: {
            title: 'flow.layout.personal_info',
            step: '1',
        }
    },
    {
        type: ESideInner.STEP_ITEM,
        props: {
            title: 'flow.layout.check_application',
            step: '2',
        },
    },
    {
        type: ESideInner.STEP_ITEM,
        props: {
            title: 'flow.layout.form_documents',
            step: '3',
        },
    },
    {
        type: ESideInner.STEP_ITEM,
        props: {
            title: 'flow.layout.estate_to_be_purchased',
            step: '4',
            filterType: 'REGISTRAR'
        },
    },
    {
        type: ESideInner.STEP_ITEM,
        props: {
            title: 'flow.layout.client_pay',
            step: '4',
            filterType: 'NOTARY'
        },
    },
    {
        type: ESideInner.STEP_ITEM,
        props: {
            title: 'flow.layout.signing_docs',
            step: '5',
        },
    },
];
export var stepsDataHeader = [
    {
        type: ESideInner.STEP_ITEM_HEADER,
        props: {
            title: 'flow.layout.personal_info',
            step: '1',
        }
    },
    {
        type: ESideInner.STEP_ITEM_HEADER,
        props: {
            title: 'flow.layout.check_application',
            step: '2',
        },
    },
    {
        type: ESideInner.STEP_ITEM_HEADER,
        props: {
            title: 'flow.layout.form_documents',
            step: '3',
        },
    },
    {
        type: ESideInner.STEP_ITEM_HEADER,
        props: {
            title: 'flow.layout.estate_to_be_purchased',
            step: '4',
            filterType: 'REGISTRAR'
        },
    },
    {
        type: ESideInner.STEP_ITEM_HEADER,
        props: {
            title: 'flow.layout.client_pay',
            step: '4',
            filterType: 'NOTARY'
        },
    },
    {
        type: ESideInner.STEP_ITEM_HEADER,
        props: {
            title: 'flow.layout.signing_docs',
            step: '5',
        },
    },
];
