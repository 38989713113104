import React from 'react';
import SVG from "react-inlinesvg";
import Text from "@/components/ui/text/Text";
import './index.scss';
import { ShowIf, ShowSwitch } from "@/components";
import { Tooltip } from "react-tooltip";
var WarningSrc = require('public/chunks/steps/exclamation-circle.svg');
var WarningMessage = function (_a) {
    var message = _a.message, _b = _a.extraClass, extraClass = _b === void 0 ? '' : _b, subtext = _a.subtext, _c = _a.type, type = _c === void 0 ? null : _c, _d = _a.tooltipText, tooltipText = _d === void 0 ? null : _d;
    return (React.createElement("div", { className: "warning-message__container ".concat(extraClass) },
        React.createElement(ShowSwitch, { conditions: [type === 'ERROR'] },
            React.createElement(SVG, { src: WarningSrc, width: 20, height: 20, className: 'error-path', "data-tooltip-id": "warn-tooltip" }),
            React.createElement(SVG, { src: WarningSrc, width: 20, height: 20, "data-tooltip-id": "warn-tooltip" })),
        React.createElement(ShowIf, { condition: !!tooltipText },
            React.createElement(Tooltip, { id: "warn-tooltip", className: 'warn-tooltip', style: { maxWidth: '700px', width: '80%', zIndex: 9999999999, fontSize: 10 }, place: 'bottom', wrapper: 'div' }, tooltipText)),
        React.createElement("div", null,
            React.createElement(Text, { text: message }),
            subtext && React.createElement(Text, { text: subtext }))));
};
export default WarningMessage;
