import React from 'react';
import './index.scss';
var ModalLink = function (_a) {
    var children = _a.children, onClick = _a.onClick;
    return (React.createElement("span", { className: 'modal-link__link m-l-5', onClick: function (e) {
            e.stopPropagation();
            onClick(e);
        } }, children));
};
export default ModalLink;
