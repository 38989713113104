import React from 'react';
import Text from "@/components/ui/text/Text";
import SubTitle from "@/components/ui/sub-title";
import './index.scss';
import { valueFormat } from "@/helpers/valueFormat";
import { useTranslation } from "react-i18next";
var ConditionBlock = function (_a) {
    var title = _a.title, type = _a.type, value = _a.value, icon = _a.icon, _b = _a.style, style = _b === void 0 ? {} : _b;
    var transformedValue = valueFormat(value, type);
    var t = useTranslation().t;
    return (React.createElement("div", { style: style, className: 'condition-block__wrapper' },
        icon,
        React.createElement("div", null,
            React.createElement(Text, { text: t(title), style: { marginBottom: '2px' } }),
            React.createElement(SubTitle, { text: transformedValue, style: { margin: '0' } }))));
};
export default ConditionBlock;
