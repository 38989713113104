var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import PrivateRoute from './private-route';
import { routes } from '@/options/routing';
import { useAuthorization } from "@/saga-query/query-hooks/useAuthorization";
import { usePermissions } from "@/saga-query/query-hooks/usePermissions";
import { useActivity } from "@/hooks/useActivity";
var Routing = function () {
    var searchParams = useSearchParams()[0];
    var _a = useAuthorization(), isAuth = _a.isAuth, setCredentials = _a.setCredentials;
    var isHasForbid = usePermissions().isHasForbid;
    useActivity();
    useEffect(function () {
        var _a;
        var authorizeParam = searchParams.get('authorize');
        if (authorizeParam) {
            setCredentials(authorizeParam);
            searchParams.delete('authorize');
            location.replace("".concat(location.pathname).concat(((_a = searchParams.toString()) === null || _a === void 0 ? void 0 : _a.length) ? "?".concat(searchParams.toString()) : ''));
        }
    }, [searchParams.get('authorize')]);
    var renderRoutes = function (list) {
        return list.map(function (props, key) {
            var children = props.children && renderRoutes(props.children);
            var element = props.private || props.forceRedirect ? React.createElement(PrivateRoute, __assign({ authenticated: isAuth, redirectTo: props.redirectTo, forceRedirect: props.forceRedirect }, props), children) : props.element;
            return React.createElement(Route, __assign({ key: key }, props, { element: element }), children);
        });
    };
    return React.createElement(Routes, null, renderRoutes(routes));
};
export default Routing;
