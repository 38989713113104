export var showErrorHelper = function (callBack) { return function (err) {
    var _a, _b, _c, _d, _e;
    if (((_a = err === null || err === void 0 ? void 0 : err.data) === null || _a === void 0 ? void 0 : _a.code) === 'token_not_valid')
        return;
    Object.entries((_b = err.data) !== null && _b !== void 0 ? _b : {}).forEach(function (_a) {
        var key = _a[0], val = _a[1];
        if (Array.isArray(val)) {
            val.forEach(function (message) {
                if (typeof message === 'string' && key !== 'non_field_errors')
                    return callBack({ message: "".concat(key, ": ").concat(message) });
            });
        }
        else if (val === null || val === void 0 ? void 0 : val.detail)
            return callBack({ message: "".concat(key, ": ").concat(val === null || val === void 0 ? void 0 : val.detail) });
    });
    if ((_c = err === null || err === void 0 ? void 0 : err.data) === null || _c === void 0 ? void 0 : _c.detail)
        return callBack({ message: err.data.detail });
    if ((_d = err === null || err === void 0 ? void 0 : err.data) === null || _d === void 0 ? void 0 : _d.non_field_errors)
        return callBack({ message: err.data.non_field_errors });
    if ((_e = err === null || err === void 0 ? void 0 : err.data) === null || _e === void 0 ? void 0 : _e.error)
        return callBack({ message: err.data.error });
    else if (err === null || err === void 0 ? void 0 : err.detail)
        return callBack({ message: err.detail });
    else if (err === null || err === void 0 ? void 0 : err.error)
        return callBack({ message: err.error });
    else if ((err === null || err === void 0 ? void 0 : err.status) === 500)
        return callBack({ message: 'Ошибка сервера' });
}; };
