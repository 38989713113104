// InnerTypes
export var ESideInner;
(function (ESideInner) {
    ESideInner["ACCORDION"] = "ACCORDION";
    ESideInner["BUTTON"] = "BUTTON";
    ESideInner["STEP_ITEM_HEADER"] = "STEP_ITEM_HEADER";
    ESideInner["STEP_ITEM"] = "STEP_ITEM";
})(ESideInner || (ESideInner = {}));
// InnerChildrenTypes
export var ESideInnerChildren;
(function (ESideInnerChildren) {
    ESideInnerChildren["NAVLINK"] = "NAVLINK";
})(ESideInnerChildren || (ESideInnerChildren = {}));
